import React, { useState } from 'react'
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import axios from "axios";
import { buyRequestInputs } from '../../formSource';
import { ToastContainer, toast } from 'react-toastify';

const NewRequest = ({ title }) => {
    const [formData, setFormData] = useState({});

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
          });
      };

      const toastNotification = () => toast.success("Successfull Deleted", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    
      const notifyError = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
        axios.post('https://api.drivenappinc.com/buynowrequest', formData)
        .then(response => {
            toastNotification('Request Created Successfully')
        })
        .catch(error => {
            notifyError(error.message)
        });
        // Here you can access formData and perform actions like submitting to a server
        
      };

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div className="new">
                <Sidebar />
                <div className="newContainer">
                    <Navbar />
                    <div className="top">
                        <h1>{title}</h1>
                    </div>
                    <div className="bottom">
                        <div className="right">
                        <form>
                            {buyRequestInputs.map((input) => (
                                <div className="formInput" key={input.id}>
                                    <label>{input.label}</label>
                                    <input 
                                    type={input.type} 
                                    placeholder={input.placeholder} 
                                    name={input.name}
                                        onChange={handleChange}
                                    />
                                </div>
                            ))}
                        </form>
                        </div>
                    </div>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', justifyItems: 'center' }}>
                    <button
                        className="submit-btn"
                        style={{ backgroundColor: '#FFC107', color: 'white' }} 
                        type="submit"
                        onClick={handleSubmit}
                    >
                        Add
                    </button>
                    </div>
                </div>
                </div>
        </>
    )
}

export default NewRequest