import "bootstrap/dist/css/bootstrap.css"
import "bootstrap/dist/js/bootstrap.bundle"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Main from "./components/web";
import Admin from "./components/admin/admin";
import Seller from './components/seller/index';
import './index.css';
import Motors from "./components/web/Motors";
import Number from "./components/web/Number";
import axios from "axios";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Login from "./login";
import Signup from "./signup";
import LoginAdmin from './admin-panel/pages/login/LoginAdmin'
import NotFound from "./Notfound";
import Footer from "./components/web/footer";
import Request from "./components/seller/request";
import Single from "./admin-panel/pages/single/Single";
import New from "./admin-panel/pages/new/New";
import NewUser from "./admin-panel/pages/users/NewUser";
import Cars from "./admin-panel/pages/cars/Cars";
import Auction from "./components/carAuction/Auction";
import Users from "./admin-panel/pages/users/Users";
import Bids from "./admin-panel/pages/bids/Bids";
import Edit from "./admin-panel/pages/edit/Edit";
import EditUser from "./admin-panel/pages/users/Edit";
import UserDetails from "./admin-panel/pages/users/UserDetails";
import NewBid from "./admin-panel/pages/bids/NewBid";
import BuyNowReq from "./admin-panel/pages/buNowRequests/BuyNowReq";
import NewRequest from "./admin-panel/pages/buNowRequests/NewRequest";
import Home from "./admin-panel/pages/home/Home";
import { productInputs, userInputs, carInputs, salcarInputs } from "./admin-panel/formSource";
import { ChakraProvider } from "@chakra-ui/react";
import CarAuction from "./components/carAuction/CarAuction";
import ComingSoonPage from "./components/web/commingsoon";
import AdminRoute from "./admin-panel/context/PrivateRoute";
import AuctionDetails from "./components/carAuction/AuctionDetails";
import Motorssalvage from "./components/web/Motorssalvage";
import PaymentForm from "./components/web/paymentform";
import Paymentsadmin from "./admin-panel/pages/payment/payments";
import Catelog from "./admin-panel/pages/catelog/catelog";
import Newcatelog from "./admin-panel/pages/catelog/catelognew";
import Motorsclassic from "./components/web/Motorsclassic";
import Motors2 from "./components/web/Allmotors";
import AdminLogin from "./adminlogin";
import Packagesadmin from "./admin-panel/pages/users/Packages";
import Packagesuser from "./admin-panel/pages/users/packagesuser";
import Newpackage from "./admin-panel/pages/users/addpackage";
import Editpackage from "./admin-panel/pages/users/editpackage";
import Newpackageuser from "./admin-panel/pages/users/newpackageuser";
import Packages from "./Packages";
import Salemotors from "./components/web/salemotors";
import SaleDetails from "./components/carAuction/saledetails";
import UsaSalemotors from "./components/web/usasalemotors";
import Numbers from "./admin-panel/pages/number/number";
import NewNumber from "./admin-panel/pages/number/newnumber";
import EditNumber from "./admin-panel/pages/number/editnumber";
import Buyed_numbers from "./admin-panel/pages/buyed_numbers.js/buyednumber";
import UsaSaleDetails from "./components/carAuction/usasaledetails";

function ClientApp() {
  sessionStorage.setItem('previousPage', window.location.href);
  const [categories, setcategories] = useState('');
  const [brands, setbrands] = useState('');
  const {i18n} = useTranslation();
  const [body_types, setbodytype] = useState('0');
  const getNormalizedLanguageCode = (language) => {
    if (language.startsWith('en')) {
      return 'en';
    } else if (language.startsWith('ar')) {
      return 'ar';
    } else {
      return language; // or set a default language if needed
    }
  };
  
  // Get the normalized language code
  const normalizedLanguage = getNormalizedLanguageCode(i18n.language);

    useEffect(() => {
      const fetchMotorsDetails = async () => {
        const baseURL = "https://api.drivenappinc.com/categories"+normalizedLanguage;
        const boodytype1 = "https://api.drivenappinc.com/body_types"+normalizedLanguage;
        await axios.get(boodytype1).then((response) => {
            setbodytype(response.data);
        });
        await axios.get(baseURL).then((response) => {
          setcategories(response.data);
        });
        const brandsurl = "https://api.drivenappinc.com/brandsen";
        await axios.get(brandsurl).then((response) => {
          setbrands(response.data);
        });
      }

      fetchMotorsDetails()
    }, [i18n.language]);
  return (
    <div className="App">
      <ChakraProvider>
      <BrowserRouter>
        <Routes >
          <Route path='/commingsoon' element={ <ComingSoonPage/> }></Route>
          <Route path='/' element={ <Main/> }></Route>
          <Route path='/motors' element={ <Motors categories={categories} brands={brands} body_types={body_types} /> }></Route>
          <Route path='/salemotors' element={ <Salemotors categories={categories} brands={brands} body_types={body_types} /> }></Route>
          <Route path='/usasalemotors' element={ <UsaSalemotors categories={categories} brands={brands} body_types={body_types} /> }></Route>
          <Route path='/allmotors' element={ <Motors2 categories={categories} brands={brands} body_types={body_types} /> }></Route>
          <Route path='/salvage' element={ <Motorssalvage categories={categories} brands={brands} body_types={body_types} /> }></Route>
          <Route path='/classic' element={ <Motorsclassic categories={categories} brands={brands} body_types={body_types} /> }></Route>
          <Route path='/Numbers' element={ <Number /> }></Route>
          <Route path='/sale/motors/:id' element={ <SaleDetails /> }></Route>
          <Route path='/Usasale/motors/:id' element={ <UsaSaleDetails /> }></Route>
          <Route path='/auction/motors/:id' element={ <AuctionDetails /> }></Route>
          <Route path='/admindashboard' element={ <Admin /> }></Route>
          <Route path='/user/Profile' element={ <Seller name={"Profile"}/> }></Route>
          <Route path='/user/Dashboard' element={ <Seller name={"Dashboard"}/> }></Route>
          <Route path='/admin/login' element={ <LoginAdmin /> }></Route>
          <Route path='/login' element={ <Login /> }></Route>
          <Route path='/signup' element={ <Signup /> }></Route>
          <Route path="/:title" element={<Auction />}></Route>
          <Route path='/user/Profile/request.js' element={ <Request inputs={salcarInputs} title="Add New Request"/> }></Route>
          <Route path='user/dashboard/paymentform' element={ <PaymentForm /> }></Route>
          <Route path='/packages' element={ <Packages /> }></Route>
          <Route path="/admin">
            <Route index element={<AdminRoute><Home /></AdminRoute>} />
            <Route path="payments" element={<Paymentsadmin />} />
            <Route path="Packages/edit/:id" element={<Editpackage />} />
            <Route path="catelog" element={<Catelog />} />
            <Route path="catelog/new"  element={<Newcatelog title="Add New Catelog"/>} />
            <Route path="login" element={<AdminLogin />} />
            <Route path="cars">
              <Route index element={<AdminRoute><Cars /></AdminRoute>} />
              <Route path=":userId" element={<AdminRoute><Single /></AdminRoute>} />
              <Route
                path="new"
                element={<AdminRoute><New inputs={carInputs} title="Add New Car" /></AdminRoute>}
              />
              <Route
                path="edit/:carId"
                element={<AdminRoute><Edit inputs={carInputs}/></AdminRoute>}
              />
            </Route>
            <Route path="Packages" element={<AdminRoute><Packagesadmin /></AdminRoute>} />
            <Route path="Packages/new" element={<AdminRoute><Newpackage /></AdminRoute>} />
            <Route path="PurPackages/new" element={<AdminRoute><Newpackageuser /></AdminRoute>} />
            <Route path="users">
              <Route index element={<AdminRoute><Users /></AdminRoute>} />
              <Route path="Packages" element={<AdminRoute><Packagesuser /></AdminRoute>} />
              <Route path="edit/:userId" element={<AdminRoute><EditUser /></AdminRoute>} />
              <Route path="view/:userId" element={<AdminRoute><UserDetails /></AdminRoute>} />
              <Route
                path="new"
                element={<AdminRoute><NewUser title="Add New User" /></AdminRoute>}
              />
            </Route>
            
            <Route path="bids">
              <Route index element={<AdminRoute><Bids /></AdminRoute>} />
              <Route path=":productId" element={<AdminRoute><Single /></AdminRoute>} />
              <Route
                path="new"
                element={<AdminRoute><NewBid inputs={productInputs} title="Add New Bid" /></AdminRoute>}
              />
            </Route>
            <Route path="buy_now_requests">
              <Route index element={<AdminRoute><BuyNowReq /></AdminRoute>} />
              <Route path=":reqId" element={<AdminRoute><Single /></AdminRoute>} />
              <Route
                path="new"
                element={<AdminRoute><NewRequest title="Add New Request" /></AdminRoute>}
              />
            </Route>
            
            <Route path="numbers">
              <Route index element={<AdminRoute><Numbers /></AdminRoute>} />
              <Route path="edit/:id" element={<AdminRoute><EditNumber /></AdminRoute>} />
              <Route
                path="new"
                element={<AdminRoute><NewNumber title="Add New Number" /></AdminRoute>}
              />
              </Route>
              <Route path="buyed_numbers">
              <Route index element={<AdminRoute><Buyed_numbers /></AdminRoute>} /></Route>
          <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
        <Footer />
      </BrowserRouter>
      </ChakraProvider>
    </div>
  );
}

export default ClientApp;