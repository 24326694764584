import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import axios from "axios";
import { location_types, Numbers, numberTypes, statusnumber } from '../../formSource';
import { ToastContainer, toast } from 'react-toastify';
import { FaTimes } from 'react-icons/fa';
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import Select from '../../components/dropdown/Select';

const EditNumber = ({ title }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [featureImage, setFeatureImage] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Fetch number data when the component mounts
    axios.get(`https://api.drivenappinc.com/numbers/${id}`)
      .then(response => {
        const { image, number, amount, expiry_date, location, status, type, increment } = response.data;
        setFormData({ image, number, amount, expiry_date, location, status, type, increment });
        setFeatureImage(image);
      })
      .catch(error => {
        toast.error(`Error fetching number: ${error.message}`);
      });
  }, [id]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleRemoveFeatureImage = () => {
    setFeatureImage(null);
    setFormData({
      ...formData,
      image: ''
    });
  };

  const handleFeatureImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFeatureImage(reader.result);
        setFormData({
          ...formData,
          image: reader.result
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const toastNotification = () => toast.success("Number Updated Successfully", {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  const notifyError = (msg) => toast.error(msg, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    formData.image = featureImage;
    setIsLoading(true);
    axios.put(`https://api.drivenappinc.com/numbers/${id}`, formData)
      .then(response => {
        toastNotification('Number Updated Successfully');
        navigate('/admin/numbers');
      })
      .catch(error => {
        notifyError(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="new">
        <Sidebar />
        <div className="newContainer">
          <Navbar />
          <div className="top">
            <h1>{title}</h1>
          </div>
          <div className="bottom">
            <div className="left">
              <label>
                Feature Image
              </label>
              {featureImage ? (
                <li style={{ display: 'flex', flexWrap: 'wrap', position: 'relative', marginBottom: '10px', listStyle: 'none' }}>
                  <img
                    src={featureImage ? featureImage : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"}
                    alt=""
                  />
                  <button
                    onClick={handleRemoveFeatureImage}
                    style={{
                      position: 'absolute',
                      top: '0',
                      right: '160px',
                      background: 'none',
                      border: 'none',
                      cursor: 'pointer',
                    }}
                  >
                    <FaTimes style={{ color: 'red' }} className="icon" />
                  </button>
                </li>
              ) : (
                <img
                  src={featureImage ? featureImage : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"}
                  alt=""
                />
              )}
            </div>
            <div className="right">
              <form onSubmit={handleSubmit}>
                <div className="formInput">
                  <label htmlFor="featureImage">
                    Feature Image: <DriveFolderUploadOutlinedIcon className="icon" />
                  </label>
                  <input
                    type="file"
                    id="featureImage"
                    onChange={handleFeatureImage}
                    style={{ display: "none" }}
                  />
                </div>
                {Numbers.map((input) => (
                  <div className="formInput" key={input.id}>
                    <label>{input.label}</label>
                    <input
                      type={input.type}
                      placeholder={input.placeholder}
                      name={input.name}
                      value={formData[input.name] || ''}
                      onChange={handleChange}
                    />
                  </div>
                ))}
                <Select language='English' value={location_types} handleChange={handleChange} name='location' title='Location' selected={formData.location}/>
                <Select language='English' value={statusnumber} handleChange={handleChange} name='status' title='Status' selected={formData.status}/>
                <Select language='English' value={numberTypes} handleChange={handleChange} name='type' title='Type' selected={formData.type}/>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', justifyItems: 'center' }}>
                  <button
                    className="submit-btn"
                    style={{ backgroundColor: '#FFC107', color: 'white' }}
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? 'Updating...' : 'Update'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditNumber;
