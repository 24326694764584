import React, { useEffect, useState } from "react";
import Navbar from "../../Navbar";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Select from 'react-select';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { isMobile } from "react-device-detect";
import NumberCard from "./NumberCard";





function Number() {
  sessionStorage.setItem('previousPage', window.location.href);
  return (
    <div>
      <Navbar />
      <Allcomp />
    </div>
  )
}
export default Number;

const Allcomp = () => {

  const [price, setPrice] = useState(0);
  const [maxprice, setmaxPrice] = useState(999999);
  const [milage, setmilage] = useState(0);
  const [maxmilage, setmaxmilage] = useState(999999);
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedstartYear, setSelectedstartYear] = useState(1900);
  const navigate = useNavigate('');

  const [numbers, setNumbers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [city, setCity] = useState('');
  const [auction, setAuction] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const cityParam = urlParams.get('city');
    const auctionParam = urlParams.get('auction');

    if (cityParam) {
      setCity(cityParam);
    }
    if (auctionParam) {
      setAuction(auctionParam);
    }

    const fetchNumbers = async () => {
      try {
        const response = await axios.get(`https://api.drivenappinc.com/numbers?city=${cityParam}&auction=${auctionParam}`);
        setNumbers(response.data);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    if (city && auction) {
      fetchNumbers();
    } else {
      setLoading(false);
    }

    const interval = setInterval(fetchNumbers, 2000);

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, [window.location.search]);
  
  const handlePriceChange = (newValue) => {
    setPrice(newValue);
  };
  const handlemaxPriceChange = (newValue) => {
    setmaxPrice(newValue);
  };
  const { t } = useTranslation();




  const Filters = () => {

  }
  const handlemix = () =>{
    navigate('/allmotors');
  }

    return (
      <div className="row m-2 mt-5">
        {!isMobile &&
          <div className="col-md-3">
            <div className="Container-fluid"><Link to={"/"} ><span>{t("home")}</span></Link>/{t("motor")}</div>
            <div className="Container-fluid mt-5 mb-3"><b>{t("Filters")} </b><button className="btn btn-warning" style={{ marginRight: "20px", marginLeft: "20px" }} onClick={handlemix}><b>{t("Reset")}</b></button></div>
            <div className="Container-fluid mt-3 mb-3">
              <button className="btn btn-warning w-100 py-3" onClick={Filters}>{t("ApplyFilter")}</button>
            </div>
            <div className="Container-fluid bold-cont mt-3"><b>{t("Price_Range")}</b> <br /><br />
              <div className="">

                <div style={{ margin: '20px', width: '80%' }}>
                  <div>
                    <label>{t('min_price')}: AED {price}</label>
                  </div>
                  <Slider
                    min={0}
                    max={999999}
                    defaultValue={price}
                    value={price}
                    onChange={handlePriceChange}
                    step={1}
                    trackStyle={{ backgroundColor: '#007bff' }}
                    handleStyle={{ backgroundColor: '#007bff', borderColor: '#007bff' }}
                    railStyle={{ backgroundColor: '#e9ecef' }}
                  />

                  <div className="mt-3">
                    <label>{t('max_price')}: AED {maxprice}</label>
                  </div>
                  <Slider
                    min={0}
                    max={999999}
                    defaultValue={maxprice}
                    value={maxprice}
                    onChange={handlemaxPriceChange}
                    step={1}
                    trackStyle={{ backgroundColor: '#007bff' }}
                    handleStyle={{ backgroundColor: '#007bff', borderColor: '#007bff' }}
                    railStyle={{ backgroundColor: '#e9ecef' }}
                  />
                </div>

              </div>
            </div>
          </div>}
        <NumberCard  city ={city} auction={auction} maxprice={maxprice ? maxprice : 900000} price={price ? price : 0}/>
      </div>
    )
  }