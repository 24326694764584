import React, { useState } from 'react';
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import axios from "axios";
import { catelogInputs } from '../../formSource';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Newcatelog = ({ title }) => {
    const [formData, setFormData] = useState({});
    const [document, setDocument] = useState('');

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (files && files[0]) {
            const file = files[0];
            const reader = new FileReader();
            reader.onloadend = () => {
                setDocument(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const toastNotification = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const notifyError = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(document);
        const finalFormData = { catelog: document };
        axios.put('https://api.drivenappinc.com/catelog', finalFormData)
            .then(response => {
                toastNotification('Added Successfully');
            })
            .catch(error => {
                console.log(error.message);
                notifyError(error.message);
            });
    };

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div className="new">
                <Sidebar />
                <div className="newContainer">
                    <Navbar />
                    <div className="top">
                        <h1>{title}</h1>
                    </div>
                    <div className="bottom">
                        <div className="right">
                            <form>
                                {/* {catelogInputs.map((input) => (
                                    <div className="formInput" key={input.id}>
                                        <label>{input.label}</label>
                                        <input
                                            type={input.type}
                                            placeholder={input.placeholder}
                                            name={input.name}
                                            onChange={handleChange}
                                        />
                                    </div>
                                ))} */}
                                <div className="formInput">
                                    <label>Upload Catalog</label>
                                    <input
                                        type="file"
                                        accept="application/pdf"
                                        onChange={handleChange}
                                    />
                                </div>
                                <button
                                    className="submit-btn"
                                    style={{ backgroundColor: '#FFC107', color: 'white' }}
                                    type="submit"
                                    onClick={handleSubmit}
                                >
                                    Add
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Newcatelog;
