import { t } from 'i18next';
import React, { useState } from 'react';
import CheckoutForm from './checkout';
import MapComponent from '../../AddressPicker';
import 'bootstrap/dist/css/bootstrap.min.css';
// import './styles.css'; // Optional: For any custom styles you want to add

const NumberCarddetailsbuynow = ({ item }) => {
  const { number, location, amount, currency, image, id } = item || {};
  const imageUrl = image;
  const [showPopup, setShowPopup] = useState(false);
  const [selectedOption, setSelectedOption] = useState('mailing');
  const [mailingAddressLat, setMailingAddressLat] = useState('');
  const [mailingAddressLog, setMailingAddressLog] = useState('');

  const handleBuyNow = () => {
    setShowPopup(true);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const handleSuccess = () => {
    alert('Checkout successful!');
    setShowPopup(false);
  };

  return (
    <>
      {showPopup && (
        <div className="modal fade show" style={{ display: 'block' }} tabIndex="1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Select Delivery Option</h5>
                <button type="button" className="close" onClick={() => setShowPopup(false)}>
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {/* <div className="btn-group btn-group-toggle mb-3" data-toggle="buttons">
                  <label className="btn btn-secondary" onClick={() => handleOptionSelect('mailing')}>
                    Mailing
                  </label>
                  <label className="btn btn-secondary" onClick={() => handleOptionSelect('pickup')}>
                    Pickup
                  </label>
                </div> */}
                {selectedOption === 'mailing' && (
                  <div>
                    <MapComponent
                      onLocationSelect={(position) => {
                        setMailingAddressLat(position.lat);
                        setMailingAddressLog(position.lng);
                      }}
                    />
                    <div className="details-and-checkout mt-3">
                    <h3>{t(location) || 'N/A'}</h3>
                    <p style={{color: 'black'}}>{t('Numbers')}{number || 'N/A'}</p>
                    <p style={{color: 'black'}}>{t('Price')}{currency || 'AED'} {(amount !== undefined) ? amount.toLocaleString() : '0'}</p>
                      <CheckoutForm
                        numberId={id}
                        onSuccess={handleSuccess}
                        mailingAddressLat={mailingAddressLat}
                        mailingAddressLog={mailingAddressLog}
                      />
                    </div>
                  </div>
                )}
                {selectedOption === 'pickup' && (
                  <div className="details-and-checkout mt-3">
                    <MapComponent
                      onLocationSelect={(position) => {
                        setMailingAddressLat(position.lat);
                        setMailingAddressLog(position.lng);
                      }}
                    />
                    <div className="details-and-checkout mt-3">
                    <h3>{t(location) || 'N/A'}</h3>
                    <p style={{color: 'black'}}>{t('Numbers')}{number || 'N/A'}</p>
                    <p style={{color: 'black'}}>{t('Price')}{currency || 'AED'} {(amount !== undefined) ? amount.toLocaleString() : '0'}</p>
                      <CheckoutForm
                        numberId={id}
                        onSuccess={handleSuccess}
                        mailingAddressLat={mailingAddressLat}
                        mailingAddressLog={mailingAddressLog}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => setShowPopup(false)}>{t('Close')}</button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="col-lg-2 col-md-4 col-sm-6 mb-4">
        <div className="card h-100 text-center">
          <img className="card-img-top p-1" style={{width:'212',height:"50px"}} src={imageUrl} alt={`${location} ${number}`} />
          <div className="card-body" style={{width:'100%'}}>
            <h5 className="card-title"><span style={{fontSize: 'small'}}>{currency || 'AED'}</span> {(amount !== undefined) ? amount.toLocaleString() : '0'}</h5>
            {/* <p className="card-text" style={{ color: 'black', marginBottom: '3px' }}>{number || 'N/A'}</p>
            <p className="card-text" style={{ color: 'black', marginBottom: '3px' }}>{currency || 'AED'} {(amount !== undefined) ? amount.toLocaleString() : '0'}</p> */}
            <button className="btn btn-primary" style={{ width: '90%' }} onClick={handleBuyNow}>{t('Buy_now')}</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default NumberCarddetailsbuynow;