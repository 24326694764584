import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import axios from "axios";
import { Circles } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

const EditUser = () => {
    const navigate = useNavigate();
    const { userId } = useParams();
    const [image, setImage] = useState([]);
    const [data, setData] = useState(null);
    const [formData, setFormData] = useState({
        image: ''
    });
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchDataForPosts = async () => {
          try {
            const response = await fetch(
              `https://api.drivenappinc.com/users/${userId}`
            );
            if (!response.ok) {
              throw new Error(`HTTP error: Status ${response.status}`);
            }
            let userData = await response.json();
            setData(userData);
            console.log(data);
            setIsLoading(false);
          } catch (err) {
            console.log(err.message)
            setIsLoading(false)
          }
        };
    
        fetchDataForPosts();
      }, [])

      const handleImages = (e) => {
        const files = Array.from(e.target.files);
        setImage([]);
        files.forEach((file) => {
          const reader = new FileReader();
          reader.onload = () => {
            if(reader.readyState === 2) {
              setImage([...image, reader.result]);
            }
          };
          reader.readAsDataURL(file)
        })
      }

      const handleChange = (e) => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value
        });
      };

      const toastNotification = (msg) => {return (
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                >
                    {msg}
                </ToastContainer>
      )}

      const handleSubmit = (e) => {
        setIsLoading(true);
        e.preventDefault();
        formData.image=image[0];
        console.log(formData)
        axios.put(`https://api.drivenappinc.com/user/${userId}`, formData)
        .then(response => {
            navigate('/users', { replace: true })
        })
        .catch(error => {
            toastNotification(error.message)
        });
        setIsLoading(false);
        // Here you can access formData and perform actions like submitting to a server
        
      };

    return (
        <>
        <div className="new">
            <Sidebar />
            <div className="newContainer">
                <Navbar />
                {isLoading ? (
                        <div style=
                            {{ 
                                display: 'flex', 
                                justifyContent: 'center', 
                                alignItems: 'center', 
                                top: 0, 
                                bottom: 0, 
                                right: 0, 
                                left: 0, 
                                position: 'fixed' 
                            }}>
                            <Circles
                            height="80"
                            width="80"
                            color="#FFC107"
                            ariaLabel="circles-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            />
                        </div>
                ) : (data && (<>
                <div className="top">
                <h1>Edit Car</h1>
                </div>
                <div className="bottom">
                    <div className="left">
                        <img
                            src={
                                data[0].image === '' ? image : data[0].image
                            }
                            alt="User"
                        />
                    </div>
                    <div className="right">
                    <form>
                        <div className="formInput">
                        <label htmlFor="file">
                            Image: <DriveFolderUploadOutlinedIcon className="icon" />
                        </label>
                        <input
                            type="file"
                            id="file"
                            onChange={handleImages}
                            style={{ display: "none" }}
                        />
                        </div>
                        <div className="formInput">
                            <label>Name</label>
                            <input 
                            required
                            type='text' 
                            name='name'
                            defaultValue={data[0].name}
                            onChange={handleChange}
                            />
                        </div>
                        <div className="formInput">
                            <label>Email</label>
                            <input 
                            type='email' 
                            name='email'
                            defaultValue={data[0].email}
                            onChange={handleChange}
                            />
                        </div>
                        <div className="formInput">
                            <label>Username</label>
                            <input 
                            required
                            type='text' 
                            name='username'
                            defaultValue={data[0].username}
                            onChange={handleChange}
                            />
                        </div>
                        <div className="formInput">
                            <label>Phone</label>
                            <input 
                            required
                            type='text' 
                            name='phone'
                            defaultValue={data[0].phone}
                            onChange={handleChange}
                            />
                        </div>
                        <div className="formInput">
                            <label>Country</label>
                            <input 
                            required
                            type='text' 
                            name='country'
                            defaultValue={data[0].country}
                            onChange={handleChange}
                            />
                        </div>
                        <div className="formInput">
                            <label>City</label>
                            <input
                            required 
                            type='text' 
                            name='city'
                            defaultValue={data[0].city}
                            onChange={handleChange}
                            />
                        </div>
                        <div className="formInput">
                            <label>State</label>
                            <input 
                            required
                            type='text' 
                            name='state'
                            defaultValue={data[0].state}
                            onChange={handleChange}
                            />
                        </div>
                        <div className="formInput">
                            <label>Address</label>
                            <input
                            required 
                            type='text' 
                            name='address'
                            defaultValue={data[0].address}
                            onChange={handleChange}
                            />
                        </div>
                        <div className="formInput">
                            <label>ZIP Code</label>
                            <input
                            required 
                            type='text' 
                            name='zip_code'
                            defaultValue={data[0].zip_code}
                            onChange={handleChange}
                            />
                        </div>
                    </form>
                    </div>
                </div>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', justifyItems: 'center' }}>
                <button
                    className="submit-btn"
                    style={{ backgroundColor: '#FFC107', color: 'white' }} 
                    type="submit"
                    onClick={handleSubmit}
                >
                    Add
                </button>
                </div>
                </>))}
            </div>
        </div>
       
       </>
    )
}

export default EditUser