import React from 'react'
import { useEffect, useState, useRef } from "react";
//import { carsColumns } from "../../datatablesource";
import { Circles } from 'react-loader-spinner';
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import { StatusDropdown, FeaturedDropdown } from '../../components/customDataButtons/customButtons';
import List from '../list/List'




const Cars = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true)
    const childRef = useRef(null);

    useEffect(() => {
        const fetchDataForPosts = async () => {
          try {
            const response = await fetch(
              `https://api.drivenappinc.com/`
            );
            if (!response.ok) {
              throw new Error(`HTTP error: Status ${response.status}`);
            }
            let postsData = await response.json();
            setData(postsData);
            setIsLoading(false)
          } catch (err) {
            console.log(err.message)
            setIsLoading(false)
          }
        };
    
        fetchDataForPosts();
      }, [])
      const [getFeature, setGetFeature] = useState(data)
      const [getStatus, setGetStatus] = useState(data)
      const handleFeaturedChange = (id, newValue) => {
        setGetFeature(getFeature.map(row => (row.id === id ? { ...row, featured: newValue } : row)));
      };

      const handleStatusChange = (id, newValue) => {
        setGetStatus(getStatus.map(row => (row.id === id ? { ...row, status: newValue } : row)));
      };

      const carsColumns = [
        { field: "id", headerName: "ID", width: 70 },
        {
          field: "title",
          headerName: "Title",
          width: 230,
          renderCell: (params) => {
            return (
              <div className="cellWithImg">
                <img className="cellImg" src={params.row.feature_image} alt="avatar" />
                {params.row.title}
              </div>
            );
          },
        },
        {
          field: "car_model",
          headerName: "Model",
          width: 230,
        },
      
        {
          field: "brand",
          headerName: "Brand",
          width: 100,
        },
        {
          field: "vendor",
          headerName: "Vendor",
          width: 160
        },
        {
          field: "winner_id",
          headerName: "Winnder Id",
          width: 160
        },
        {
          field: "is_featured",
          headerName: "Featured",
          width: 160,
          renderCell: (params) => (
            <FeaturedDropdown
              id={params.row.id}
              featured={params.row}
              onChange={(newValue) => handleFeaturedChange(params.row.id, newValue)}
            />
          ),
        },
        {
          field: "status",
          headerName: "Status",
          width: 160,
          renderCell: (params) => (
            <StatusDropdown
              id={params.row.id}
              statusActive={params.row}
              onChange={(newValue) => handleStatusChange(params.row.id, newValue)}
            />
          ),
        },
      ];
      
    return (
        <>
        <div className="list">
          <Sidebar/>
          <div className="listContainer">
            <Navbar/>
            {isLoading ? (
              <div style=
                {{ 
                  display: 'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center', 
                  top: 0, 
                  bottom: 0, 
                  right: 0, 
                  left: 0, 
                  position: 'fixed' 
                }}>
                <Circles
                height="80"
                width="80"
                color="#FFC107"
                ariaLabel="circles-loading"
                wrapperClass=""
                visible={true}
                />
              </div>
            ) : (data && (
                <List 
                  data={data} 
                  link='cars'
                  ref={childRef} 
                  columns={carsColumns} 
                  setData={setData} 
                  title={"Cars"}
                />
              )
            )}
          </div>
        </div>
      </>
    )
}

export default Cars