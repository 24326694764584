import React, { useState, useCallback } from 'react';
import { GoogleMap, LoadScript, Marker, Autocomplete } from '@react-google-maps/api';
import { isMobile } from 'react-device-detect';
import './MapComponent.css'; // Import the CSS file

const containerStyle = {
  width: '100%',
  height: '400px'
};

function MapComponent({ onLocationSelect }) {
  const [map, setMap] = useState(null);
  const [markerPosition, setMarkerPosition] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [address, setAddress] = useState('');

  const onLoad = useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        const location = place.geometry.location;
        const position = {
          lat: location.lat(),
          lng: location.lng()
        };
        setMarkerPosition(position);
        setAddress(place.formatted_address);
        onLocationSelect(position);
        setMap((prevMap) => {
          prevMap.panTo(location);
          return prevMap;
        });
      }
    }
  };

  const onMapClick = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    const position = { lat, lng };
    setMarkerPosition(position);

    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: position }, (results, status) => {
      if (status === 'OK' && results[0]) {
        setAddress(results[0].formatted_address);
      } else {
        setAddress('');
      }
      onLocationSelect(position);
    });

    setMap((prevMap) => {
      prevMap.panTo({ lat, lng });
      return prevMap;
    });
  };

  return (
    <div className="autocomplete-container">
      <LoadScript googleMapsApiKey="AIzaSyAIgMCGNjpwT1yNrC77M59jrOJi4LX7bVs" libraries={['places']}>
        <Autocomplete
          onLoad={(autocomplete) => setAutocomplete(autocomplete)}
          onPlaceChanged={onPlaceChanged}
        >
          <div className="autocomplete-container">
            <label><b style={{ color: 'black', fontSize: '30px' }}>Address</b></label>
            <br />
            {!isMobile ?
              <input
                type="text"
                placeholder="Type a location"
                value={address}
                autoComplete="on"
                onChange={(e) => setAddress(e.target.value)}
                className="autocomplete-input"
              /> :
              <input
                type="text"
                placeholder="Type a location"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                className="autocomplete-input"
                style={{ width: '100%' }}
              />}
          </div>
        </Autocomplete>
        <div className="google-map-container">
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={markerPosition || { lat: 0, lng: 0 }}
            zoom={markerPosition ? 10 : 2}
            onLoad={onLoad}
            onUnmount={onUnmount}
            onClick={onMapClick}
          >
            {markerPosition && <Marker position={markerPosition} />}
          </GoogleMap>
        </div>
      </LoadScript>
    </div>
  );
}

export default MapComponent;
