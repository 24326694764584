import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Language from './components/language';
import "./i18n"
import { useTranslation } from 'react-i18next';
import Popup from './components/popup/popup';
import NumberPopup from './components/popup/numberpop';
import Propertiespop from './components/popup/propertiespop.js';
import Rentpop from './components/popup/rentpop.js';
import Surpluspop from './components/popup/surpluspop.js';
import './components/css/style.css'
import { useNavigate} from 'react-router-dom';
import './components/css/font-awesome.css'

function Navbar() {
  const navigate = useNavigate();
  const [popup, setpopup] = useState('close');
  const [numberpop, setnumberpop] = useState('close');
  const [propertiespop, setPropertiespop] = useState('close');
  const [rentpop, setRentpop] = useState('close');
  const [surpluspop, setSurpluspop] = useState('close');
  const userData = sessionStorage.getItem('user');
  const defaultUser = {}; // Set default user object here
  const [user, setUser] = useState(userData ? JSON.parse(userData) : defaultUser);

  const [isMobile, setIsMobile] = useState(false);
useEffect (() => {
  const handleResize = () => {
      setIsMobile(window.innerWidth <= 991);
  };

  handleResize();

  window.addEventListener('resize', handleResize);

  return () => {
      window.removeEventListener('resize', handleResize);
  };
}, []);



  useEffect(() => {
    const userFromSession = JSON.parse(sessionStorage.getItem('user'));
    setUser(userFromSession || {});
  }, []);

  const close = () => {
    setnumberpop("close");
    setPropertiespop('close');
    setRentpop("close");
    setSurpluspop("close");
    setpopup("close");
  }

  const open = () => {
    switch(popup){
      case 'close':
        setnumberpop("close");
        setPropertiespop('close');
        setRentpop("close");
        setSurpluspop("close");
        setpopup("open");
          return;
      case 'open':
          setpopup("close");
          return;
      default: 
          setpopup("close");
          return;
  }
    
  }

  const number = () => {
    switch(numberpop){
      case 'close':
        setpopup('close');
        setPropertiespop('close');
        setRentpop("close");
        setSurpluspop("close");
        setnumberpop("open");
          return;
      case 'open':
        setnumberpop("close");
          return;
      default: 
      setnumberpop("close");
          return;
  }
    
  }

  const propertiespopbut = () => {
    switch(propertiespop){
      case 'close':
        setpopup('close');
        setPropertiespop('open');
        setRentpop("close");
        setSurpluspop("close");
        setnumberpop("close");
          return;
      case 'open':
        setPropertiespop("close");
          return;
      default: 
      setPropertiespop("close");
          return;
  }
    
  }

  const rentpopbtn = () => {
    switch(rentpop){
      case 'close':
        setpopup('close');
        setPropertiespop('close');
        setRentpop("open");
        setSurpluspop("close");
        setnumberpop("close");
          return;
      case 'open':
        setRentpop("close");
          return;
      default: 
      setRentpop("close");
          return;
  }
  }

  const surpluspopbtn = () => {
    switch(surpluspop){
      case 'close':
        setpopup('close');
        setPropertiespop('close');
        setRentpop("close");
        setSurpluspop("open");
        setnumberpop("close");
          return;
      case 'open':
        setSurpluspop("close");
          return;
      default: 
      setSurpluspop("close");
          return;
  }
  }

  
function Logout() {
  const user = {};
  sessionStorage.setItem('user', JSON.stringify(user));
  sessionStorage.setItem('admin', JSON.stringify(user));
  navigate('/');
}



const menuMainClickHandler = (e) => {
  const menu = document.querySelector(".menu");
  const menuMain = menu.querySelector(".menu-main");
  const goBack = menu.querySelector(".go-back");

  if (!menu.classList.contains("active")) {
      return;
  }

  if (e.target.closest(".menu-item-has-children")) {
      const hasChildren = e.target.closest(".menu-item-has-children");
      showSubMenu(hasChildren);
  }
};

const showSubMenu = (hasChildren) => {
  const subMenu = hasChildren.querySelector(".sub-menu");
  subMenu.classList.add("active");
  subMenu.style.animation = "slideLeft 0.5s ease forwards";
  const menuTitle = hasChildren.querySelector("i").parentNode.childNodes[0].textContent;
  const currentMenuTitle = document.querySelector(".current-menu-title");
  if (currentMenuTitle) {
      currentMenuTitle.innerHTML = menuTitle;
  }
  const mobileMenuHead = document.querySelector(".mobile-menu-head");
  if (mobileMenuHead) {
      mobileMenuHead.classList.add("active");
  }
};

const goBackClickHandler = () => {
  hideSubMenu();
};

const hideSubMenu = () => {
  const subMenu = document.querySelector(".sub-menu.active");
  if (subMenu) {
      subMenu.style.animation = "slideRight 0.5s ease forwards";
      setTimeout(() => {
          subMenu.classList.remove("active");
      }, 300);
  }
  const currentMenuTitle = document.querySelector(".current-menu-title");
  if (currentMenuTitle) {
      currentMenuTitle.innerHTML = "";
  }
  const mobileMenuHead = document.querySelector(".mobile-menu-head");
  if (mobileMenuHead) {
      mobileMenuHead.classList.remove("active");
  }
};

const toggleMenu = () => {
  const menu = document.querySelector(".menu");
  const menuOverlay = document.querySelector(".menu-overlay");
  menu.classList.toggle("active");
  if (menuOverlay) {
      menuOverlay.classList.toggle("active");
  }
};

const menuTriggerClickHandler = () => {
  toggleMenu();
};

const closeMenuClickHandler = () => {
  toggleMenu();
};

const menuOverlayClickHandler = () => {
  toggleMenu();
};






function isDesktop() {
  return window.innerWidth >= 624;
}

// Function to create and show popups
function createAndShowPopup(position, imageUrl, qrCodeText, iosLink, androidLink) {
  if (!isDesktop()) {
      return; // Do not show popups on mobile devices
  }

  // Create popup container
  const popup = document.createElement('div');
  popup.className = 'custom-popup';
  
  // Create image element
  const img = document.createElement('img');
  img.src = imageUrl;
  popup.appendChild(img);

  // Create QR code container
  const qrCodeDiv = document.createElement('div');
  qrCodeDiv.className = 'qr-code';

  // Add text with icon
  const textDiv = document.createElement('div');
  textDiv.innerHTML = `Scan to get<br/> <span><img class="app-icon" src="${position === 'left' ? '/iconsSVG/1.svg' : '/iconsSVG/2.svg'}" />${position === 'left' ? 'app' : 'app'} </span>`;
  qrCodeDiv.appendChild(textDiv);

  // Create link element
  const linkElem = document.createElement('a');
  linkElem.className = 'app-link';
  linkElem.target = '_blank';
  linkElem.appendChild(textDiv);
  qrCodeDiv.appendChild(linkElem);

  // Append QR code container to popup
  popup.appendChild(qrCodeDiv);

  // Position the popup
  if (position === 'left') {
      popup.style.left = '0';
      popup.style.top = '50%';
      popup.style.transform = 'translateY(-50%)';
  } else if (position === 'right') {
      popup.style.right = '0';
      popup.style.top = '50%';
      popup.style.transform = 'translateY(-50%)';
  }

  // Append the popup to the body
  document.body.appendChild(popup);

  // Show the popup after a delay
  setTimeout(() => {
      popup.style.display = 'flex';
  }, 2000); // 2-second delay
}

// Example usage: Create and show popups on page load
document.addEventListener('DOMContentLoaded', () => {
  createAndShowPopup(
      'left', 
      '/androidqrcode.svg', 
      'QR Code For iOS app',
      'https://apps.apple.com/app/idXXXXXXXXX', // Replace with actual iOS app link
      'https://play.google.com/store/apps/details?id=com.example.app' // Replace with actual Android app link
  );
  createAndShowPopup(
      'right', 
      '/iosqrcode.svg', 
      'QR Code For Android app',
      'https://apps.apple.com/app/idXXXXXXXXX', // Replace with actual iOS app link
      'https://play.google.com/store/apps/details?id=com.example.app' // Replace with actual Android app link
  );
});



const {t} = useTranslation();
return (
  <header className="header bg-warning  popup_applinkthere" >
      <div style={{paddingBottom: "10px"}}>
          <div className="row v-center"> 
              <div className="header-item item-left">
                  <Link to={"/"} className="navbar-brand">
                      <img src="/assets/img/logo.png" alt="Logo" height="40" style={{ marginRight: "20px" }} className="d-inline-block logo" />
                  </Link>
              </div>
              <div className="header-item item-center">
                  <div className="menu-overlay" onClick={menuOverlayClickHandler}></div>
                  <nav className="menu">
                      <div className="mobile-menu-head">
                          <div className="go-back" onClick={goBackClickHandler}><i className="fa fa-angle-left"></i></div>
                          <div className="current-menu-title"></div>
                          <div className="mobile-menu-close" onClick={closeMenuClickHandler}>&times;</div>
                      </div>
                      <ul className="menu-main" onClick={menuMainClickHandler}>
                          <li>
                              <Link to="/">{t("home")}</Link>
                          </li>
                          <li className="menu-item-has-children">
                            <Popup />
                          </li>

                          <li className="menu-item-has-children">
                              <NumberPopup />
                          </li>
                          {/* <li className="menu-item-has-children">
                             <Propertiespop />
                          </li>
                          <li className="menu-item-has-children">
                             <Rentpop/>
                          </li>
                          <li className="menu-item-has-children">
                             <Surpluspop/>
                          </li> */}
                          {user && Object.keys(user).length > 0 &&
                          <li>
                            <button className="btn btn-outline-warning" onClick={Logout} type="button">
                              {t('Logout')}
                            </button>
                          </li>
            }
            {user && Object.keys(user).length === 0 && isMobile &&
            <li>
        <Link to={'/login'} className="btn btn-outline-warning " type="button">
          {t('Login')}
        </Link> 
        <Link to={'/signup'} className="btn btn-outline-warning mx-1" type="button">
          {t('signup')}
        </Link></li>
    }
    {user && Object.keys(user).length > 0 && isMobile && 
              <div>
                <li style={{display: "flex"}}>
                              <Link to={'/user/dashboard'}><img className="langbtn profilebtn mx-2 my-2" alt={"Bid"}  src='/bid.png' /></Link>
                           <Link to={'/user/Profile'}><img className="langbtn profilebtn my-2" alt={"Profile"}  src='/profile.webp' /></Link></li>
          </div>
            }
                          <li>
                            <Language />
                          </li>
                          {
                            isMobile && <div style={{width: '50%',margin: '24%', marginTop: '10px'}} align="center">
                            <a href='https://play.google.com/store/apps/details?id=com.driven.customer.application&pli=1'>
                            <img src='\icons-SVGmenu/1.svg' /></a>
                            
                            <a href='https://apps.apple.com/us/app/driven-customer/id1603274627'>
                            <img src='\icons-SVGmenu/2.svg' /></a>
                            </div>
                          }
                      </ul>
                    </nav>
              </div>
              <div className="header-item item-right">
              {user && Object.keys(user).length > 0 && !isMobile && 
              <div>
                              <Link to={'/user/dashboard'}><img className="langbtn profilebtn mx-2" alt={"Bid"}  src='/bid.png' /></Link>
                              <Link to={'/user/Profile'}><img className="langbtn profilebtn" alt={"Profile"}  src='/profile.webp' /></Link>
          </div>
            }
            
            {user && Object.keys(user).length === 0 && !isMobile &&
        <Link to={'/login'} className="btn btn-outline-warning " type="button">
          {t('Login')}
        </Link>}{user && Object.keys(user).length === 0 && !isMobile &&
        <Link to={'/signup'} className="btn btn-outline-warning mx-1" type="button">
          {t('signup')}
        </Link>
    }
                  <div className="mobile-menu-trigger" onClick={menuTriggerClickHandler}>
                      <span></span>
                  </div> 
              </div>
          </div>
      </div>
  </header>
);


}

export default Navbar;
