import React, { useEffect, useState } from "react";
import "../../i18n";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { Link } from 'react-router-dom';



function Popup(props){
    const [categories, setcategories] = useState('');
    const [boodytype, setboodytype] = useState('');
    const {i18n} = useTranslation();
    const getNormalizedLanguageCode = (language) => {
        if (language.startsWith('en')) {
          return 'en';
        } else if (language.startsWith('ar')) {
          return 'ar';
        } else {
          return language; // or set a default language if needed
        }
      };
      
      // Get the normalized language code
      const normalizedLanguage = getNormalizedLanguageCode(i18n.language);
    useEffect(() => {
        const baseURL = "https://api.drivenappinc.com/navcategories"+normalizedLanguage;
        const boodytype1 = "https://api.drivenappinc.com/body_types"+normalizedLanguage;
            axios.get(baseURL).then((response) => {
                setcategories(response.data);
              });
              axios.get(boodytype1).then((response) => {
                  setboodytype(response.data);
                });
        }, [normalizedLanguage]);
//   React.useEffect(() => {
//     axios.get(baseURL).then((response) => {
//       setcategories(response.data);
//     });
//     axios.get(boodytype1).then((response) => {
//         setboodytype(response.data);
//       });
//   }, []);
    return (<Container boodytype={boodytype} categories={categories} />
    )
}
export default Popup;

const Container = (props) => {
    const {t} = useTranslation();
    if(props.categories !== '' && props.boodytype !== '' ){
        return(
            <li className="menu-item-has-children">
                              <a href="#" >{t('motor')} <i className="fa fa-angle-down"></i></a>
                              <div className="sub-menu mega-menu mega-menu-column-4">
                                  <div className="list-item">
                                      <h4 className="title">{t('Category')}</h4>
                                      <ul>
                                      {props.categories.map((category) => {
                              return(<li><Link to={"/motors"} key={category.id} >{category.name}</Link></li>
                              )})}
                               <li><Link to={"/salvage"}>{t('Salvage')}</Link></li>
                               <li><Link to={"/classic"}>{t('classic')}</Link></li>
                                      </ul>
                                  </div>
                                  <div className="list-item">
                                      <h4 className="title">{t('Body')}</h4>
                                      <ul>
                                      {props.boodytype.map((body) => {
                              return(<li><Link to={"/motors"} key={body.id} >{body.name}</Link></li>
                              )})}</ul>
                                  </div>
                                  <div className="list-item">
                                      <h4 className="title">{t('Popular')}</h4>
                                      <ul>
                                          <li><Link to={"/motors"}>{t('toyota')}</Link></li>
                                          <li><Link to={"/motors"}>{t('nissan')}</Link></li>
                                          <li><Link to={"/motors"}>{t('Mercedes')}</Link></li>
                                          <li><Link to={"/motors"}>{t('Audi')}</Link></li>
                                          <li><Link to={"/motors"}>{t('Volvo')}</Link></li>
                                          <li><Link to={"/motors"}>{t('BMW')}</Link></li>
                                          <li><Link to={"/salvage"}>{t('Salvage')}</Link></li>
                                      </ul>
                                  </div>
                                  <div className="list-item">
                                      <img src="https://dummyimage.com/600x400/000/fff.jpg&text=hello" alt="shop" />
                                  </div>
                                  <div className="row p-3 py-0 w-100">
            <hr />
            <div >
                <Link to={"/allmotors"} type="button" className="btn btn-warning last-button mt-3">{t('Viewallmotors')} </Link> 
                <Link to={"/salvage"} type="button" className="btn btn-warning last-button mt-3">{t('Viewallmotorssal')} </Link> 
                <Link to={"/classic"} type="button" className="btn btn-warning last-button mt-3">{t('Viewallmotorsclassic')}</Link>
             </div>
    </div>
                              </div>
                          </li>
            
           )
    }
   
}