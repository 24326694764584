import React, { useState } from 'react';
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

const Newpackageuser = ({ title }) => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        U_id: '',
        Purchased_date: '',
        Expiry_date: '',
        Package_id: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const toastNotification = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const notifyError = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        const finalFormData = {
            U_id: formData.U_id,
            Purchased_date: formData.Purchased_date,
            Expiry_date: formData.Expiry_date,
            Package_id: formData.Package_id,
        };
        console.log(finalFormData);

        axios.post('https://api.drivenappinc.com/purchased_package', finalFormData)
        .then(response => {
            toastNotification('Purchased Package Added Successfully');
            navigate('/admin/users/Packages');
        })
        .catch(error => {
            console.log(error.message);
            notifyError('Error Adding Purchased Package');
        });    
    };

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div className="new">
                <Sidebar />
                <div className="newContainer">
                    <Navbar />
                    <div className="top">
                        <h1>Add New Purchased Package</h1>
                    </div>
                    <div className="bottom">
                        <div className="right">
                            <form onSubmit={handleSubmit}>
                                <div className="formInput">
                                    <label>User ID</label>
                                    <input
                                        type="text"
                                        name="U_id"
                                        value={formData.U_id}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="formInput">
                                    <label>Purchased Date</label>
                                    <input
                                        type="date"
                                        name="Purchased_date"
                                        value={formData.Purchased_date}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="formInput">
                                    <label>Expiry Date</label>
                                    <input
                                        type="date"
                                        name="Expiry_date"
                                        value={formData.Expiry_date}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="formInput">
                                    <label>Package ID</label>
                                    <input
                                        type="text"
                                        name="Package_id"
                                        value={formData.Package_id}
                                        onChange={handleChange}
                                    />
                                </div>
                                <button
                                    className="submit-btn"
                                    style={{ backgroundColor: '#FFC107', color: 'white' }}
                                    type="submit"
                                >
                                    Add
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Newpackageuser;
