import React, { useState, useEffect } from 'react';
import { Circles } from 'react-loader-spinner';
import { useLocation, useParams } from 'react-router-dom';
import Navbar from '../../Navbar';
import "./auctiondetails.css";
import { isMobile } from 'react-device-detect';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; 
import { useTranslation } from 'react-i18next';
import MapComponentshower from './mapcomponent';

const SaleDetails = () => {
  const { id } = useParams();
  const carId = id;
  const [cars, setCars] = useState(null);
  const [carContentAr, setCarContentAr] = useState(null);
  const [carContentEn, setCarContentEn] = useState(null);
  const [carContent, setCarContent] = useState({});
  const [carImages, setCarImages] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isPopupVisible1, setIsPopupVisible1] = useState(false);
  const [feature_image, setFeatureImage] = useState('');
  const [documents, setDocument] = useState('');
  const [carprice, setcarprice] = useState();
  let id1 = '';
  const [isInWishlist, setIsInWishlist] = useState(false);
  const userData = sessionStorage.getItem('user');
  const defaultUser = {};
  const [user, setUser] = useState(userData? JSON.parse(userData) : defaultUser);
  const { i18n } = useTranslation();
  const { t } = useTranslation();
    const [carowner, setcarowner] = useState('');
  const togglePopup1 = () => {
    setIsPopupVisible1(!isPopupVisible1);
  };

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };


  const addwishlist = (id) => {
    if (isInWishlist) {
      handleRemoveFromWishlist(id);
    } else {
      handleAddWishlist(id);
    }
  };

  const handleAddWishlist = async (auctionId) => {
    const userId = user.data && user.data[0]?.id;
    if (!userId) {
      alert('Please Login first!');
      return;
    }
    if (!auctionId) {
      console.error('Auction ID not found');
      return;
    }

    try {
      const response = await axios.post('https://api.drivenappinc.com/wishlist', { auctionId, userId });
      console.log('Added to wishlist successfully', response.data);
      setIsInWishlist(true);
      checkWishlistStatus();
    } catch (error) {
      console.error('Error adding to wishlist:', error);
    }
  };

  const handleRemoveFromWishlist = async (auctionId) => {
    const userId = user.data && user.data[0]?.id;

    try {
      const response = await axios.delete(`https://api.drivenappinc.com/wishlist/${userId}/${auctionId}`);
      setIsInWishlist(false);
      checkWishlistStatus();
    } catch (error) {
      console.error('Error removing from wishlist:', error);
    }
  };

  const checkWishlistStatus = async () => {
    const userId = user.data && user.data[0]?.id;
    const auctionId = carId;

    try {
      const response = await axios.get(`https://api.drivenappinc.com/wishlist/${userId}/${auctionId}`);
      const isInWishlist = response.data.isInWishlist;
      setIsInWishlist(isInWishlist);
    } catch (error) {
      console.error('Error checking wishlist status:', error);
    }
  };

  useEffect(() => {
    const fetchCarData = async (url, setter) => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`HTTP error: Status ${response.status}`);
        }
        const carData = await response.json();
        setter(carData);
      } catch (err) {
        console.log(err.message);
      }
    };
    const fetchDataForPosts = async () => {
      setIsLoading(true);
      await fetchCarData(`https://api.drivenappinc.com/cars/${carId}`, setCars);
      await fetchCarData(`https://api.drivenappinc.com/carsen/${carId}`, setCarContentEn);
      await fetchCarData(`https://api.drivenappinc.com/carsar/${carId}`, setCarContentAr);
      await fetchCarData(`https://api.drivenappinc.com/carsimages/${carId}`, setCarImages);
      setIsLoading(false);
    };

    fetchDataForPosts();
  }, [carId]);

  if(cars?.[0]?.vendor != undefined){
  id1 = cars?.[0]?.vendor === 'admin' ? '' : cars?.[0]?.vendor;
}
  const handleChange = async () => {
    try {
      const response = await axios.get(`https://api.drivenappinc.com/car_price/${carId}`);
      if (response.data && response.data.length > 0) {
        setCarContent(prevContent => ({
          ...prevContent,
          price: response.data[0].price
        }));
        setcarprice(response.data[0].price);
      } else {
        console.log('No data found',id1);
      }
    } catch (error) {
      console.error(error);
      console.log('Failed to fetch car price');
    }
    try {
        const response = await axios.get(`https://api.drivenappinc.com/users/${id1}`);
        console.log('User data response:', response.data); // Add this line
    
        if (response.data && response.data.length > 0) {
          setcarowner(response.data[0]);
          console.log(carowner);
        } else {
          console.log('No data found');
        }
      } catch (error) {
        console.error(error);
        console.log('Failed to fetch car price');
      }
  };
  useEffect(() => {
    const fetchPrice = async () => {
      handleChange();
    };

    fetchPrice();
    const intervalId = setInterval(fetchPrice, 10000);

    return () => clearInterval(intervalId);
  }, [carId,id1]);

  useEffect(() => {
    if (!isLoading) {
      setCarContent(i18n.language === 'ar' ? carContentAr : carContentEn);
    }
  }, [i18n.language, isLoading, carContentAr, carContentEn]);

  const BidPopup = ({ onClose }) => {
    const [user] = useState(JSON.parse(sessionStorage.getItem('user')) || {});
    const users = user.data ? user.data[0] : {};
    const payment = users.payment;
    const userId = users.id;
    const navigate = useNavigate();

    useEffect(() => {
      if (!userId) {
        navigate('/login');
      }
    }, [userId, navigate]);

    const [formData, setFormData] = useState({
      BidAmount: '',
      BidEndTime: cars?.[0]?.expiry_date || '',
      UserID: userId,
      AuctionID: cars?.[0]?.id || ''
    });

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value
      });
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      const bidAmount = parseFloat(formData.BidAmount);
      const userPayment = payment / 0.3;

      if (bidAmount < cars?.[0]?.previous_price) {
        alert(`Please add minimum Incremental Amount ${cars?.[0]?.previous_price}`);
        return;
      }

      if (bidAmount > userPayment) {
        alert('Insufficient balance. Please recharge your account.');
      } else {
        try {
          await axios.post('https://api.drivenappinc.com/bids', formData);
          alert("Bid Successfully!");
          onClose();
        } catch (error) {
          console.error('Error:', error);
        }
      }
    };

    return (
      <div id="popup" style={{ backgroundColor: 'white', minWidth: '90%', minHeight: '200px', zIndex: 999, borderRadius: '20px', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', position: 'absolute', paddingRight: '50px' }}>
        <div align="right">
          <button onClick={onClose} className="m-3 btn btn-danger">Close</button>
          <button onClick={handleSubmit} className="m-3 btn btn-danger">Submit</button>
        </div>
        <div className="row">
          <div style={{ width: '300px', margin: '0px auto' }}>
            <label className="form-label m-3" htmlFor="cnumber">Bid Amount</label>
            <br />
            <span style={{ marginLeft: '20px' }}>Minimum Increment: {cars?.[0]?.previous_price}</span>
            <input
              onChange={handleChange}
              type="number"
              className="form-control m-3 w-80"
              name="BidAmount"
              id="cnumber"
              placeholder='Enter Incremental Bid Amount'
            />
          </div>
        </div>
      </div>
    );
  };

  const Buypopup = ({ onClose }) => {
    const [user] = useState(JSON.parse(sessionStorage.getItem('user')) || {});
    const users = user.data ? user.data[0] : {};
    const userId = users.id;
    const navigate = useNavigate();

    useEffect(() => {
      if (!userId) {
        navigate('/login');
      }
    }, [userId, navigate]);

    const [formData, setFormData] = useState({
      BidAmount: '',
      BidEndTime: cars?.[0]?.expiry_date || '',
      UserID: userId,
      AuctionID: cars?.[0]?.id || ''
    });

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value
      });
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        await axios.post('https://api.drivenappinc.com/buynowrequest', formData);
        alert("Buy Now request submitted successfully!");
        onClose();
      } catch (error) {
        console.error('Error:', error);
      }
    };

    return (
      <div id="popup" style={{ backgroundColor: 'white', minWidth: '90%', minHeight: '200px', zIndex: 999, borderRadius: '20px', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', position: 'absolute', paddingRight: '50px' }}>
        <div align="right">
          <button onClick={onClose} className="m-3 btn btn-danger">Close</button>
          <button onClick={handleSubmit} className="m-3 btn btn-danger">Submit</button>
        </div>
        <div className="row">
          <div style={{ width: '300px', margin: '0px auto' }}>
            <label className="form-label m-3" htmlFor="cnumber">Buy Amount</label>
            <input
              onChange={handleChange}
              type="number"
              className="form-control m-3 w-80"
              name="BidAmount"
              id="cnumber"
              placeholder='Enter Buy Now Amount'
            />
          </div>
        </div>
      </div>
    );
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }


  const newfeature_image = '' ? cars[0]?.feature_image : feature_image
const images = [
  
];


function ImageSlider() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? carImages.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === carImages.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      goToNext();
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [currentIndex]); // Only re-run the effect if currentIndex changes

  return (
    <div className="slider2">
      <button onClick={goToPrevious} className="left-arrow">
        ❮
      </button>
      <div className="slide">
        <img src={carImages[currentIndex].Image} alt={`Slide ${currentIndex}`} />
      </div>
      <button onClick={goToNext} className="right-arrow">
        ❯
      </button>
    </div>
  );
}





  const description = carContent[0]?.description || carContent.description;
  const terms = carContent[0]?.terms || carContent.terms;

  return (
    <div>

      <Navbar />
     
      {isLoading && carprice !== '' ?
        (
          <div style=
            {{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              position: 'fixed'
            }}>
            <Circles
              height="80"
              width="80"
              color="#FFC107"
              ariaLabel="circles-loading"
              wrapperclassName=""
              visible={true}
            />
          </div>
          
        ) : (cars &&
          <div class="auction-details">
            {isMobile && 
            <div class="floating_contact">
            <div class="flex flex-col md:flex-row justify-center">
                <div class="flex bg-transaparent item-ceter rounded-md text-black">
                    <div><a target="_blank"
                        href={carowner?.phone ? `https://wa.me/${carowner?.phone}` : 'https://wa.me/+971545127066'}><img src="/contact/1.png" alt="favorite"  width="46" height="46" loading="lazy" rel="preload" aria-label="Add to Watchlist" className=""/></a>
                    </div>
                </div>
                <div class="flex bg-transaparent item-ceter rounded-md text-black ">
                    <div ><a class="p-1" href={carowner?.email ? `mailto:${carowner?.email}` : ''}> <svg height="50px" width="50px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 64 64" xmlSpace="preserve">
    <g>
      <path fill="#D1A552" d="M32,0C14.328,0,0,14.328,0,32s14.328,32,32,32s32-14.328,32-32S49.672,0,32,0z M46.32,16l-14.4,14.4 L17.52,16H46.32z M50.4,46.32H13.6V21.28L31.92,39.6c0.56,0.56,1.44,0.56,2,0L50.4,23.28V46.32z"/>
    </g>
  </svg>
                    </a></div>
                </div>
                <div class="flex bg-transaparent item-ceter rounded-md text-black ">
                    <div ><a target="_blank" href={carowner?.phone ? `tel:${carowner?.phone}` : 'tel:+971545127066'}> <img src="/contact/2.png" alt="favorite"  width="46" height="46" loading="lazy" rel="preload" aria-label="Add to Watchlist" className=""/>
                    </a>
</div>
                </div>
            </div>
        </div>
        }
          <div class="container mx-auto px-4">
              <div class="flex justify-between items-center gap-4">
                  <div>
                      <h4 class="text-2xl md:text-4xl font-bold">{carContent[0] == null ? carContent.title : carContent[0].title}</h4>
                      {/* <p class="mt-3 text-[12px]">{t('Lot')} # <span class="text-warning font-semibold">{cars[0].id}</span> | {t('End_Date')}: <span>{cars[0].expiry_date ? new Date(cars[0].expiry_date).toISOString().split('T')[0] : ''} </span></p> */}
                  </div>
                  <div><button onClick={() => {addwishlist(carId)}}
                          class={`border text-warning ${isInWishlist ? 'bg-danger' : 'border-yellow-800'} hover:text-black duration-300 rounded py-1.5 px-3 flex justify-center items-center gap-x-1`}><svg
                              stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em"
                              width="1em" xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M458.4 64.3C400.6 15.7 311.3 23 256 79.3 200.7 23 111.4 15.6 53.6 64.3-21.6 127.6-10.6 230.8 43 285.5l175.4 178.7c10 10.2 23.4 15.9 37.6 15.9 14.3 0 27.6-5.6 37.6-15.8L469 285.6c53.5-54.7 64.7-157.9-10.6-221.3zm-23.6 187.5L259.4 430.5c-2.4 2.4-4.4 2.4-6.8 0L77.2 251.8c-36.5-37.2-43.9-107.6 7.3-150.7 38.9-32.7 98.9-27.8 136.5 10.5l35 35.7 35-35.7c37.8-38.5 97.8-43.2 136.5-10.6 51.1 43.1 43.5 113.9 7.3 150.8z">
                              </path>
                          </svg></button></div>
              </div>
              {!isMobile && isPopupVisible && <BidPopup onClose={togglePopup}/>}
              {!isMobile && isPopupVisible1 && <Buypopup onClose={togglePopup1}/>}
              <div class="flex flex-col lg:flex-row items-start gap-4 lg:gap-8 max-w-full overflow-hidden mt-12">
                  <div class="w-full lg:w-[45%]">
                      <div class="w-full h-auto">
                          <div class="all-initial"
                              style={{cursor: 'crosshair', width: 'auto', height: 'auto', fontSize: '0px', position: 'relative', userSelect: "none"}}>
                                <ImageSlider />
                              {/* <img alt="Vehicle photo" class="all-initial"
                                  src={feature_image === '' ? cars[0]?.feature_image : feature_image}
                                  style={{width: '100%', height: 'auto', display: 'block', pointerEvents: "none"}} /> */}
                              <div>
                              <div style={{width: '100%', height: '37px', inset: '103px auto auto', display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.4)', position: 'absolute', opacity: '0', transition: 'opacity 300ms ease-in 0s'}}>
                                  </div>
                                  <div
                                      style={{width: '0px', height: '66px', inset: '103px auto auto', display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.4)', position: 'absolute', opacity: '0', transition: 'opacity 300ms ease-in 0s'}}>
                                  </div>
                                  <div
                                      style={{width: '225px', height: '66px', inset: '103px auto auto', display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.4)', position: 'absolute', opacity: '0', transition: 'opacity 300ms ease-in 0s'}}>
                                  </div>
                                  <div
                                      style={{width: '100%', height: '154px', inset: '103px auto auto', display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.4)', position: 'absolute', opacity: '0', transition: 'opacity 300ms ease-in 0s'}}>
                                  </div>
                              </div>
                          </div>
                          <div class="mt-10 mb-10 grid grid-cols-4 gap-2 h-[230px] overflow-y-auto">
                          
                {carImages.map((car) => (
                  <div key={car.ID} className="car-image-group">
                          <div key={car.ID} className="rounded-md cursor-pointer">
                          <img
                            onClick={() => setFeatureImage(car.Image)}
                            alt={`car-${car.ID}-image-${car.CarID}`}
                            loading="lazy"
                            width="400"
                            height="400"
                            decoding="async"
                            data-nimg="1"
                            className="w-full object-cover rounded-md cursor-pointer"
                            src={car.Image}
                            srcSet={car.Image}
                            style={{ color: 'transparent' }}
                        />
                          </div>
                  </div>
              ))}
                          </div>
                      </div>

                      <ul class="nav nav-pills mb-3 bg-warning" id="pills-tab" role="tablist">
  <li class="nav-item" role="presentation">
    <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">{t('Details')}</button>
  </li>
  {/* <li class="nav-item" role="presentation">
    <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">{t('Term')}</button>
  </li> */}
</ul>
<div class="tab-content" id="pills-tabContent">
  <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
  <h4 className='text-2xl md:text-4xl font-bold' style={{textDecoration: 'underline'}}>
                    
                  </h4>
                  <p style={{paddingLeft: '20px'}} dangerouslySetInnerHTML={{ __html: description }} />
  </div>
  {/* <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
  <h4 className='text-2xl md:text-4xl font-bold' style={{textDecoration: 'underline'}}>
                  </h4>
                  <p style={{paddingLeft: '20px'}} dangerouslySetInnerHTML={{ __html: terms }} />
  </div> */}
</div>


                      
                  
                  </div>

                  
                  <div class="w-full lg:w-[30%]">
                      <div class="border border-warning rounded overflow-hidden">
                          <div class="bg-warning text-black rounded p-3">
                              <h4 class="text-lg font-semibold">{t('Vehicle_Informations')}</h4>
                          </div>
                          <div class="p-3">
                              <div>
                                  <div class="flex flex-col">
                                      <div>
                                          <div class="flex justify-between gap-x-1 py-1">
                                              <p class="font-semibold text-sm">{t("Vin")}:</p>
                                              <p class="font-semibold text-sm">{cars[0].Vin_Number}</p>
                                          </div>
                                          <div class="bg-warning w-full h-[1px] my-1 bg-opacity-20"></div>
                                      </div>
                                      
                                  </div>
                              </div>
                              <div>
                                  <div class="flex flex-col">
                                      <div>
                                          <div class="flex justify-between gap-x-1 py-1">
                                              <p class="font-semibold text-sm">{t("brands")}:</p>
                                              <p class="font-semibold text-sm">{carContent[0] == null ? carContent.brand : carContent[0].brand}</p>
                                          </div>
                                          <div class="bg-warning w-full h-[1px] my-1 bg-opacity-20"></div>
                                      </div>
                                      
                                  </div>
                              </div>
                              <div>
                                  <div class="flex flex-col">
                                      <div>
                                          <div class="flex justify-between gap-x-1 py-1">
                                              <p class="font-semibold text-sm">{t("models")}:</p>
                                              <p class="font-semibold text-sm">{carContent[0] == null ? carContent.car_model : carContent[0].car_model}</p>
                                          </div>
                                          <div class="bg-warning w-full h-[1px] my-1 bg-opacity-20"></div>
                                      </div>
                                      
                                  </div>
                              </div>
                              <div>
                                  <div class="flex flex-col">
                                      <div>
                                          <div class="flex justify-between gap-x-1 py-1">
                                              <p class="font-semibold text-sm">{t("Power")}:</p>
                                              <p class="font-semibold text-sm">{carContent[0] == null ? carContent.Power : carContent[0].Power}</p>
                                          </div>
                                          <div class="bg-warning w-full h-[1px] my-1 bg-opacity-20"></div>
                                      </div>
                                      
                                  </div>
                              </div>

                              <div>
                                  <div class="flex flex-col">
                                      <div>
                                          <div class="flex justify-between gap-x-1 py-1">
                                              <p class="font-semibold text-sm">{t("Year")}:</p>
                                              <p class="font-semibold text-sm">{cars[0].year}</p>
                                          </div>
                                          <div class="bg-warning w-full h-[1px] my-1 bg-opacity-20"></div>
                                      </div>
                                      
                                  </div>
                              </div>
                              <div>
                                  <div class="flex flex-col">
                                      <div>
                                          <div class="flex justify-between gap-x-1 py-1">
                                              <p class="font-semibold text-sm">{t("Engine_cylinders")}:</p>
                                              <p class="font-semibold text-sm">{carContent[0] == null ? carContent.Engine_cylinders : carContent[0].Engine_cylinders}</p>
                                          </div>
                                          <div class="bg-warning w-full h-[1px] my-1 bg-opacity-20"></div>
                                      </div>
                                      
                                  </div>
                              </div>
                              <div>
                                  <div class="flex flex-col">
                                      <div>
                                          <div class="flex justify-between gap-x-1 py-1">
                                              <p class="font-semibold text-sm">{t("Specs")}:</p>
                                              <p class="font-semibold text-sm">{carContent[0] == null ? carContent.Specs : carContent[0].Specs}</p>
                                          </div>
                                          <div class="bg-warning w-full h-[1px] my-1 bg-opacity-20"></div>
                                      </div>
                                  </div>
                              </div>
                              <div>
                                  <div class="flex flex-col">
                                      <div>
                                          <div class="flex justify-between gap-x-1 py-1">
                                              <p class="font-semibold text-sm">{t("Exterior")}:</p>
                                              <p class="font-semibold text-sm">{carContent[0] == null ? carContent.Exterior_color : carContent[0].Enterior_color}</p>
                                          </div>
                                          <div class="bg-warning w-full h-[1px] my-1 bg-opacity-20"></div>
                                      </div>
                                      
                                  </div>
                              </div>
                              <div>
                                  <div class="flex flex-col">
                                      <div>
                                          <div class="flex justify-between gap-x-1 py-1">
                                              <p class="font-semibold text-sm">{t("Warranty")}:</p>
                                              <p class="font-semibold text-sm">{carContent[0] == null ? carContent.Warranty : carContent[0].Warranty}</p>
                                          </div>
                                          <div class="bg-warning w-full h-[1px] my-1 bg-opacity-20"></div>
                                      </div>
                                      
                                  </div>
                              </div>
                              <div>
                                  <div class="flex flex-col">
                                      <div>
                                          <div class="flex justify-between gap-x-1 py-1">
                                              <p class="font-semibold text-sm">{t("Interior")}:</p>
                                              <p class="font-semibold text-sm">{carContent[0] == null ? carContent.Interior_color : carContent[0].Interior_color}</p>
                                          </div>
                                          <div class="bg-warning w-full h-[1px] my-1 bg-opacity-20"></div>
                                      </div>
                                      
                                  </div>
                              </div>
                              <div>
                                  <div class="flex flex-col">
                                      <div>
                                          <div class="flex justify-between gap-x-1 py-1">
                                              <p class="font-semibold text-sm">{t("Body")}:</p>
                                              <p class="font-semibold text-sm">{carContent[0] == null ? carContent.bodu_type : carContent[0].body_type}</p>
                                          </div>
                                          <div class="bg-warning w-full h-[1px] my-1 bg-opacity-20"></div>
                                      </div>
                                      
                                  </div>
                              </div>
                              <div>
                                  <div class="flex flex-col">
                                      <div>
                                          <div class="flex justify-between gap-x-1 py-1">
                                              <p class="font-semibold text-sm">{t("Transmission_type")}:</p>
                                              <p class="font-semibold text-sm">{carContent[0] == null ? carContent.transmission_type : carContent[0].transmission_type}</p>
                                          </div>
                                          <div class="bg-warning w-full h-[1px] my-1 bg-opacity-20"></div>
                                      </div>
                                      
                                  </div>
                              </div>
                              <div>
                                  <div class="flex flex-col">
                                      <div>
                                          <div class="flex justify-between gap-x-1 py-1">
                                              <p class="font-semibold text-sm">{t("Fuel_type")}:</p>
                                              <p class="font-semibold text-sm">{carContent[0] == null ? carContent.fuel_type : carContent[0].fuel_type}</p>
                                          </div>
                                          <div class="bg-warning w-full h-[1px] my-1 bg-opacity-20"></div>
                                      </div>
                                      
                                  </div>
                              </div>
                              <div>
                                  <div class="flex flex-col">
                                      <div>
                                          <div class="flex justify-between gap-x-1 py-1">
                                              <p class="font-semibold text-sm">{t("Categoryf")}:</p>
                                              <p class="font-semibold text-sm">{t('UseD_Cars')}</p>
                                          </div>
                                          <div class="bg-warning w-full h-[1px] my-1 bg-opacity-20"></div>
                                      </div>
                                  </div>
                              </div>
                              
                              {/* <div>
                                  <div class="flex flex-col">
                                      <div>
                                          <div class="flex justify-between gap-x-1 py-1">
                                              <p class="font-semibold text-sm">{t("Trim")}:</p>
                                              <p class="font-semibold text-sm">{carContent[0] == null ? carContent.trim : carContent[0].trim}</p>
                                          </div>
                                          <div class="bg-warning w-full h-[1px] my-1 bg-opacity-20"></div>
                                      </div>
                                  </div>
                              </div> */}
                              <div>
                                  <div class="flex flex-col">
                                      <div>
                                          <div class="flex justify-between gap-x-1 py-1">
                                              <p class="font-semibold text-sm">{t("Power")}:</p>
                                              <p class="font-semibold text-sm">{carContent[0] == null ? carContent.category : carContent[0].category}</p>
                                          </div>
                                          <div class="bg-warning w-full h-[1px] my-1 bg-opacity-20"></div>
                                      </div>
                                      
                                  </div>
                              </div>
                              {/* <div>
                                  <div class="flex flex-col">
                                      <div>
                                          <div class="flex justify-between gap-x-1 py-1">
                                              <p class="font-semibold text-sm">{t("address")}:</p>
                                              <p class="font-semibold text-sm">{carContent ? (carContent[0] == null ? carContent.address : carContent[0].address) : ''}</p>
                                          </div>
                                          <div class="bg-warning w-full h-[1px] my-1 bg-opacity-20"></div>
                                      </div>
                                      
                                  </div>
                              </div> */}
                              {/* <div>
                                  <div class="flex flex-col">
                                      <div>
                                          <div class="flex justify-between gap-x-1 py-1">
                                              <p class="font-semibold text-sm">{t("documents")}:</p>
                                              <p class="font-semibold text-sm"><a href={`${documents}`} download="filename.pdf" className='btn btn-warning'>Download</a>                                              </p>
                                          </div>
                                          <div class="bg-warning w-full h-[1px] my-1 bg-opacity-20"></div>
                                      </div>
                                      
                                  </div>
                              </div> */}
                          </div>
                      </div>
                  </div>
                  
                  <div class="w-full lg:w-[25%]">
                      <div class="border border-warning rounded overflow-hidden mb-2">
                          <div class="bg-warning text-black rounded p-3">
                              <h4 class="text-lg font-semibold">{t('Vehicle')}</h4>
                          </div>
                          <div class="p-3">
                              <div class="flex flex-col gap-4 justify-center items-center">
                                  <div class="text-xl"><span style={{fontSize: '24px'}}>{t('Price')} : AED</span>  <span style={{fontSize: '30px', fontWeight:'bolder', color: '#036eb5'}}>{carprice}</span></div>
                                  <div style={{display: "flex"}}> 
                                  <a class="m-1" target="_blank" href={carowner?.phone ? `tel:${carowner?.phone}` : 'tel:+971545127066'}>
                                  <button
  className="btn bg-transparent p-1 text-black rounded-md hover:bg-opacity-70 duration-200"
  style={{ minWidth: '100px', padding: '5px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
>
  {carowner.name}
  <img src={`${carowner.image ? carowner.image : '/All_users.png'}`} alt="123" style={{ height: '40px', width: '40px', marginLeft: '5px' }} />
</button>
</a></div>
                              </div>
                          </div>
                      </div>
                      {isMobile && isPopupVisible && <BidPopup onClose={togglePopup}/>}
                  {isMobile && isPopupVisible1 && <Buypopup onClose={togglePopup1}/>}
                  {!isMobile &&
                      <div class="border border-warning rounded overflow-hidden mt-4">
                          <div class="bg-warning text-black rounded p-2">
                              <h4 class="text-lg font-semibold">{t('Contact_us_for_more_information')}</h4>
                          </div>
                          <div class="p-3">
                              <div class="flex flex-col md:flex-row justify-center gap-2">
                                  <button class="flex btn bg-transparent  p-2 item-ceter rounded-md text-black">
                                      <div class="px-1"><a class="m-1" target="_blank"
                                          href={carowner?.phone ? `https://wa.me/${carowner?.phone}` : 'https://wa.me/+971545127066'}>
                                      <img src="/contact/1.png" alt="favorite"  width="46" height="46" loading="lazy" rel="preload" aria-label="Add to Watchlist" className=""/>
                                      </a></div>
                                  </button>
                                  <div class="flex btn  bg-transparent  p-1 p-y-2 item-ceter rounded-md text-black " style={{marginLeft: '15px',marginRight: '15px'}}>
                                      <div ><a class="m-1" href={carowner?.email ? `mailto:${carowner?.email}` : ''}>
                                      <svg height="50px" width="50px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 64 64" xmlSpace="preserve">
    <g>
      <path fill="#D1A552" d="M32,0C14.328,0,0,14.328,0,32s14.328,32,32,32s32-14.328,32-32S49.672,0,32,0z M46.32,16l-14.4,14.4 L17.52,16H46.32z M50.4,46.32H13.6V21.28L31.92,39.6c0.56,0.56,1.44,0.56,2,0L50.4,23.28V46.32z"/>
    </g>
  </svg>
                                      </a>
                                      </div>
                                  </div>
                                  <div class="flex btn  bg-transparent p-1 p-y-2 item-ceter rounded-md text-black ">
                                      <div >
                                      <a class="m-1" target="_blank" href={carowner?.phone ? `tel:${carowner?.phone}` : 'tel:+971545127066'}>
                                      <img src="/contact/2.png" alt="favorite"  width="46" height="46" loading="lazy" rel="preload" aria-label="Add to Watchlist" className=""/>
                                      </a></div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      }

                      <div class="border border-warning rounded overflow-hidden mt-4">
                          <div class="bg-warning text-black rounded p-2">
                              <h4 class="text-lg font-semibold">{t('address')}</h4>
                          </div>
                          <MapComponentshower latitude={cars[0].latitude} longitude={cars[0].longitude}/>
                          
                      </div>
                  </div>
              </div>
          </div>
          
      </div>
          )}
    <div>

    </div>
    </div>

  )
}

export default SaleDetails;