import React from 'react'
import { useEffect, useState, useRef } from "react";
//import { carsColumns } from "../../datatablesource";
import { Circles } from 'react-loader-spinner';
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import { StatusDropdown, FeaturedDropdown } from '../../components/customDataButtons/customButtons';
import List from '../list/List'
import ImagePopup from './imagepopup';




const Paymentsadmin = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true)
    const childRef = useRef(null);
    const [popupImageUrl, setPopupImageUrl] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  // Function to handle opening the popup with the image URL
  const handleImageClick = (imageUrl) => {
    setPopupImageUrl(imageUrl);
    setIsPopupOpen(true);
  };

  // Function to close the popup
  const closePopup = () => {
    setIsPopupOpen(false);
    setPopupImageUrl(''); // Reset the image URL when closing the popup
  };


    useEffect(() => {
        const fetchDataForPosts = async () => {
          try {
            const response = await fetch(
              `https://api.drivenappinc.com/pay`
            );
            if (!response.ok) {
              throw new Error(`HTTP error: Status ${response.status}`);
            }
            let postsData = await response.json();
            setData(postsData);
            setIsLoading(false)
          } catch (err) {
            console.log(err.message)
            setIsLoading(false)
          }
        };
    
        fetchDataForPosts();
      }, [])
      const [getFeature, setGetFeature] = useState(data)
      const [getStatus, setGetStatus] = useState(data)
      const handleFeaturedChange = (id, newValue) => {
        setGetFeature(getFeature.map(row => (row.id === id ? { ...row, featured: newValue } : row)));
      };

      const handleStatusChange = (id, newValue) => {
        setGetStatus(getStatus.map(row => (row.id === id ? { ...row, status: newValue } : row)));
      };

      const paymentColumns = [
        { field: "id", headerName: "ID", width: 70 },
        {
          field: "user_id",
          headerName: "User_id",
          width: 100,
          
        },
        {
          field: "receipt_image",
          headerName: "Receipt Image",
          width: 230,
          renderCell: (params) => {
            return (
              <div className="cellWithImg">
                <img onClick={() => handleImageClick(params.row.receipt_image)} className="cellImg" src={params.row.receipt_image} alt="avatar" />
              </div>
            );
          }
        },
      
        {
          field: "username",
          headerName: "Sender Name",
          width: 230,
        },
        {
          field: "price",
          headerName: "price",
          width: 160
        }
      ];
      
    return (
        <>
        <div className="list">
          <Sidebar/>
          <div className="listContainer">
            <Navbar/>
            <ImagePopup
        imageUrl={popupImageUrl}
        isOpen={isPopupOpen}
        onRequestClose={closePopup}
      />
            {isLoading ? (
              <div style=
                {{ 
                  display: 'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center', 
                  top: 0, 
                  bottom: 0, 
                  right: 0, 
                  left: 0, 
                  position: 'fixed' 
                }}>
                <Circles
                height="80"
                width="80"
                color="#FFC107"
                ariaLabel="circles-loading"
                wrapperClass=""
                visible={true}
                />
              </div>
            ) : (data && (
                <List data={data} columns={paymentColumns} setData={setData} title={"Payments"}/> 
              )
            )}
          </div>
        </div>
      </>
    )
}

export default Paymentsadmin