import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';

const BidPopup = ({ onClose, endDate, auctionId ,item}) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [formData, setFormData] = useState({
    BidAmount: '',
    BidEndTime: endDate,
    UserID: '',
    AuctionID: auctionId,
    item: 'Number'
  });
  console.log(item);

  useEffect(() => {
    const storedUser = JSON.parse(sessionStorage.getItem('user'));
    if (storedUser && storedUser.data && storedUser.data[0]) {
      const userData = storedUser.data[0];
      setUser(userData);
      setFormData(prevFormData => ({
        ...prevFormData,
        UserID: userData.id
      }));
    } else {
      navigate('/login');
    }
  }, [navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if(formData.BidAmount < item.increment){
      alert("Please add minimum increment!")
    }else{
    axios.post(`https://api.drivenappinc.com/bids`, formData)
      .then(response => {
        console.log('Response:', response.data);
        onClose(); // Close the popup after successful submission
      })
      .catch(error => {
        console.error('Error:', error);
      });}
  };

  if (!user) {
    return null;
  }

  return (
    <div id="popup" style={{ backgroundColor: 'white', Width: '400px', minHeight: '200px', position: 'fixed', top: '20%',left: '10px', zIndex: 999, borderRadius: '20px', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', paddingRight: '50px' }}>
      <div align="right">
        <button onClick={onClose} className="m-3 btn btn-danger">
          Close
        </button>
        <button onClick={handleSubmit} className="m-3 btn btn-danger">
          Submit
        </button>
      </div>
      <div className="row">
        <div style={{ width: '300px', margin: '0px auto' }}>
          <label className="form-label m-3" htmlFor="cnumber">
            Bid Amount
          </label>
          <p style={{color:'black', fontSize:'20px'}}>{t('Current_Price')}: {item.amount}</p>
          <p style={{color:'black', fontSize:'20px'}}>{t('min_increment')}: {item.increment}</p>
          <input
            onChange={handleChange}
            type="number"
            className="form-control m-3 w-80"
            name="BidAmount"
            id="cnumber"
            placeholder='Enter Bid Amount'
          />
        </div>
      </div>
    </div>
  );
};

export default BidPopup;
