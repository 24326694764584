import React from 'react';
import Modal from 'react-modal';

const ImagePopup = ({ imageUrl, isOpen, onRequestClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Image Popup"
      className="image-popup-modal"
    >
      <div className="image-popup-content bg-white" style={{marginLeft: '10%', marginTop: '2%'}}>
        <button className="close-btn btn btn-warning" onClick={onRequestClose}>
          Close
        </button>
        <img src={imageUrl} alt="Popup Image" className="popup-image mt-2" />
      </div>
    </Modal>
  );
};

export default ImagePopup;
