import i18n from 'i18next'
import languagedetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next'

i18n.use(languagedetector).use(initReactI18next).init({
    resources:{
        en:{
            translation: {
                Ep: "Email or Phone Number",
                Details: 'Details',
                name: "Auction",
                home: "Home",
                motor: 'Motors',
                Numbers: 'Prestigious Numbers',
                Properties: 'Properties',
                Surplus: 'Surplus',
                Rent: "For Rent",
                Category: "Popular Categories",
                Body: "Body Type",
                Popular: "Most Popular",
                Viewallmotors: 'VIEW ALL MORORS',
                endingtoday:  'ENDING TODAY',
                Property: "Property Type",
                Locat: "Location",
                Ownership: "Ownership Type",
                availableproperties: "All AVAILABLE PROPERTIES",
                PROPERTIESENDTODAY: "PROPERTIES END TODAY",
                REOPENTHEAUCTION: "REOPEN THE AUCTION",
                Comming: "Comming Soon",
                abudabi: "Abu Dabi Plates",
                sharjah: "Sharjah Plates",
                Ajman: 'Ajman Plates',
                Rak: "RAK Plates",
                UAQ: "UAQ Plates",
                Fujarah: "Fujairah Plates",
                Dubai: "Dubai Plates",
                Numberplate: 'Number Plate',
                Etisalat: 'Etisalat',
                Premiumpackages: "Premium Packages",
                Tags: 'Tags',
                Requestyourfavoritenumber: "Request Your Favorite Number",
                toyota: "Toyota",
                nissan: "Nissan",
                Mercedes: 'Mercedes',
                Audi: "Audi",
                Volvo: 'Volvo',
                BMW: 'BMW',
                Filters: "Filters",
                Reset: "Reset All Filter",
                ApplyFilter: "Apply Filter",
                Categoryf: "Category",
                Key: "Key",
                Available: "Available Items",
                Available_plates: 'Available Plates',
                VM: "Vehicles & Machinery",
                Vehicle: "Vehicle",
                brands: "Brands",
                models: "Models",
                Price_Range: "Price Range (AED)",
                Year_Range: "Year Range",
                milage: "Milage",
                milage_range: "Milage Range (KM)",
                min_price: "Min Price",
                max_price: "Max Price",
                min_milage: "Min Mileage",
                max_milage: "Max Mileage",
                start_year: "Start Year",
                end_year: "End Year",
                Login: "Login",
                Username: "Username",
                Password: 'Password',
                signup: "Sign Up",
                Phone: "Phone",
                Email: "Email",
                Logout: "Logout",
                Bids: "Bids",
                Profile: 'Profile',
                Mobile: "Mobile Number",
                MEmail: "My Email",
                CPassword: "Change Password",
                Documents: "My Documents",
                Address: "Address",
                address: "Address",
                Passwordline: 'We recommend updating your password periodically to ensure account security.',
                Cnumber: 'Change Number',
                Cemail: 'Change Email',
                Vemail: "Verify Email",
                Verified: "Verified",
                NVerified: "Not Verified",
                AddAddress: "Add Address",
                CAddress: "Change Address",
                Addressline: "No Address Added Yet",
                My_Requests: "My Requests",
                My_purchases: "My Purchases",
                My_won: "MY Won",
                WishList: "WishList",
                My_Bids: "My Bids",
                LoginAdmin: "Login Admin",
                Umotor: "USA Motors",
                Cmotor: "Canada Motors",
                Kmotor: "Korean Motors",
                Location: "Building 11 - Office 6 Al Wakalat 4 St - Industrial Area - Abu Dhabi - United Arab Emirates",
                Reach: "Reach us",
                Service: "Our Service",
                about_auction: "Online auction is the previous exclusive agent for the sale of distinctive numbers of cars in the emirate of Fujairah, in cooperation with the Police General Headquarters Fujairah, and the exclusive agent to organize public and online auctions  for Emirates Integrated Telecommunications Company (DU), where in previous years has organized many successful public and online auctions  in all of Fujairah and Dubai, and achieved sales more than AED 100 million until the end of 2024.",
                About_Online_Auction: "About Online Auction",
                Interior: "Interior",
                Exterior: "Exterior",
                Year: "Year",
                Keys: "Keys",
                Doors: "Doors",
                more: "Known more",
                Lot: 'Lot',
                End_Date: 'End Date',
                Current_Price: "Current Price",
                min_increment: 'Minimum Increment',
                Bid_now: "Bid Now",
                Buy_now: 'Buy Now',
                Vehicle_Informations: "Vehicle Informations",
                Contact: "Contact",
                Contact_us_for_more_information: "Contact us for more information",
                Mail: "Mail",
                WhatsApp: "WhatsApp",
                Power: 'Power',
                Car_Condition: 'Category',
                Fuel_type: 'Fuel type',
                Transmission_type: 'Transmission type',
                Warranty: 'Warranty',
                Salvage: 'Salvage',
                currency: "AED",
                Ab: "Available Balance",                
                My_Wallet: "My Wallet",
                Engine_cylinders: "Engine Cylinders",
                Specs: "Specs",
                Vin: 'Vin Number',
                documents: 'Inspection documents',
                Download: "Download",
                Receipt_Image: 'Receipt Image',
                Price: 'Price',
                Payment_Form: 'Payment Form',
                AUsername: 'Account Holder Name',
                Term: 'Terms & Condition',
                catelog: 'Click to Download Auction catelog',
                Name: 'Full Name',
                Join: "Join Now & Start Bidding",
                Create: 'Create Account',
                fields_mandatory: 'All fields are mandatory',
                Time_up: "Time's up",
                time_left: 'Time Left',
                classic: 'Classic',
                Ending_in: 'Ending in',
                a_title: 'ICES GENERAL GLOBAL D',
                Currency: 'AED',
                bank_name: 'C Bank Community Maryah A',
                Account_Title: "Account Title",
                Account_Number: "Account Number",
                IBAN_Number: "IBAN Number",
                Swift_code: "Swift code",
                Bank_Name: "Bank Name",
                Deposit_Amount: "Deposit Amount",
                BDBT: 'Bank Deposit/Bank Transfer',
                Doyrbl: ' Deposit 20% of your required bidding limit',Ahtuya: "Allow 48 hrs to update your account",
                MSBR: 'Must submit bank receipt',
                Cheque: "Cheque",
                Submit_a_cheque: "Submit a cheque of required bidding limit",
                Limit_updates_immediately: "Limit updates immediately",Cash_Deposit: "Cash Deposit",
                Deposit_20_of: "Deposit 20% of your required bidding limit",
                Deposit_in_Branch: 'Deposit in Branch',
                Yblwbi: 'Your bidding limit will be instantly updated once you submit your security cheque/Cash.',
                checash: "Cheque/Cash must be submitted to one of Emirates Auctions' branches in person.",
                saccorbl: 'Submit a cheque/Cash of required bidding limit',
                Step_2: "Step 2",
                Step_3: "Step 3",
                psootf: 'Please select one of the following',
                iahads: 'I already have a deposit slip',
                iwtmand: ' I want to make a new deposit',
                cagdr: 'Click After Getting Deposit receipt',
                Viewallmotorsclassic: 'VIEW ALL CLASSIC',
                Viewallmotorssal: 'VIEW ALL SALVAGE',
                Youhavenopackage: 'You have no purchased package',
                Nationality: 'Nationality',
                Trim: 'Trim',
                Sale_Car: 'Add Listing',
                Select_Packages: 'Select Packages',
                Used_Cars: 'Used Cars For direct Sell',
                Total_numbers_of_Listing_Cars: 'Total numbers of Listing Cars',
                UseD_Cars: 'Used Cars',
                Close: 'Close'
            }
            
        },
        ar:{
            translation:{
                min_increment: 'الحد الأدنى للزيادة',
                Trim: 'تقليم',
                Available_plates: 'اللوحات المتوفرة',
                Ep: "البريد الإلكتروني أو رقم الهاتف",
                Nationality: 'جنسية',
                Youhavenopackage: 'ليس لديك أي حزمة تم شراؤها',
                Viewallmotorsclassic: 'عرض الكل كلاسيكي',
                Viewallmotorssal: 'عرض الكل الإنقاذ',
                classic: 'كلاسيكي',
                cagdr: 'انقر بعد الحصول على إيصال الإيداع',
                Step_3: "الخطوة 3",
                Step_2: "الخطوة 2",
                psootf: 'الرجاء اختيار واحد مما يلي',
                iahads: 'لدي بالفعل قسيمة إيداع',
                iwtmand: 'أريد أن أقوم بإيداع جديد',
                Yblwbi: 'سيتم تحديث حد المزايدة الخاص بك على الفور بمجرد تقديم شيك الضمان/النقد.',
                checash: "يجب تقديم الشيك/النقد شخصياً إلى أحد فروع الإمارات للمزادات.",
                saccorbl: 'قم بإرسال شيك/نقدًا بحد المزايدة المطلوب',
                Cash_Deposit: "إيداع نقدي",
                Deposit_20_of: "قم بإيداع 20% من حد المزايدة المطلوب",
                Deposit_in_Branch: 'الإيداع في الفرع',
                Cheque: "يفحص",
                Submit_a_cheque: "إرسال شيك من حد العطاءات المطلوبة",
                Limit_updates_immediately: "الحد من التحديثات على الفور",
                Ahtuya: "انتظر 48 ساعة لتحديث حسابك",
                MSBR: 'يجب تقديم إيصال البنك',
                Doyrbl: 'قم بإيداع 20% من حد المزايدة المطلوب',
                BDBT: 'الإيداع البنكي/التحويل البنكي',
                Deposit_Amount: "قيمة الايداع",
                Account_Title: "اسم الحساب",
                Account_Number: "رقم الحساب",
                IBAN_Number: "رقم آيبان",
                Swift_code: "SWIFTرمز",
                Bank_Name: "اسم البنك",
                a_title: ' دريفن العالمية للخدمات العامة',
                Currency: 'درهم',
                bank_name: 'بنك المارية المحلي ذ.م.م.',
                Join: "انضم الآن وابدأ المزايدة",
                time_left: 'الوقت المتبقي',
                fields_mandatory: 'جميع الحقول إلزامية',
                Create: 'إنشاء حساب',
                Name: 'الاسم الكامل',
                Key: 'مفتاح',
                catelog: 'انقر لتنزيل كتالوج المزاد',
                Term: 'الشروط والأحكام',
                Submit: 'يُقدِّم',
                Close: 'يغلق',
                Dashboard: 'لوحة القيادة',
                AUsername: 'اسم صاحب الحساب',
                Receipt_Image: 'صورة الاستلام',
                Price: 'سعر',
                Payment_Form: 'نموذج الدفع',
                Download: "تحميل",
                documents: 'وثائق التفتيش',
                Vin: 'رقم المركبة',
                Engine_cylinders: "اسطوانات المحرك",
                Specs: "المواصفات",
                Salvage: 'استخلاص',
                Power: 'قوة',
                Transmission_type: 'نوع التحويل',
                Warranty: 'ضمان',
                Car_Condition: 'حالة السيارة',
                Fuel_type: 'نوع الوقود',
                Interior: "الداخلية",
                Exterior: "الخارج",
                Year: "سنة",
                Keys: "مفاتيح",
                Doors: "أبواب",
                Contact: "اتصال",
                Call: "اتصال",
                Mail: "بريد",
                WhatsApp: "واتس اب",
                Contact_us_for_more_information: "اتصل بنا للحصول على مزيد من المعلومات",
                Current_Price: "السعر الحالي",
                Bid_now: "المزايدة الآن",
                Buy_now: 'اشتري الآن',
                End_Date: 'تاريخ الانتهاء',
                Lot: 'كثير',
                Details: "تفاصيل",
                name:"مزاد علني",
                motor: 'مركبات',
                Numbers: 'أرقام مميزة',
                Properties: 'عقارات',
                Surplus: 'مزادات متنوعة',
                Rent: "للإيجار",
                Category: "مزادات تنتهي اليوم",
                Body: "نوع المركبة",
                Popular: "المركبات الأكثر طلبًا",
                Viewallmotors: 'عرض جميع المركبات',
                endingtoday:  'مزادات تنتهي اليوم',
                Ending_in: 'تنتهي في',
                Property: "نوع العقار",
                Locat: "الموقع",
                Time_up: "انتهى الوقت",
                Ownership: "نوع التمَلُّك",
                availableproperties: "مزاد العقارات الحالي",
                PROPERTIESENDTODAY: "عقارات تنتهي اليوم",
                REOPENTHEAUCTION: "إعادة فتح المزاد",
                Comming: "قريباً",
                abudabi: "لوحات أبوظبي",
                sharjah: "لوحات الشارقة",
                Ajman: 'لوحات عجمان',
                Rak: "لوحات رأس الخيمة",
                UAQ: "لوحات أم القيوين",
                Fujarah: "لوحات الفجيرة",
                Dubai: "لوحات دبي",
                Numberplate: 'لوحات مميزة',
                Etisalat: 'اتصالات',
                Premiumpackages: "الباقات المميزة",
                Tags: 'Tags',
                Requestyourfavoritenumber: "اطلب رقمك المفضل",
                toyota: "تويوتا",
                nissan: "نيسان",
                Mercedes: 'مرسيدس',
                Audi: "اودي",
                Volvo: 'فولفو',
                BMW: 'بي ام دبليو',
                Filters: "فرز",
                Reset: "إعادة تعيين الفرز",
                ApplyFilter: "عرض النتائج",
                Categoryf: "الفئة",
                home: "الرئيسية",
                Available: "مركبة في المزاد",
                VM: "المركبات والآليات",
                brands: "العلامات التجارية",
                Speed: "سرعة",
                models: "نموذج",
                Price_Range: "نطاق السعر (AED)",
                Year_Range: "النطاق السنوي",
                Milage: 'ميلج',
                milage_range: "نطاق ميلج (KM)",
                min_price: "سعر دقيقة",
                max_price: "ماكس السعر",
                min_milage: "سألتقي",
                max_milage: "الحد الأقصى لعدد الكيلومترات",
                start_year: "سنة البدء",
                end_year: "نهاية السنة",
                Login: "تسجيل الدخول",
                Username: "اسم المستخدم",
                Password: 'كلمة المرور',
                signup: "اشتراك",
                Phone: "هاتف",
                Email: "بريد إلكتروني",
                Logout: "تسجيل خروج",
                Bids: "العطاءات",
                Profile: 'حساب تعريفي',
                Mobile: "رقم الهاتف المحمول",
                MEmail: "بريدي الالكتروني",
                CPassword: "تغيير كلمة المرور",
                Documents: "مستنداتي",
                Address: "عنوان",
                address: "عنوان",
                Passwordline: 'نوصي بتحديث كلمة المرور الخاصة بك بشكل دوري لضمان أمان الحساب',
                Cnumber: 'تغيير رقم',
                Cemail: 'تغيير الايميل',
                Vemail: "التحقق من البريد الإلكتروني",
                Verified: "تم التحقق",
                NVerified: "لم يتم التحقق منها",
                My_Requests: "طلباتي",
                My_purchases: "مشترياتي",
                My_won: "فاز بلدي",
                WishList: "قائمة الرغبات",
                My_Bids: "عروض الأسعار الخاصة بي",
                LoginAdmin: "مشرف تسجيل الدخول",
                Umotor: "الولايات المتحدة الأمريكية للسيارات",
                Cmotor: "كندا موتورز",
                Kmotor: "المحركات الكورية",
                Location: "مبنى 11 - مكتب 6 شارع الوكالات 4 - المنطقة الصناعية - أبوظبي - الإمارات العربية المتحدة",
                Reach: "الوصول إلينا",
                Service: "خدمتنا",
                about_auction: "المزاد الإلكتروني هو الوكيل الحصري السابق لبيع أرقام مميزة من السيارات في إمارة الفجيرة، بالتعاون مع القيادة العامة لشرطة الفجيرة، والوكيل الحصري لتنظيم المزادات العامة والإلكترونية لشركة الإمارات للاتصالات المتكاملة (دو)، حيث نظمت في الأعوام السابقة العديد من المزادات العامة والإلكترونية الناجحة في عموم الفجيرة ودبي، وحققت مبيعات تجاوزت 100 مليون درهم حتى نهاية عام 2024.",
                About_Online_Auction: "حول المزاد عبر الإنترنت",
                more: "معروف أكثر",
                Vehicle: "عربة",
                Vehicle_Informations: "معلومات المركبة",
                currency: "درهم إماراتي",
                Ab: "الرصيد المتوفر",
                My_Wallet: "محفظتى",
                Sale_Car: 'إضافة قائمة',
                Select_Packages: 'حدد الحزم',
                Used_Cars: 'سيارات مستعملة للبيع المباشر',
                Total_numbers_of_Listing_Cars: 'إجمالي عدد السيارات المدرجة',
                UseD_Cars: 'السيارات المستعملة'
            }
            
        }
    }
})