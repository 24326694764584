// ComingSoonPage.js
import React from 'react';
import Navbar from '../../Navbar';

function ComingSoonPage() {
    return (
        <div>
            <Navbar />
        <div className="container commingsoon">
            <h1>Coming Soon</h1>
            <p>Stay tuned for exciting updates!</p>
        </div>
        </div>
    );
}

export default ComingSoonPage;
 