import React from 'react'
import { useEffect, useState } from "react";
import { Circles } from 'react-loader-spinner';
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import List from '../list/List'
import PdfViewer from './preview';

const Catelog = () => {

    const [state, setstate] = useState('');
    const handlep = (e) => {
        
        setstate(e);
      }
      
      const Catelogcol = [
        { field: "id", headerName: "ID", width: 70 },
        {
          field: "Catelog",
          headerName: "Catelog",
          width: 200,
          renderCell: (params) => {
                console.log(params.row.catelog);
                  return (
                    <a href={`${params.row.catelog}`} download="filename.pdf" className='btn btn-warning'>
                    <div className="cellWithImg">
                        Download
                    </div>
                    
                    </a>
                  );
                },
        }
      ]

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true)

    console.log(data);
  useEffect(() => {
    const fetchDataForPosts = async () => {
      try {
        const response = await fetch('https://api.drivenappinc.com/catelog');
        if (!response.ok) {
          throw new Error(`HTTP error: Status ${response.status}`);
        }
        const postsData = await response.json();
        setData(postsData);
        setIsLoading(false);
      } catch (err) {
        console.log(err.message);
        setIsLoading(false);
      }
    };

    fetchDataForPosts();
    const intervalId = setInterval(fetchDataForPosts, 10000000); // 10000 ms = 10 seconds

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, []);
  console.log(data);


    return (
        <>
          <div className="list">
          <Sidebar/>
          <div className="listContainer">
            <Navbar/>
            {isLoading ? (
              <div style=
                {{ 
                  display: 'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center', 
                  top: 0, 
                  bottom: 0, 
                  right: 0, 
                  left: 0, 
                  position: 'fixed' 
                }}>
                <Circles
                height="80"
                width="80"
                color="#FFC107"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                />
              </div>
            ) : (data && (
              <List data={data} columns={Catelogcol} setData={setData} title={"Catelog"} link={"Catelog"}/>
            ))}


            {state !== '' ? 
                <PdfViewer pdfUrl={state} /> : ''
            }
          </div>
          </div>
        </>
    )
}

export default Catelog