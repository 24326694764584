import React, { useState, useEffect } from 'react';
import './Card.css';
import AuctionCard from './AuctionCard';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import SaleAuctionCard from './Saledetailcard';
import AuctionGallery from './auctiongallerypage';
import axios from 'axios';
import UsaSaleAuctionCard from './usasaledetailscard';

const UsaSalCard = (items) => {
  const [item, setItem] = useState('');
//   const [documentcate, setdocumentcate]=useState();

  const [lenght, setlength] = useState();
  const { t } = useTranslation();
  useEffect(() => {
    
    const fetchDataForPosts = async () => {
        setlength(items.lenght1);
      };
  
    // Fetch data immediately on component mount
    fetchDataForPosts();
  
    // Set interval to fetch data every 20 seconds
    const intervalId = setInterval(() => {
      fetchDataForPosts();
    }, 20000);
  
    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);
  

  

  const calculateTimeLeft = () => {
    const difference = +new Date(item) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        D: Math.floor(difference / (1000 * 60 * 60 * 24)),
        H: Math.floor((difference / (1000 * 60 * 60)) % 24),
        M: Math.floor((difference / 1000 / 60) % 60),
        S: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };
  
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    // Cleanup timer on component unmount
    return () => clearInterval(timer);
  }, [item]);

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span key={interval}>
        {timeLeft[interval]}{interval} {":"}
      </span>
    );
  });

  return (
    <div className="styles_auctionsLayoutList__2WnvP col-md-9">
      <div style=
        {{
          width: '100%',
          gap: '1rem',
          alignItems: 'flex-start',
          display: 'flex',
          marginBottom: '1rem'
        }}
      >
        <div className='styles_ListHeaderContainer__Wj9_d'>
          <div>
            <div className='raw-html-embed'>
              <h1 style={{ fontSize: '1.1rem' }}>
                {t('VM')}
              </h1>
            </div>
            <p>
              <span style={{ color: '#e51937' }}>
                <strong>{lenght} </strong>
              </span>
              {t('Available')}
            </p>
          </div>
          {!isMobile && 
            <div className='styles_ListHeaderImagesContainer__T7mFd'>
            <span
              style={{ 
                boxSizing: 'border-box',
                display: 'inline-block',
                overflow: 'hidden',
                width: 'initial',
                height: 'initial',
                background: 'none',
                opacity: 1,
                border: '0px',
                margin: '0px',
                padding: '0px',
                position: 'relative',
                maxWidth: '100%'
               }}
            >
              <span
                style={{
                  boxSizing: 'border-box',
                  display: 'block',
                  width: 'initial',
                  height: 'initial',
                  background: 'none',
                  opacity: 1,
                  border: '0px',
                  margin: '0px',
                  padding: '0px',
                  maxWidth: '100%'
                }}
              >
                <img 
                  alt
                  aria-hidden="true"
                  src='data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2777%27%20height=%2770%27/%3e'
                  style={{
                    padding: '0px',
                    border: '0px',
                    margin: '0px',
                    display: 'block',
                    width: 'initial',
                    height: 'initial',
                    background: 'none',
                    opacity: 1,
                    maxWidth: '100%',
                  }}
                />
              </span>
              <img alt="logo" src="./images(29).png" 
              style={{
                position: 'absolute', 
                inset: '0px',
                boxSizing: 'border-box',
                padding: '0px', 
                border: 'none',
                margin: 'auto',
                display: 'block',
                width: '0px',
                height: '0px', 
                minWidth: '100%', 
                maxWidth: '100%', 
                minHeight: '100%', 
                maxHeight: '100%'
              }}
            />
            </span> 
            <span
              style={{ 
                boxSizing: 'border-box',
                display: 'inline-block',
                overflow: 'hidden',
                width: 'initial',
                height: 'initial',
                background: 'none',
                opacity: 1,
                border: '0px',
                margin: '0px',
                padding: '0px',
                position: 'relative',
                maxWidth: '100%'
               }}
            >
              <span
                style={{
                  boxSizing: 'border-box',
                  display: 'block',
                  width: 'initial',
                  height: 'initial',
                  background: 'none',
                  opacity: 1,
                  border: '0px',
                  margin: '0px',
                  padding: '0px',
                  maxWidth: '100%'
                }}
              >
                <img 
                  alt
                  aria-hidden="true"
                  src='data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2777%27%20height=%2770%27/%3e'
                  style={{
                    padding: '0px',
                    border: '0px',
                    margin: '0px',
                    display: 'block',
                    width: 'initial',
                    height: 'initial',
                    background: 'none',
                    opacity: 1,
                    maxWidth: '100%',
                  }}
                />
              </span>
              <img alt="logo" src="./images(29).png" 
              style={{
                position: 'absolute', 
                inset: '0px',
                boxSizing: 'border-box',
                padding: '0px', 
                border: 'none',
                margin: 'auto',
                display: 'block',
                width: '0px',
                height: '0px', 
                minWidth: '100%', 
                maxWidth: '100%', 
                minHeight: '100%', 
                maxHeight: '100%'
              }}
            />
            </span>
            <span
              style={{ 
                boxSizing: 'border-box',
                display: 'inline-block',
                overflow: 'hidden',
                width: 'initial',
                height: 'initial',
                background: 'none',
                opacity: 1,
                border: '0px',
                margin: '0px',
                padding: '0px',
                position: 'relative',
                maxWidth: '100%'
               }}
            >
              <span
                style={{
                  boxSizing: 'border-box',
                  display: 'block',
                  width: 'initial',
                  height: 'initial',
                  background: 'none',
                  opacity: 1,
                  border: '0px',
                  margin: '0px',
                  padding: '0px',
                  maxWidth: '100%'
                }}
              >
                <img 
                  alt
                  aria-hidden="true"
                  src='data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2777%27%20height=%2770%27/%3e'
                  style={{
                    padding: '0px',
                    border: '0px',
                    margin: '0px',
                    display: 'block',
                    width: 'initial',
                    height: 'initial',
                    background: 'none',
                    opacity: 1,
                    maxWidth: '100%',
                  }}
                />
              </span>
              <img alt="logo" src="./images(29).png" 
              style={{
                position: 'absolute', 
                inset: '0px',
                boxSizing: 'border-box',
                padding: '0px', 
                border: 'none',
                margin: 'auto',
                display: 'block',
                width: '0px',
                height: '0px', 
                minWidth: '100%', 
                maxWidth: '100%', 
                minHeight: '100%', 
                maxHeight: '100%'
              }}
            />
            </span>
            <span
              style={{ 
                boxSizing: 'border-box',
                display: 'inline-block',
                overflow: 'hidden',
                width: 'initial',
                height: 'initial',
                background: 'none',
                opacity: 1,
                border: '0px',
                margin: '0px',
                padding: '0px',
                position: 'relative',
                maxWidth: '100%'
               }}
            >
              <span
                style={{
                  boxSizing: 'border-box',
                  display: 'block',
                  width: 'initial',
                  height: 'initial',
                  background: 'none',
                  opacity: 1,
                  border: '0px',
                  margin: '0px',
                  padding: '0px',
                  maxWidth: '100%'
                }}
              >
                <img 
                  alt
                  aria-hidden="true"
                  src='data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2777%27%20height=%2770%27/%3e'
                  style={{
                    padding: '0px',
                    border: '0px',
                    margin: '0px',
                    display: 'block',
                    width: 'initial',
                    height: 'initial',
                    background: 'none',
                    opacity: 1,
                    maxWidth: '100%',
                  }}
                />
              </span>
              <img alt="logo" src="./images(29).png" 
              style={{
                position: 'absolute', 
                inset: '0px',
                boxSizing: 'border-box',
                padding: '0px', 
                border: 'none',
                margin: 'auto',
                display: 'block',
                width: '0px',
                height: '0px', 
                minWidth: '100%', 
                maxWidth: '100%', 
                minHeight: '100%', 
                maxHeight: '100%'
              }}
            />
            </span>
            <span
              style={{ 
                boxSizing: 'border-box',
                display: 'inline-block',
                overflow: 'hidden',
                width: 'initial',
                height: 'initial',
                background: 'none',
                opacity: 1,
                border: '0px',
                margin: '0px',
                padding: '0px',
                position: 'relative',
                maxWidth: '100%'
               }}
            >
              <span
                style={{
                  boxSizing: 'border-box',
                  display: 'block',
                  width: 'initial',
                  height: 'initial',
                  background: 'none',
                  opacity: 1,
                  border: '0px',
                  margin: '0px',
                  padding: '0px',
                  maxWidth: '100%'
                }}
              >
                <img 
                  alt
                  aria-hidden="true"
                  src='data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2777%27%20height=%2770%27/%3e'
                  style={{
                    padding: '0px',
                    border: '0px',
                    margin: '0px',
                    display: 'block',
                    width: 'initial',
                    height: 'initial',
                    background: 'none',
                    opacity: 1,
                    maxWidth: '100%',
                  }}
                />
              </span>
              <img alt="logo" src="./images(29).png" 
              style={{
                position: 'absolute', 
                inset: '0px',
                boxSizing: 'border-box',
                padding: '0px', 
                border: 'none',
                margin: 'auto',
                display: 'block',
                width: '0px',
                height: '0px', 
                minWidth: '100%', 
                maxWidth: '100%', 
                minHeight: '100%', 
                maxHeight: '100%'
              }}
            />
            </span>
            <span
              style={{ 
                boxSizing: 'border-box',
                display: 'inline-block',
                overflow: 'hidden',
                width: 'initial',
                height: 'initial',
                background: 'none',
                opacity: 1,
                border: '0px',
                margin: '0px',
                padding: '0px',
                position: 'relative',
                maxWidth: '100%'
               }}
            >
              <span
                style={{
                  boxSizing: 'border-box',
                  display: 'block',
                  width: 'initial',
                  height: 'initial',
                  background: 'none',
                  opacity: 1,
                  border: '0px',
                  margin: '0px',
                  padding: '0px',
                  maxWidth: '100%'
                }}
              >
                <img 
                  alt
                  aria-hidden="true"
                  src='data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2777%27%20height=%2770%27/%3e'
                  style={{
                    padding: '0px',
                    border: '0px',
                    margin: '0px',
                    display: 'block',
                    width: 'initial',
                    height: 'initial',
                    background: 'none',
                    opacity: 1,
                    maxWidth: '100%',
                  }}
                />
              </span>
              <img alt="logo" src="./images(29).png" 
              style={{
                position: 'absolute', 
                inset: '0px',
                boxSizing: 'border-box',
                padding: '0px', 
                border: 'none',
                margin: 'auto',
                display: 'block',
                width: '0px',
                height: '0px', 
                minWidth: '100%', 
                maxWidth: '100%', 
                minHeight: '100%', 
                maxHeight: '100%'
              }}
            />
            </span>
          </div>
          }
        </div>
      </div>
       {/* <div style={{minHeight: '50px'}}>
      <div className="styles_filterContainer__mUPQ6" style={{position: 'absolute', right: '20px'}}>
        <div className="styles_ListHeaderContainer__dy9Lq">
          {/* <div className="styles_flex__MxPTO">
            <img src="./alarmV2.png" alt="logo" style={{width: '20px', height: '20px'}}/>
            <span className="styles_font18_500__c6Jn4">{t('Ending_in')}: </span>
          </div> 
           <div className="styles_wrapper__Esa60">
            <div className="styles_item__jnNtb">
              <p className="styles_number__v738w">{timerComponents.length ? timerComponents : <span>{t('Time_up')}!</span>}</p>
            </div>
          </div>
        </div> 
         <a href={`${documentcate}`} download="filename.pdf" style={{color: 'red'}}>{t('catelog')}</a>

        {!isMobile && <div className="styles_SearchSort__idHUq">
          {/* <div className="styles_DisFlex__3n4ez">
            <div></div>
            <div className="styles_DisFlex2__R2wG2  ">
              <div className="styles_main__f3mw5 ">
                <div className="styles_hasSearch__5k8YO ">
                  <input id="Search" type="text" className="styles_formControl__Kj_hE" placeholder="Search ..." value=""/>
                  <div className="styles_imgContain__e5H3K">
                    <svg xmlns="http://www.w3.org/2000/svg" className="styles_formControlFeedback__grud3" viewBox="0 0 101 101" id="search">
  <path d="M63.3 59.9c3.8-4.6 6.2-10.5 6.2-17 0-14.6-11.9-26.5-26.5-26.5S16.5 28.3 16.5 42.9 28.4 69.4 43 69.4c6.4 0 12.4-2.3 17-6.2l20.6 20.6c.5.5 1.1.7 1.7.7.6 0 1.2-.2 1.7-.7.9-.9.9-2.5 0-3.4L63.3 59.9zm-20.4 4.7c-12 0-21.7-9.7-21.7-21.7s9.7-21.7 21.7-21.7 21.7 9.7 21.7 21.7-9.7 21.7-21.7 21.7z" fill="gold"></path>
</svg>

                   </div>
                </div>
              </div>
              <button type="button" className="styles_btnFilter__loYk_">
                <img src="./Filter.svg" width="18" height="18" alt="filter icon"/>
              </button>
              <div>
                <button type="button" className="styles_btnSort__oU457">
                  <img src="./arrows.svg" alt="sort" width="16" height="16" loading="lazy" rel="preload"/>
                  <span className="styles_HiddenSm__SEE0f">Sort</span>
                </button>
              </div>
              <div className="styles_toggleBtns__a_eC4">
                <div> 
                  <button type="button" className="styles_btnSort2__TQ_3_" aria-label="Grid View">
                    {/* <img src="./grid-view-black.svg" alt="Grid view icon" title="Switch to grid view"/> <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512" width="200" height="200">
    <rect x="108" y="108" width="126" height="126" rx="20" ry="20" fill="white" stroke="gold" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/>
    <rect x="288" y="108" width="126" height="126" rx="20" ry="20" fill="white" stroke="gold" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/>
    <rect x="108" y="288" width="126" height="126" rx="20" ry="20" fill="white" stroke="gold" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/>
    <rect x="288" y="288" width="126" height="126" rx="20" ry="20" fill="white" stroke="gold" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/>
</svg>

                  </button>
                </div>
                <div>
                  <button type="button" className="styles_btnSortFilter__Io_Si" aria-label="List View">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="List">
  <g fill="#FFD700" class="color000000 svgShape">
    <g fill="#FFD700" class="color000000 svgShape">
      <circle cx="4" cy="7" r="1" fill="#FFD700" class="color000000 svgShape"></circle>
      <circle cx="4" cy="12" r="1" fill="#FFD700" class="color000000 svgShape"></circle>
      <circle cx="4" cy="17" r="1" fill="#FFD700" class="color000000 svgShape"></circle>
      <rect width="14" height="2" x="7" y="11" rx=".94" ry=".94" fill="#FFD700" class="color000000 svgShape"></rect>
      <rect width="14" height="2" x="7" y="16" rx=".94" ry=".94" fill="#FFD700" class="color000000 svgShape"></rect>
      <rect width="14" height="2" x="7" y="6" rx=".94" ry=".94" fill="#FFD700" class="color000000 svgShape"></rect>
    </g>
  </g>
</svg>
{/* <img src="./list-view-white.svg" alt="List view icon" title="Switch to list view" width="24" height="24" loading="lazy" rel="preload"/> 
                  </button>
                </div>
              </div>
            </div>
          </div> 
        </div>
        
        }
      </div>
      </div> */}
      
      <UsaSaleAuctionCard itemsdata={items}/>
    </div>
  )
}

export default UsaSalCard