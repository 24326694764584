import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { Circles } from 'react-loader-spinner';
import './AuctionCard.css';
import './saledetails.css';

const UsaSaleAuctionCard = () => {
  const [manufacturers, setManufacturers] = useState([]); // Holds manufacturer data
  const [models, setModels] = useState([]); // Holds model data
  const [selectedManufacturer, setSelectedManufacturer] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [cartype, setCartype] = useState('');

  // Fetch manufacturers on component mount
  const fetchManufacturers = async () => {
    setIsLoading(true);
    const options = {
      method: 'GET',
      url: 'https://api-for-copart-and-iaai.p.rapidapi.com/manufacturers',
      headers: {
        'x-rapidapi-key': '310dd2d995msh2b0513cd3246d2dp11a8e1jsn46b76362f943',
        'x-rapidapi-host': 'api-for-copart-and-iaai.p.rapidapi.com'
      }
    };

    try {
      const response = await axios.request(options);
      const manufacturerOptions = response.data.data.map(manufacturer => ({
        value: manufacturer.id,
        label: manufacturer.name
      }));
      setManufacturers(manufacturerOptions);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching manufacturers:', error);
      setIsLoading(false);
    }
  };

  // Fetch models based on the selected manufacturer
  const fetchModels = async (manufacturerId) => {
    if (!manufacturerId) return;

    {

const options = {
  method: 'GET',
  url: 'https://api-for-copart-and-iaai.p.rapidapi.com/cars',
  params: {
    minutes: '10',
    manufacturer_id:  manufacturerId,
    per_page: '50'
  },
  headers: {
    'x-rapidapi-key': '310dd2d995msh2b0513cd3246d2dp11a8e1jsn46b76362f943',
    'x-rapidapi-host': 'api-for-copart-and-iaai.p.rapidapi.com'
  }
};

try {
	const response = await axios.request(options);
	console.log(response.data);
  setItems(response.data.data);
} catch (error) {
	console.error(error);
}
    }

    setIsLoading(true);
    const options = {
      method: 'GET',
      url: `https://api-for-copart-and-iaai.p.rapidapi.com/models/${manufacturerId}`,
      headers: {
        'x-rapidapi-key': '310dd2d995msh2b0513cd3246d2dp11a8e1jsn46b76362f943',
        'x-rapidapi-host': 'api-for-copart-and-iaai.p.rapidapi.com'
      }
    };

    try {
      const response = await axios.request(options);
      const modelOptions = response.data.data.map(model => ({
        value: model.id,
        label: model.name
      }));
      setModels(modelOptions);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching models:', error);
      setIsLoading(false);
    }
  };

  const fetchModelsdata = async (modelid) => {
    if (!modelid) return;

const options = {
  method: 'GET',
  url: 'https://api-for-copart-and-iaai.p.rapidapi.com/cars',
  params: {
    minutes: '10',
    model_id: modelid,
    per_page: '50'
  },
  headers: {
    'x-rapidapi-key': '310dd2d995msh2b0513cd3246d2dp11a8e1jsn46b76362f943',
    'x-rapidapi-host': 'api-for-copart-and-iaai.p.rapidapi.com'
  }
};

try {
	const response = await axios.request(options);
  setItems(response.data.data);
	console.log(response.data);
} catch (error) {
	console.error(error);
}
  };
  
  useEffect(() => {
    fetchManufacturers();
  }, []);

  useEffect(() => {
    if (selectedModel) {
      fetchModelsdata(selectedModel.value);
    }
  }, [selectedModel]);

  useEffect(() => {
    if (selectedManufacturer) {
      fetchModels(selectedManufacturer.value);
    }
  }, [selectedManufacturer]);

  const handleManufacturerChange = (selectedOption) => {
    setSelectedManufacturer(selectedOption);
    setSelectedModel(null); // Reset selected model when manufacturer changes
  };

  const handleModelChange = (selectedOption) => {
    setSelectedModel(selectedOption);
  };

  const fetchItems = async (page) => {
    setIsLoading(true);
    const options = {
      method: 'GET',
      url: 'https://api-for-copart-and-iaai.p.rapidapi.com/cars',
      params: {
        minutes: '10',
        per_page: '50',
        page: page
      },
      headers: {
        'x-rapidapi-key': '310dd2d995msh2b0513cd3246d2dp11a8e1jsn46b76362f943',
        'x-rapidapi-host': 'api-for-copart-and-iaai.p.rapidapi.com'
      }
    };

    try {
      const response = await axios.request(options);
      setItems(response.data.data); // Assuming response.data.data is an array of items
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching items:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchItems(currentPage);
  }, [currentPage]);

  return (
    <div>
      {isLoading && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <Circles height="80" width="80" color="#FFC107" ariaLabel="circles-loading" visible={true} />
        </div>
      )}

      {!isLoading && (
        <>
          

          <div className='pagination'>
          <div>
            <Select
              options={manufacturers}
              onChange={handleManufacturerChange}
              value={selectedManufacturer}
              placeholder="Select Manufacturer"
              style={{minWidth: '150px', height: '40px'}} className='mx-3'
            />
          </div>

          {selectedManufacturer && (
            <div>
              <Select
                options={models}
                onChange={handleModelChange}
                value={selectedModel}
                placeholder="Select Model"
                style={{minWidth: '150px' , height: '40px'}}
                className='mx-3'
              />
            </div>
          )}
            <button className='btn btn-warning' onClick={() => setCartype('Salvage')}>Salvage</button>
            <button className='btn btn-warning' onClick={() => setCartype('Run & Drive')}>Run & Drive</button>
          </div>

          <div className='gallery-container'>
            {items.map((item) => {
              
              const images = item?.lots[0]?.images;
              const titleName = item?.lots[0]?.condition?.name;
              const imageUrl = images?.big?.[0] || images?.normal?.[0] || images?.small?.[0] || 'https://via.placeholder.com/300x300';

              const shouldDisplayCard =
                (cartype === '' || // Show all items
                  (cartype === 'Run & Drive' && titleName && titleName.includes("run_and_drives")) || // Show non-salvage items
                  (cartype === 'Salvage' && titleName && !titleName.includes("run_and_drives"))); // Show salvage items

              if (!shouldDisplayCard) return null;

              return (
                <div key={item.id} className='card-container'>
                  <a href={`/Usasale/motors/${item.vin}`}>
                  <div className='card-image-container'>
                    <img
                      src={imageUrl}
                      alt={item.title}
                      title={item.title}
                      className='card-image'
                      loading='lazy'
                      style={{ height: '400px', width: '500px' }}
                    />
                  </div>
                  <div className='card-details'>
                    <h3 className='card-title'>{item.title}</h3>
                    <div className='detail-section'>
                      <div className='detail-label'>
                        {`Mileage: ${item.lots[0].odometer ? item.lots[0].odometer.km : ''} Km`}
                      </div>
                    </div>
                    <div className='detail-section'>
                      <div className='detail-label'>
                        {`Year: ${item.year}`}
                      </div>
                    </div>
                    <div className='detail-section'>
                      <div className='detail-label'>
                        {`Brand: ${item.manufacturer ? item.manufacturer.name : ''}`}
                      </div>
                    </div>
                    <div className='detail-section'>
                      <div className='detail-label'>
                        {`Model: ${item.model ? item.model.name : ''}`}
                      </div>
                    </div>
                    <div className='detail-section'>
                      <div className='detail-label price'>
                        {`Price: ${item?.lots[0]?.bid ?? item?.lots[0]?.pre_accident_price ?? "N/A"}`}
                      </div>
                    </div>
                    
                      <div className='btn btn-warning w-100 m-1'>
                        {`Details`}
                    </div>
                  </div>
                  </a>
                </div>
              );
            })}
          </div>

          <div className="pagination">
            <button className='btn btn-warning' onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
            <button className='btn btn-warning' onClick={() => setCurrentPage(currentPage + 1)}>Next</button>
          </div>
        </>
      )}
    </div>
  );
};

export default UsaSaleAuctionCard;
