import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Circles } from 'react-loader-spinner';
import Navbar from "../../Navbar";
import SlideShow from '../slideshow/SlideShow';
import { isMobile } from 'react-device-detect';
import MapComponent from '../../AddressPicker';

// const cards = [
//     {
//         id: 1,
    
//         title: 'UAE Cars AUCTION',
//         subTitle: 'Emirates car auction',
//         image: 'https://images.unsplash.com/photo-1606220838315-056192d5e927?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
//     },
//     {
//         id: 2,
//         title: 'Salvage Cars AUCTION',
//         subTitle: 'Salvage car auction',
//         image: 'https://images.unsplash.com/photo-1606220838315-056192d5e927?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
//     },
//     {
//         id: 3,
//         title: 'UAE Plates AUCTION',
//         subTitle: 'Emirates paintings auction',
//         image: 'https://images.unsplash.com/photo-1614332287897-cdc485fa562d?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8Y29taW5nJTIwc29vbnxlbnwwfHwwfHx8MA%3D%3D'
//     },
// ]

const Main = () => {

    const {t} = useTranslation();
    sessionStorage.setItem('previousPage', window.location.href);

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [count,setcount] = useState(0);
    const [countsal,setcountsal] = useState(0);
    const [countclassic,setcountclassic] = useState(0);
    const [countsale,setcountsale] = useState(0);
    const [numbercountabudabi, setnumbercountabudabi] = useState(0);

    useEffect(() => {
        const fetchDataForPosts = async () => {
          try {
            const response = await fetch(
              `https://api.drivenappinc.com/`
            );
            if (!response.ok) {
              throw new Error(`HTTP error: Status ${response.status}`);
            }
            let postsData = await response.json();
            setData(postsData);
            setIsLoading(false)
          } catch (err) {
            console.log(err.message)
            setIsLoading(false)
          }
        };
    
        fetchDataForPosts();
      }, [])

      useEffect(() => {
        const fetchDataForPosts = async () => {
          try {
            const response = await fetch(
              `https://api.drivenappinc.com/auction-items-c`
            );
            if (!response.ok) {
              throw new Error(`HTTP error: Status ${response.status}`);
            }
            let postsData = await response.json();
            setcount(postsData[0].count);
          } catch (err) {
            console.log(err.message);
          }

          try {
            const response = await fetch(
              `https://api.drivenappinc.com/abudabi_buynow_item`
            );
            if (!response.ok) {
              throw new Error(`HTTP error: Status ${response.status}`);
            }
            let postsData = await response.json();
            setnumbercountabudabi(postsData[0].count);
          } catch (err) {
            console.log(err.message);
          }


          try {
            const response = await fetch(
              `https://api.drivenappinc.com/auction-items-sal-c`
            );
            if (!response.ok) {
              throw new Error(`HTTP error: Status ${response.status}`);
            }
            let postsData = await response.json();
            setcountsal(postsData[0].count);
          } catch (err) {
            console.log(err.message);
          }

          try {
            const response = await fetch(
              `https://api.drivenappinc.com/auction-items-classic`
            );
            if (!response.ok) {
              throw new Error(`HTTP error: Status ${response.status}`);
            }
            let postsData = await response.json();
            setcountclassic(postsData[0].count);
          } catch (err) {
            console.log(err.message);
          }

          try {
            const response = await fetch(
              `https://api.drivenappinc.com/sale-items`
            );
            if (!response.ok) {
              throw new Error(`HTTP error: Status ${response.status}`);
            }
            let postsData = await response.json();
            setcountsale(postsData[0].count);
          } catch (err) {
            console.log(err.message);
          }
        };
      console.log(count,countsal,countclassic,countsale);
        // Fetch data immediately on component mount
        fetchDataForPosts();
      
        // Set interval to fetch data every 10 seconds
        const intervalId = setInterval(() => {
          fetchDataForPosts();
        }, 10000); // 10000 ms = 10 seconds
      
        // Cleanup interval on component unmount or if dependencies change
        return () => clearInterval(intervalId);
      }, []);
    return (
        <div>
        <Navbar />
        <div>
          {!isMobile &&
          <SlideShow />
          }
          
        </div>
        {isLoading ? (
              <div style=
                {{ 
                  display: 'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center', 
                  top: 0, 
                  bottom: 0, 
                  right: 0, 
                  left: 0, 
                  position: 'fixed' 
                }}>
                <Circles
                height="80"
                width="80"
                color="#FFC107"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                />
              </div>
            ) : (
              <div class="main-site-wrap" style={{width: '100%'}}>
              <div class="site-content-top">
              <div class="auction-cat" style={{justifyContent: 'left'}}>

              <div class="auction-block">
              <div class="auction-status">
              <a href="/motors">
              <img src="/car2.png" alt="" /></a>
              <span class="count">{count}</span> </div>
              <div class="auction-name">
              <span class="eng">Used Car Auction</span>
              <span class="arb">مزاد سيارات</span>
              </div>
              <div class="acution-time" data-time="2024-05-30 21:00:00" id="end_9">
              <div class="timer">
              <div class="days"></div>
              <div class="hours"></div>
              <div class="minutes"></div>
              <div class="seconds"></div>
              </div>
              </div>
              </div>


              <div class="auction-block">
              <div class="auction-status">
              <a href="/salvage">
              <img src="/car3.png" alt="" /></a>
              <span class="count">{countsal}</span>
              </div>
              <div class="auction-name">
              <span class="eng">Salvage Auction</span>
              <span class="arb">مزاد سكراب</span>
              </div>
              </div>

              <div class="auction-block">
              <div class="auction-status">
              <a href="/classic">
              <img src="/car1.png" alt="" /></a>
              <span class="count">{countclassic}</span>
              </div>
              <div class="auction-name">
              <span class="eng">Classic Auction</span>
              <span class="arb">مزاد الكلاسيك</span>
              </div>
              </div>

              <div class="auction-block">
              <div class="auction-status">
              <a href="/salemotors">
              <img src="/usesalecars.jpg" alt="" /></a>
              <span class="count">{countsale}</span> </div>
              <div class="auction-name">
              <span class="eng">Used Cars</span>
              <span class="arb">سيارات مستعملة</span>
              </div>
              <div class="acution-time" data-time="2024-05-30 21:00:00" id="end_9">
              <div class="timer">
              <div class="days"></div>
              <div class="hours"></div>
              <div class="minutes"></div>
              <div class="seconds"></div>
              </div>
              </div>
              </div>

              <div class="auction-block">
              <div class="auction-status">
              <a href="/Numbers?city=abudabi&auction=buynow">
              <img src="/numberabudabi.jpg" alt="" /></a>
              <span class="count">{numbercountabudabi}</span> </div>
              <div class="auction-name">
              <span class="eng">UAE Plates AUCTION</span>
              <span class="arb">مزاد لوحات الإمارات</span>
              </div>
              <div class="acution-time" data-time="2024-05-30 21:00:00" id="end_16">
              <div class="timer">
              <div class="days"></div>
              <div class="hours"></div>
              <div class="minutes"></div>
              <div class="seconds"></div>
              </div>
              </div>
              </div>
              {/*<div class="auction-block">
              <div class="auction-status">
              <a href="/commingsoon">
              <img src="https://onlineauction-live.s3.us-east-2.amazonaws.com/UI/category/mixAuction.png" alt="" /></a>
              <span class="live">مباشر</span>
              <span class="count">0</span> </div>
              <div class="auction-name">
              <span class="eng">Mix Auction</span>
              <span class="arb">المزاد المتنوع </span>
              </div>
              <div class="acution-time" data-time="2024-05-27 21:08:04" id="end_25">
              <div class="timer">
              <div class="days"></div>
              <div class="hours"></div>
              <div class="minutes"></div>
              <div class="seconds"></div>
              </div>
              </div>
              </div>
              <div class="auction-block">
              <div class="auction-status">
              <a href="/commingsoon">
              <img src="https://onlineauction-live.s3.us-east-2.amazonaws.com/UI/category/9.png" alt="" /></a>
              <span class="live">مباشر</span>
              <span class="count">0</span> </div>
              <div class="auction-name">
              <span class="eng">UAE Cars AUCTION</span>
              <span class="arb">مزاد سيارات الإمارات</span>
              </div>
              <div class="acution-time" data-time="2024-05-30 21:00:00" id="end_9">
              <div class="timer">
              <div class="days"></div>
              <div class="hours"></div>
              <div class="minutes"></div>
              <div class="seconds"></div>
              </div>
              </div>
              </div>
              <div class="auction-block">
              <div class="auction-status">
              <a href="/commingsoon">
              <img src="https://onlineauction-live.s3.us-east-2.amazonaws.com/UI/category/6.png" alt="" /></a>
              <span class="live">مباشر</span>
              <span class="count">0</span> </div>
              <div class="auction-name">
              <span class="eng">Property Auction</span>
              <span class="arb">مزاد العقارات</span>
              </div>
              <div class="acution-time" data-time="2024-05-30 21:00:00" id="end_6">
              <div class="timer">
              <div class="days"></div>
              <div class="hours"></div>
              <div class="minutes"></div>
              <div class="seconds"></div>
              </div>
              </div>
              </div> */}
              <div class="auction-block">
              <div class="auction-status">
              <a href="/usasalemotors">
              <img src="/usa_auction.jpg" alt="" /></a>
              {/* <span class="count">0</span> */}
              </div>
              <div class="auction-name">
              <span class="eng">USA Cars AUCTION</span>
              <span class="arb">مزاد السيارات الأمريكية</span>
              </div>
              </div>


              {/* <div class="auction-block">
              <div class="auction-status">
              <a href="">
              <img src="https://onlineauction-live.s3.us-east-2.amazonaws.com/UI/category/7.png" alt="" /></a>
              </div>
              <div class="auction-name">
              <span class="eng">Watches Auction</span>
              <span class="arb"> مزاد الساعات</span>
              </div>
              </div>
              <div class="auction-block">
              <div class="auction-status">
              <a href="/commingsoon">
              <img src="https://onlineauction-live.s3.us-east-2.amazonaws.com/UI/category/plates-buy-now.png" alt="" /></a>
              <span class="live">مباشر</span>
              <span class="count">0</span> </div>
              <div class="auction-name">
              <span class="eng">UAE PLATES (Direct Sale)</span>
              <span class="arb">بيع مباشر</span>
              </div>
              </div>
              <div class="auction-block">
              <div class="auction-status">
              <a href="/commingsoon">
              <img src="https://onlineauction-live.s3.us-east-2.amazonaws.com/UI/category/25.png" alt="" /></a>
              <span class="live">مباشر</span>
              <span class="count">0</span> </div>
              <div class="auction-name">
              <span class="eng">Mix Direct Sell</span>
              <span class="arb">بيع مباشر متنوع</span>
              </div>
              </div> */}
              </div></div></div>
            )}
        </div>
    );
}
export default Main;