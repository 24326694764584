async function fetchDataFromApi() {
  try {
    const response = await fetch('https://api.drivenappinc.com/brandsar');
    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data:', error.message);
    return null; // or handle the error as needed
  }
}

const brands = []

fetchDataFromApi()
  .then(data => {
    if (data) {
      brands.push(data)
      // Do something with the data
    } else {
      console.log('Failed to fetch data from API');
    }
  })
  .catch(error => {
    console.error('Error in fetching data:', error.message);
  });


export const userInputs = [
    {
      id: 1,
      label: "Name*",
      type: "text",
      placeholder: "Enter Name",
      name: 'name'
    },
    {
      id: 2,
      label: "Username*",
      type: "text",
      placeholder: "Enter Username",
      name: 'username'
    },
    {
      id: 3,
      label: "Email*",
      type: "mail",
      placeholder: "Enter Email",
      name: 'email'
    },
    {
      id: 4,
      label: "Phone*",
      type: "text",
      placeholder: "Enter Phone",
      name: 'phone'
    },
    {
      id: 5,
      label: "Country*",
      type: "text",
      placeholder: "Enter Country",
      name: 'country'
    },
    {
      id: 6,
      label: "City*",
      type: "text",
      placeholder: "Enter City",
      name: 'city'
    },
    {
      id: 7,
      label: "State*",
      type: "text",
      placeholder: "Enter State",
      name: 'state'
    },
    {
      id: 8,
      label: "ZIP Code*",
      type: "text",
      placeholder: "Enter ZIP Code",
      name: 'zip_code'
    },
    {
      id: 9,
      label: "Address*",
      type: "text",
      placeholder: "Enter Address",
      name: 'address'
    },
    {
      id: 10,
      label: "Password*",
      placeholder: 'Enter Password',
      type: "password",
    }
  ];
  
  export const productInputs = [
    {
      id: 1,
      label: "Title",
      type: "text",
      placeholder: "Apple Macbook Pro",
    },
    {
      id: 2,
      label: "Description",
      type: "text",
      placeholder: "Description",
    },
    {
      id: 3,
      label: "Category",
      type: "text",
      placeholder: "Computers",
    },
    {
      id: 4,
      label: "Price",
      type: "text",
      placeholder: "100",
    },
    {
      id: 5,
      label: "Stock",
      type: "text",
      placeholder: "in stock",
    },
  ];
  

  export const carInputs = [
    {
      id: 1,
      label: "Price",
      type: "number",
      placeholder: "Price",
      name: "price"
    },
    {
      id: 2,
      label: "Incremental Amount",
      type: "number",
      placeholder: "Incremental Amount",
      name: "previous_price"
    },
    {
      id: 3,
      label: "Speed",
      type: "text",
      placeholder: "Speed",
      name: "speed"
    },
    {
      id: 4,
      label: "Year",
      type: "text",
      placeholder: "Year",
      name: "year"
    },
    {
      id: 5,
      label: "Mileage",
      type: "text",
      placeholder: "Mileage",
      name: "mileage"
    },
    {
      id: 6,
      label: "Featured",
      type: "text",
      placeholder: "Featured",
      name: "featured"
    },
    {
      id: 7,
      label: "Specification",
      type: "text",
      placeholder: "Specification",
      name: 'specification'
    },
    {
      id: 8,
      label: "Status",
      type: "text",
      placeholder: "Status",
      name: "status"
    },
    {
      id: 11,
      label: "Expiry Date",
      type: "datetime-local",
      placeholder: "Expiry Date",
      name: "expiry_date"
    },
    {
      id: 12,
      label: "Vin Number",
      type: "text",
      placeholder: "Vin Number",
      name: "Vin_Number"
    },
    {
      id: 13,
      label: "Car Key",
      type: "text",
      placeholder: "Key",
      name: "Key"
    },
  ]


  export const salcarInputs = [
    {
      id: 1,
      label: "Price",
      type: "number",
      placeholder: "Price",
      name: "price"
    },
    {
      id: 3,
      label: "Speed",
      type: "text",
      placeholder: "Speed",
      name: "speed"
    },
    {
      id: 4,
      label: "Year",
      type: "text",
      placeholder: "Year",
      name: "year"
    },
    // {
    //   id: 7,
    //   label: "Specification",
    //   type: "text",
    //   placeholder: "Specification",
    //   name: 'specification'
    // },
    {
      id: 12,
      label: "Vin Number",
      type: "text",
      placeholder: "Vin Number",
      name: "Vin_Number"
    }
  ]

  export const bidInputs = [
    {
      id: 1,
      label: "Bid Amount",
      type: "number",
      placeholder: "Bid Amount",
      name: "bid_amount"
    },
    {
      id: 1,
      label: "",
      type: "number",
      placeholder: "Bid Amount",
      name: "bid_amount"
    },
    {
      id: 1,
      label: "Bid Amount",
      type: "number",
      placeholder: "Bid Amount",
      name: "bid_amount"
    },
  ]

  export const  buyRequestInputs = [
    {
      id: 1,
      label: "Item Type",
      type: "text",
      placeholder: "Item Type",
      name: "ItemType"
    },
    {
      id: 2,
      label: "Requested Price",
      type: "number",
      placeholder: "Requested Price",
      name: "RequestedPrice"
    },
    {
      id: 3,
      label: "Status",
      type: "text",
      placeholder: "Status",
      name: "Status"
    },
    {
      id: 4,
      label: "Request Time",
      type: "datetime-local",
      placeholder: "Request Time",
      name: "RequestTime"
    },
  ]

  export const  Numbers = [
    {
      id: 1,
      label: "Number",
      type: "text",
      placeholder: "Number",
      name: "number"
    },
    {
      id: 2,
      label: "Amount",
      type: "text",
      placeholder: "Amount",
      name: "amount"
    },
    {
      id: 4,
      label: "Increment",
      type: "text",
      placeholder: "Increment",
      name: "increment"
    },
    {
      id: 5,
      label: "Expiry date",
      type: "datetime-local",
      placeholder: "Expiry date",
      name: "expiry_date"
    }
  ]



  export const  catelogInputs = [
    {
      id: 1,
      label: "Catelog",
      type: "File",
      placeholder: "Catelog",
      name: "Catelog"
    }
  ]

  export const transmissionTypesar = [
    {
      name: 'يدوي'
    },
    {
      name: 'أوتوماتيك'
    },
    {
      name: 'نصف أوتوماتيك'
    },
    {
      name: 'تربترونيك'
    }
  ];
  

  export const transmissionTypes = [
    {
      name:  'Manual'
    },
    {
      name:  'Automatic'
    },
    {
      name:  'Semi-Auto'
    },
    {
      name:  'Triptronic'
    }
  ]


  export const exteriorColorTypesar = [
    {
      name: 'أسود'
    },
    {
      name: 'أزرق'
    },
    {
      name: 'بني'
    },
    {
      name: 'برغندي'
    },
    {
      name: 'ذهبي'
    },
    {
      name: 'رمادي'
    },
    {
      name: 'برتقالي'
    },
    {
      name: 'أخضر'
    },
    {
      name: 'بنفسجي'
    },
    {
      name: 'أحمر'
    },
    {
      name: 'فضي'
    },
    {
      name: 'بيج'
    },
    {
      name: 'بيج فاتح'
    },
    {
      name: 'تيل'
    },
    {
      name: 'أبيض'
    },
    {
      name: 'أصفر'
    },
  ];

  
  export const exteriorColorTypes = [
    {
      name:  'Black'
    },
    {
      name:  'Blue'
    },
    {
      name:  'Brown'
    },
    {
      name:  'Burgundy'
    },
    {
      name:  'Gold'
    },
    {
      name:  'Grey'
    },
    {
      name:  'Orange'
    },
    {
      name:  'Green'
    },
    {
      name:  'Purple'
    },
    {
      name:  'Red'
    },
    {
      name:  'Silver'
    },
    {
      name:  'Beige'
    },
    {
      name:  'Tan'
    },
    {
      name:  'Teal'
    },
    {
      name:  'White'
    },
    {
      name:  'Yellow'
    },
  ]

  export const emirateTypesar = [
    {
      name: 'أبو ظبي'
    },
    {
      name: 'الشارقة'
    },
    {
      name: 'أم القيوين'
    },
    {
      name: 'دبي'
    },
    {
      name: 'عجمان'
    },
    {
      name: 'الفجيرة'
    },
    {
      name: 'رأس الخيمة'
    },
  ];
  

  export const emirateTypes = [
    {
      name: 'Abu Dhabi'
    },
    {
      name: 'Sharjah'
    },
    {
      name: 'Umm Al Quwah'
    },
    {
      name: 'Dubai'
    },
    {
      name: 'Ajman'
    },
    {
      name: 'Fujairah'
    },
    {
      name: 'Ras Al Khaimah'
    },
  ]

  export const warrantyTypes = [
    {
      name: 'Under Warranty'
    },
    {
      name: 'No Warranty'
    },
    {
      name: "Desn't Apply"
    },
  ]

  export const warrantyTypesar = [
    {
      name: 'تحت الضمان'
    },
    {
      name: 'بدون ضمان'
    },
    {
      name: 'لا ينطبق'
    },
  ];
  

  export const specsTypesar = [
    {
      name: 'مواصفات أمريكية'
    },
    {
      name: 'مواصفات أوروبية'
    },
    {
      name: 'مواصفات خليجية'
    },
    {
      name: 'مواصفات يابانية'
    },
    {
      name: 'مواصفات أخرى'
    },
  ];

  export const specsTypes = [
    {
      name: 'American Specs'
    },
    {
      name: 'European Specs'
    },
    {
      name: 'GCC Specs'
    },
    {
      name: 'Japanese Specs'
    },
    {
      name: 'Other Specs'
    },
  ]

  export const trimTypes = [
    {
      name: 'S'
    }, {
      name: 'SL'
    }, {
      name: 'SL Sport'
    }, {
      name: 'SL Turbo'
    }, {
      name: 'Standard'
    }, {
      name: 'SV'
    }, {
      name: 'SV + PROPOLOT'
    },
  ]

  export const trimTypesar = [
    {
      name: 'إس'
    },
    {
      name: 'إس إل'
    },
    {
      name: 'إس إل سبورت'
    },
    {
      name: 'إس إل توربو'
    },
    {
      name: 'قياسي'
    },
    {
      name: 'إس في'
    },
    {
      name: 'إس في + بروبولوت'
    },
  ];
  
  export const mileageTypes = [
    {
      name: 'Less than 10,000 km'
    },
    {
      name: '10,000-20,000 km'
    },
    {
      name: '20,000-30,000 km'
    },
    {
      name: '30,000-40,000 km'
    },
    {
      name: '40,000-50,000 km'
    },
    {
      name: '50,000-60,000 km'
    },
    {
      name: '60,000-70,000 km'
    },
    {
      name: '70,000-80,000 km'
    },
    {
      name: '80,000-90,000 km'
    },
    {
      name: '90,000-100,000 km'
    },
    {
      name: '100,000+ km'
    },
    {
      name: 'Unknown'
    },
  ];
  
  export const powerTypes = [
    {
      name: 'Less than 150 HP'
    },
    {
      name: '150-200'
    },
    {
      name: '200-250'
    },
    {
      name: '250-300'
    },
    {
      name: '300-400'
    },
    {
      name: '400-500'
    },
    {
      name: '500-600'
    },
    {
      name: '600-700'
    },
    {
      name: '700-800'
    },
    {
      name: '800-900'
    },
    {
      name: '900+ HP'
    },
    {
      name: 'Unknown'
    },
  ]

  export const cylinderTypes = [
    {
      name: '2'
    },
    {
      name: '3'
    },
    {
      name: '4'
    },
    {
      name: '5'
    },
    {
      name: '6'
    },
    {
      name: '8'
    },
    {
      name: '12'
    },
    {
      name: 'Unknown'
    },
  ]
export const conditionTypesuser = [
  {
    name: 'Used'
  }
]
  export const conditionTypes = [
    {
      name: 'Used'
    },,{
      name: 'Salvage'
    },{
      name: 'Classic'
    },
    {
      name: 'Certified Pre-Owned (CPO)'
    }
  ]

  export const categoryARTypes = [
    {
      name: 'قابل للتحويل'
    },
    {
      name: 'زوج'
    },
    {
      name: 'عبور'
    },
    {
      name: 'هاتشباك'
    },
    {
      name: 'شاحنة صغيرة'
    },
    {
      name: 'سيدان'
    },
    {
      name: 'رياضات'
    },
    {
      name: 'سيارات الدفع الرباعي'
    }
  ]

  export const transmissionARTypes = [
    {
      name: 'يدوي'
    },
    {
      name: 'تلقائي'
    },
    {
      name: 'شبه أوتوماتيكي'
    },
    {
      name: 'تيبترونيك'
    },
  ]

  export const fuelARTypes = [
    {
      name: 'بنزين (بنزين)'
    },
    {
      name: 'ديزل'
    },
    {
      name: 'هجين'
    },
    {
      name: 'كهربائي'
    },
    {
      name: 'وقود حيوي'
    },
  ]

  export const conditionARTypes = [
    
    {
      name: 'تصديق قبل الملكية'
    },
    {
      name: 'استخلاص'
    },
    {
      name: 'مستخدم'
    },
    {
      name: 'كلاسيكي'
    }
  ]


  
  export const conditionARTypesuser = [
    
    {
      name: 'مستخدم'
    }
  ]


  export const location_types = [
    {
      name: 'abudabi'
    }, {
      name: 'sharjah'
    }, {
      name: 'Ajman'
    }, {
      name: 'Rak'
    }, {
      name: 'UAQ'
    }, {
      name: 'Fujairah'
    },{
      name: 'Dubai'
    }
  ]

  export const statusnumber = [
    {
      name: 'Active'
    }, {
      name: 'Non Active'
    }
  ]

  export const numberTypes = [
    {
      name: 'Auction'
    }, {
      name: 'buynow'
    }
  ]