
import React, { useState, useEffect } from 'react';
import { Circles } from 'react-loader-spinner';
import { useLocation, useParams } from 'react-router-dom';
import Navbar from '../../Navbar';
import "./auctiondetails.css";
import { isMobile } from 'react-device-detect';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; 
import { useTranslation } from 'react-i18next';
import MapComponentshower from './mapcomponent';


  

const UsaSaleDetails = () => {

  
  const { id } = useParams();
  const carId = id;
  const [cars, setCars] = useState('');
  const [carContentAr, setCarContentAr] = useState(null);
  const [carContentEn, setCarContentEn] = useState(null);
  const [carContent, setCarContent] = useState({});
  const [carImages, setCarImages] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isPopupVisible1, setIsPopupVisible1] = useState(false);
  const [feature_image, setFeatureImage] = useState('');
  const [documents, setDocument] = useState('');
  const [carprice, setcarprice] = useState();
  let id1 = '';
  const [isInWishlist, setIsInWishlist] = useState(false);
  const userData = sessionStorage.getItem('user');
  const defaultUser = {};
  const [user, setUser] = useState(userData? JSON.parse(userData) : defaultUser);
  const { i18n } = useTranslation();
  const { t } = useTranslation();
    const [carowner, setcarowner] = useState('');
  const togglePopup1 = () => {
    setIsPopupVisible1(!isPopupVisible1);
  };

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };


  const fetchDataForPosts = async (carId, setCars, setIsLoading, setCarImages) => {
    setIsLoading(true);
  
    const options = {
      method: 'GET',
      url: `https://api-for-copart-and-iaai.p.rapidapi.com/search-vin/${carId}`,
      headers: {
        'x-rapidapi-key': '310dd2d995msh2b0513cd3246d2dp11a8e1jsn46b76362f943',
        'x-rapidapi-host': 'api-for-copart-and-iaai.p.rapidapi.com'
      }
    };
  
    try {
      const response = await axios.request(options);
      setCars(response.data);
      let carImages = [];
      
      const images = response?.data?.data?.lots[0]?.images;

      if (images) {
        if (images.big?.length) {
          carImages = images.big;
        } else if (images.normal?.length) {
          carImages = images.normal;
        } else if (images.small?.length) {
          carImages = images.small;
        }

        // If carImages is a single string with multiple URLs separated by commas, split it into an array
        if (carImages.length === 1 && typeof carImages[0] === 'string' && carImages[0].includes(',')) {
          carImages = carImages[0].split(',');
        }
      }
      
      setCarImages(carImages);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
    

  const addwishlist = (id) => {
    if (isInWishlist) {
      handleRemoveFromWishlist(id);
    } else {
      handleAddWishlist(id);
    }
  };

  const handleAddWishlist = async (auctionId) => {
    const userId = user.data && user.data[0]?.id;
    if (!userId) {
      alert('Please Login first!');
      return;
    }
    if (!auctionId) {
      console.error('Auction ID not found');
      return;
    }

    try {
      const response = await axios.post('https://api.drivenappinc.com/wishlist', { auctionId, userId });
      console.log('Added to wishlist successfully', response.data);
      setIsInWishlist(true);
      checkWishlistStatus();
    } catch (error) {
      console.error('Error adding to wishlist:', error);
    }
  };
  

  const handleRemoveFromWishlist = async (auctionId) => {
    const userId = user.data && user.data[0]?.id;

    try {
      const response = await axios.delete(`https://api.drivenappinc.com/wishlist/${userId}/${auctionId}`);
      setIsInWishlist(false);
      checkWishlistStatus();
    } catch (error) {
      console.error('Error removing from wishlist:', error);
    }
  };

  function ImageSlider() {
    const [currentIndex, setCurrentIndex] = useState(0);

    const goToPrevious = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? carImages.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    };

    const goToNext = () => {
        const isLastSlide = currentIndex === carImages.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            goToNext();
        }, 3000);

        return () => clearInterval(interval);
    }, [currentIndex]);

    if (carImages.length === 0) {
        return <div>No images available</div>;
    }

    return (
        <div className="slider2">
            <button onClick={goToPrevious} className="left-arrow">❮</button>
            <div className="slide">
                <img src={carImages[currentIndex]} alt={`Slide ${currentIndex}`} key={`Slide ${currentIndex}`} />
            </div>
            <button onClick={goToNext} className="right-arrow">❯</button>
        </div>
    );
}
  const checkWishlistStatus = async () => {
    const userId = user.data && user.data[0]?.id;
    const auctionId = carId;

    try {
      const response = await axios.get(`https://api.drivenappinc.com/wishlist/${userId}/${auctionId}`);
      const isInWishlist = response.data.isInWishlist;
      setIsInWishlist(isInWishlist);
    } catch (error) {
      console.error('Error checking wishlist status:', error);
    }
  };

  useEffect(() => {
    if (carId) {
      fetchDataForPosts(carId, setCars, setIsLoading, setCarImages);
    }
  }, [carId]);


  if (isLoading) {
    return <div>Loading...</div>;
  }








  const description = carContent[0]?.description || carContent.description;
  const terms = carContent[0]?.terms || carContent.terms;

  return (
    <div>

      <Navbar />
     
      {isLoading && carprice !== '' ?
        (
          <div style=
            {{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              position: 'fixed'
            }}>
            <Circles
              height="80"
              width="80"
              color="#FFC107"
              ariaLabel="circles-loading"
              wrapperclassName=""
              visible={true}
            />
          </div>
          
        ) : (cars &&
          <div class="auction-details">
            
      {console.log(cars)}
            {isMobile && 
            <div class="floating_contact">
              {console.log(cars,"ASDSD")}
            <div class="flex flex-col md:flex-row justify-center">
                <div class="flex bg-transaparent item-ceter rounded-md text-black">
                    <div><a target="_blank"
                        href={'https://wa.me/+971545127066'}><img src="/contact/1.png" alt="favorite"  width="46" height="46" loading="lazy" rel="preload" aria-label="Add to Watchlist" className=""/></a>
                    </div>
                </div>
                <div class="flex bg-transaparent item-ceter rounded-md text-black ">
                    <div ><a class="p-1" href={`mailto:${'help@driven.llc'}`}> <svg height="50px" width="50px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 64 64" xmlSpace="preserve">
    <g>
      <path fill="#D1A552" d="M32,0C14.328,0,0,14.328,0,32s14.328,32,32,32s32-14.328,32-32S49.672,0,32,0z M46.32,16l-14.4,14.4 L17.52,16H46.32z M50.4,46.32H13.6V21.28L31.92,39.6c0.56,0.56,1.44,0.56,2,0L50.4,23.28V46.32z"/>
    </g>
  </svg>
                    </a></div>
                </div>
                <div class="flex bg-transaparent item-ceter rounded-md text-black ">
                    <div ><a target="_blank"  href={'tel:+971545127066'}> <img src="/contact/2.png" alt="favorite"  width="46" height="46" loading="lazy" rel="preload" aria-label="Add to Watchlist" className=""/>
                    </a>
</div>
                </div>
            </div>
        </div>
        }
          <div class="container mx-auto px-4">
              <div class="flex justify-between items-center gap-4">
                  <div>
                      <h4 class="text-2xl md:text-4xl font-bold">{cars.data.title}</h4>
                  </div>
              </div>
              
              <div class="flex flex-col lg:flex-row items-start gap-4 lg:gap-8 max-w-full overflow-hidden mt-12">
                  <div class="w-full lg:w-[45%]">
                      <div class="w-full h-auto">
                          <div class="all-initial"
                              style={{cursor: 'crosshair', width: 'auto', height: 'auto', fontSize: '0px', position: 'relative', userSelect: "none"}}>
                                <ImageSlider />
                              <div>
                              <div style={{width: '100%', height: '37px', inset: '103px auto auto', display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.4)', position: 'absolute', opacity: '0', transition: 'opacity 300ms ease-in 0s'}}>
                                  </div>
                                  <div
                                      style={{width: '0px', height: '66px', inset: '103px auto auto', display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.4)', position: 'absolute', opacity: '0', transition: 'opacity 300ms ease-in 0s'}}>
                                  </div>
                                  <div
                                      style={{width: '225px', height: '66px', inset: '103px auto auto', display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.4)', position: 'absolute', opacity: '0', transition: 'opacity 300ms ease-in 0s'}}>
                                  </div>
                                  <div
                                      style={{width: '100%', height: '154px', inset: '103px auto auto', display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.4)', position: 'absolute', opacity: '0', transition: 'opacity 300ms ease-in 0s'}}>
                                  </div>
                              </div>
                          </div>
                          <div class="mt-10 mb-10 grid grid-cols-4 gap-2 h-[230px] overflow-y-auto">
                            {console.log(carImages)}
                          {carImages !==  'null' ? 
                          
    carImages.map((car) => (
        <div key={car} className="car-image-group">
            <div className="rounded-md cursor-pointer">
                <img
                    onClick={() => setFeatureImage(car)}
                    alt={`car-${car}-image-${car}`}
                    loading="lazy"
                    width="400"
                    height="400"
                    decoding="async"
                    className="w-full object-cover rounded-md cursor-pointer"
                    src={car} // Use car.Image for the src
                    srcSet={car} // Use car.Image for srcSet if needed
                    style={{ color: 'transparent' }}
                />
            </div>
        </div>
    )) 
    :
    <div className="car-image-group">
        <div className="rounded-md cursor-pointer">
            <img
                alt={`no car image`}
                loading="lazy"
                decoding="async"
                className="w-full object-cover rounded-md cursor-pointer"
                src={'./noimagefound.png'}
                style={{ color: 'transparent' }}
            />
        </div>
    </div>
}

                          </div>
                      </div>

                      {/* <ul class="nav nav-pills mb-3 bg-warning" id="pills-tab" role="tablist">
  <li class="nav-item" role="presentation">
    <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">{t('Details')}</button>
  </li>
</ul> */}
<div class="tab-content" id="pills-tabContent">
  <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
  <h4 className='text-2xl md:text-4xl font-bold' style={{textDecoration: 'underline'}}>
                    
                  </h4>
                  <p style={{paddingLeft: '20px'}} dangerouslySetInnerHTML={{ __html: description }} />
  </div>
</div>
                  </div>
                  <div class="w-full lg:w-[30%]">
                      <div class="border border-warning rounded overflow-hidden">
                          <div class="bg-warning text-black rounded p-3">
                              <h4 class="text-lg font-semibold">{t('Vehicle_Informations')}</h4>
                          </div>
                          <div class="p-3">
                              <div>
                                  <div class="flex flex-col">
                                      <div>
                                          <div class="flex justify-between gap-x-1 py-1">
                                              <p class="font-semibold text-sm">{t("Vin")}:</p>
                                              <p class="font-semibold text-sm">{cars.data.vin}</p>
                                          </div>
                                          <div class="bg-warning w-full h-[1px] my-1 bg-opacity-20"></div>
                                      </div>
                                      
                                  </div>
                              </div>
                              <div>
                                  <div class="flex flex-col">
                                      <div>
                                          <div class="flex justify-between gap-x-1 py-1">
                                              <p class="font-semibold text-sm">{t("Lot")}:</p>
                                              <p class="font-semibold text-sm">{cars.data.lots[0].lot}</p>
                                          </div>
                                          <div class="bg-warning w-full h-[1px] my-1 bg-opacity-20"></div>
                                      </div>
                                  </div>
                              </div>
                              <div>
                                  <div class="flex flex-col">
                                      <div>
                                          <div class="flex justify-between gap-x-1 py-1">
                                              <p class="font-semibold text-sm">{t("Title Code")}:</p>
                                              <p class="font-semibold text-sm">{cars.data.title}</p>
                                          </div>
                                          <div class="bg-warning w-full h-[1px] my-1 bg-opacity-20"></div>
                                      </div>
                                  </div>
                              </div>

                              <div>
                                  <div class="flex flex-col">
                                      <div>
                                          <div class="flex justify-between gap-x-1 py-1">
                                              <p class="font-semibold text-sm">{t("Color")}:</p>
                                              <p class="font-semibold text-sm">{cars.data.color?.name}</p>
                                          </div>
                                          <div class="bg-warning w-full h-[1px] my-1 bg-opacity-20"></div>
                                      </div>
                                  </div>
                              </div>
                              <div>
                                  <div class="flex flex-col">
                                      <div>
                                          <div class="flex justify-between gap-x-1 py-1">
                                              <p class="font-semibold text-sm">{t("Drive")}:</p>
                                              <p class="font-semibold text-sm">{cars.data.drive_wheel.name}</p>
                                          </div>
                                          <div class="bg-warning w-full h-[1px] my-1 bg-opacity-20"></div>
                                      </div>
                                  </div>
                              </div>
                              <div>
                                  <div class="flex flex-col">
                                      <div>
                                          <div class="flex justify-between gap-x-1 py-1">
                                              <p class="font-semibold text-sm">{t("brands")}:</p>
                                              <p class="font-semibold text-sm">{cars?.data?.manufacturer?.name}</p>
                                          </div>
                                          <div class="bg-warning w-full h-[1px] my-1 bg-opacity-20"></div>
                                      </div>
                                      
                                  </div>
                              </div>
                              <div>
                                  <div class="flex flex-col">
                                      <div>
                                          <div class="flex justify-between gap-x-1 py-1">
                                              <p class="font-semibold text-sm">{t("vehicle_type")}:</p>
                                              <p class="font-semibold text-sm">{cars?.data?.vehicle_type?.name}</p>
                                          </div>
                                          <div class="bg-warning w-full h-[1px] my-1 bg-opacity-20"></div>
                                      </div>
                                      
                                  </div>
                              </div>
                              <div>
                                  <div class="flex flex-col">
                                      <div>
                                          <div class="flex justify-between gap-x-1 py-1">
                                              <p class="font-semibold text-sm">{t("keys")}:</p>
                                              <p class="font-semibold text-sm">{cars?.data?.lots[0].keys_available ? "True" : "False"}</p>
                                          </div>
                                          <div class="bg-warning w-full h-[1px] my-1 bg-opacity-20"></div>
                                      </div>
                                      
                                  </div>
                              </div>
                              <div>
                                  <div class="flex flex-col">
                                      <div>
                                          <div class="flex justify-between gap-x-1 py-1">
                                              <p class="font-semibold text-sm">{t("models")}:</p>
                                              <p class="font-semibold text-sm">{cars?.data?.model?.name}</p>
                                          </div>
                                          <div class="bg-warning w-full h-[1px] my-1 bg-opacity-20"></div>
                                      </div>
                                      
                                  </div>
                              </div>
                              <div>
                                  <div class="flex flex-col">
                                      <div>
                                          <div class="flex justify-between gap-x-1 py-1">
                                              <p class="font-semibold text-sm">{t("Power")}:</p>
                                              <p class="font-semibold text-sm">{cars?.data?.engine?.name}</p>
                                          </div>
                                          <div class="bg-warning w-full h-[1px] my-1 bg-opacity-20"></div>
                                      </div>
                                      
                                  </div>
                              </div>

                              <div>
                                  <div class="flex flex-col">
                                      <div>
                                          <div class="flex justify-between gap-x-1 py-1">
                                              <p class="font-semibold text-sm">{t("Year")}:</p>
                                              <p class="font-semibold text-sm">{cars.data.year}</p>
                                          </div>
                                          <div class="bg-warning w-full h-[1px] my-1 bg-opacity-20"></div>
                                      </div>
                                      
                                  </div>
                              </div>
                              <div>
                                  <div class="flex flex-col">
                                      <div>
                                          <div class="flex justify-between gap-x-1 py-1">
                                              <p class="font-semibold text-sm">{t("Engine_cylinders")}:</p>
                                              <p class="font-semibold text-sm">{cars.data.cylinders}</p>
                                          </div>
                                          <div class="bg-warning w-full h-[1px] my-1 bg-opacity-20"></div>
                                      </div>
                                      
                                  </div>
                              </div>
                              <div>
                                  <div class="flex flex-col">
                                      <div>
                                          <div class="flex justify-between gap-x-1 py-1">
                                              <p class="font-semibold text-sm">{"Primary Damage"}:</p>
                                              <p class="font-semibold text-sm">{cars?.data?.lots[0]?.damage?.main?.name ? cars.data.lots[0].damage.main.name : 'UnKnown'}</p>
                                          </div>
                                          <div class="bg-warning w-full h-[1px] my-1 bg-opacity-20"></div>
                                      </div>
                                  </div>
                              </div>
                              <div>
                                  <div class="flex flex-col">
                                      <div>
                                          <div class="flex justify-between gap-x-1 py-1">
                                              <p class="font-semibold text-sm">{"Secondary Damage"}:</p>
                                              <p class="font-semibold text-sm">{cars?.data?.lots[0]?.damage?.second?.name ? cars.data.lots[0].damage.second.name : 'Unknown' }</p>
                                          </div>
                                          <div class="bg-warning w-full h-[1px] my-1 bg-opacity-20"></div>
                                      </div>
                                  </div>
                              </div>
                              {/* <div>
                                  <div class="flex flex-col">
                                      <div>
                                          <div class="flex justify-between gap-x-1 py-1">
                                              <p class="font-semibold text-sm">{t("Exterior")}:</p>
                                              <p class="font-semibold text-sm">{cars.data.}</p>
                                          </div>
                                          <div class="bg-warning w-full h-[1px] my-1 bg-opacity-20"></div>
                                      </div>
                                      
                                  </div>
                              </div> */}
                              {/* <div>
                                  <div class="flex flex-col">
                                      <div>
                                          <div class="flex justify-between gap-x-1 py-1">
                                              <p class="font-semibold text-sm">{t("Warranty")}:</p>
                                              <p class="font-semibold text-sm">{carContent[0] == null ? carContent.Warranty : carContent[0].Warranty}</p>
                                          </div>
                                          <div class="bg-warning w-full h-[1px] my-1 bg-opacity-20"></div>
                                      </div>
                                      
                                  </div>
                              </div> */}
                              {/* <div>
                                  <div class="flex flex-col">
                                      <div>
                                          <div class="flex justify-between gap-x-1 py-1">
                                              <p class="font-semibold text-sm">{t("Interior")}:</p>
                                              <p class="font-semibold text-sm">{carContent[0] == null ? carContent.Interior_color : carContent[0].Interior_color}</p>
                                          </div>
                                          <div class="bg-warning w-full h-[1px] my-1 bg-opacity-20"></div>
                                      </div>
                                      
                                  </div>
                              </div> */}
                              <div>
                                  <div class="flex flex-col">
                                      <div>
                                          <div class="flex justify-between gap-x-1 py-1">
                                              <p class="font-semibold text-sm">{t("Body")}:</p>
                                              <p class="font-semibold text-sm">{cars?.data?.body_type?.name}</p>
                                          </div>
                                          <div class="bg-warning w-full h-[1px] my-1 bg-opacity-20"></div>
                                      </div>
                                      
                                  </div>
                              </div>
                              <div>
                                  <div class="flex flex-col">
                                      <div>
                                          <div class="flex justify-between gap-x-1 py-1">
                                              <p class="font-semibold text-sm">{t("Transmission_type")}:</p>
                                              <p class="font-semibold text-sm">{cars?.data?.transmission ? cars.data.transmission.name : 'Unknown'}</p>
                                          </div>
                                          <div class="bg-warning w-full h-[1px] my-1 bg-opacity-20"></div>
                                      </div>
                                      
                                  </div>
                              </div>
                              <div>
                                  <div class="flex flex-col">
                                      <div>
                                          <div class="flex justify-between gap-x-1 py-1">
                                              <p class="font-semibold text-sm">{t("Engine")}:</p>
                                              <p class="font-semibold text-sm">{cars?.data?.engine?.name}</p>
                                          </div>
                                          <div class="bg-warning w-full h-[1px] my-1 bg-opacity-20"></div>
                                      </div>
                                      
                                  </div>
                              </div>
                              <div>
                                  <div class="flex flex-col">
                                      <div>
                                          <div class="flex justify-between gap-x-1 py-1">
                                              <p class="font-semibold text-sm">{t("Fuel_type")}:</p>
                                              <p class="font-semibold text-sm">{cars?.data?.fuel?.name ? cars.data.fuel.name :'Unknown'}</p>
                                          </div>
                                          <div class="bg-warning w-full h-[1px] my-1 bg-opacity-20"></div>
                                      </div>
                                      
                                  </div>
                              </div>
                              <div>
                                  <div class="flex flex-col">
                                      <div>
                                          <div class="flex justify-between gap-x-1 py-1">
                                              <p class="font-semibold text-sm">{t("Categoryf")}:</p>
                                              <p class="font-semibold text-sm">{cars?.data?.lots[0]?.condition?.name}</p>
                                          </div>
                                          <div class="bg-warning w-full h-[1px] my-1 bg-opacity-20"></div>
                                      </div>
                                  </div>
                              </div>
                              <div>
                                  <div class="flex flex-col">
                                      <div>
                                          <div class="flex justify-between gap-x-1 py-1">
                                              <p class="font-semibold text-sm">{t("Meter")}:</p>
                                              <p class="font-semibold text-sm">{cars?.data?.lots[0]?.odometer?.km} Km</p>
                                          </div>
                                          <div class="bg-warning w-full h-[1px] my-1 bg-opacity-20"></div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  
                  <div class="w-full lg:w-[25%]">
                      <div class="border border-warning rounded overflow-hidden mb-2">
                          <div class="bg-warning text-black rounded p-3">
                              <h4 class="text-lg font-semibold">{t('Vehicle')}</h4>
                          </div>
                          <div class="p-3">
                              <div class="flex flex-col gap-4 justify-center items-center">
                                  <div class="text-xl"><span style={{fontSize: '18px'}}>{"Current bid USD"} : </span>  <span style={{fontSize: '30px', fontWeight:'bolder', color: '#036eb5'}}>{cars?.data?.lots[0]?.bid ?? cars?.data?.lots[0]?.bid  ?? "null"}</span> USD</div>
                                  
                                  <div class="text-xl"><span style={{fontSize: '18px'}}>{"Retail price"} : </span>  <span style={{fontSize: '30px', fontWeight:'bolder', color: '#036eb5'}}>{cars?.data?.lots[0]?.estimate_repair_price ?? cars?.data?.lots[0]?.estimate_repair_price ?? "null"}</span> USD</div>
                                  <div style={{display: "flex"}}> 
                                  <a class="m-1" target="_blank" href={'tel:+971545127066'}>
</a></div>
                              </div>
                          </div>
                      </div>
                  {!isMobile &&
                      <div class="border border-warning rounded overflow-hidden mt-4">
                          <div class="bg-warning text-black rounded p-2">
                              <h4 class="text-lg font-semibold">{t('Contact_us_for_more_information')}</h4>
                          </div>
                          <div class="p-3">
                              <div class="flex flex-col md:flex-row justify-center gap-2">
                                  <button class="flex btn bg-transparent  p-2 item-ceter rounded-md text-black">
                                      <div class="px-1"><a class="m-1" target="_blank"
                                          href={'https://wa.me/+971545127066'}>
                                      <img src="/contact/1.png" alt="favorite"  width="46" height="46" loading="lazy" rel="preload" aria-label="Add to Watchlist" className=""/>
                                      </a></div>
                                  </button>
                                  <div class="flex btn  bg-transparent  p-1 p-y-2 item-ceter rounded-md text-black " style={{marginLeft: '15px',marginRight: '15px'}}>
                                      <div ><a class="m-1" href={"mailto:'help@driven.llc'"}>
                                      <svg height="50px" width="50px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 64 64" xmlSpace="preserve">
    <g>
      <path fill="#D1A552" d="M32,0C14.328,0,0,14.328,0,32s14.328,32,32,32s32-14.328,32-32S49.672,0,32,0z M46.32,16l-14.4,14.4 L17.52,16H46.32z M50.4,46.32H13.6V21.28L31.92,39.6c0.56,0.56,1.44,0.56,2,0L50.4,23.28V46.32z"/>
    </g>
  </svg>
                                      </a>
                                      </div>
                                  </div>
                                  <div class="flex btn  bg-transparent p-1 p-y-2 item-ceter rounded-md text-black ">
                                      <div >
                                      <a class="m-1" target="_blank" href={'tel:+971545127066'}>
                                      <img src="/contact/2.png" alt="favorite"  width="46" height="46" loading="lazy" rel="preload" aria-label="Add to Watchlist" className=""/>
                                      </a></div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      }

                      <div class="border border-warning rounded overflow-hidden mt-4">
                          <div class="bg-warning text-black rounded p-2">
                              <h4 class="text-lg font-semibold">{t('address')}</h4>
                          </div>
                          <MapComponentshower latitude={cars.data.lots[0].location.latitude} longitude={cars.data.lots[0].location.longitude}/>
                      </div>
                  </div>
              </div>
          </div>
          
      </div>
          )}
    <div>

    </div>
    </div>

  )
}
export default UsaSaleDetails;


