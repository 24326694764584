import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import './package.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Packages = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [packageType, setPackageType] = useState('Private');
  const [packages, setPackages] = useState([]);
  const [error, setError] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [selectedPackageprice, setSelectedPackageprice] = useState(null);
  const [selectedPackageterm, setSelectedPackageterm] = useState(null);
  const user = useState(JSON.parse(sessionStorage.getItem('user')) || {});
  const users = user[0].data[0];
  const price = users.payment;
  const id = users.id;

  const handleConfirm = (pkgId) => {
    // Add your confirmation logic here
    let expiryDate;
    if (selectedPackageterm === 'Year') {
      expiryDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString();
    } else if (selectedPackageterm === 'Month') {
      expiryDate = new Date(new Date().setMonth(new Date().getMonth() + 1)).toISOString();
    } else {
      expiryDate = new Date(new Date().setDate(new Date().getDate() + 7)).toISOString();
    }
    const toastNotification = (msg) => toast.success(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
  });
  
  const notifyError = (msg) => toast.error(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
  });

  setSelectedPackage(null);
  console.log(price);
    if(selectedPackageprice > price){
      notifyError('Error Adding Purchased Package');
      return;
    }else{



    const finalFormData = {
      U_id: id,
      Purchased_date: new Date().toISOString(),
      Expiry_date: expiryDate,
      Package_id: pkgId,
      Price: selectedPackageprice
    };

  console.log(finalFormData);

  axios.post('https://api.drivenappinc.com/purchased_package_user', finalFormData)
  .then(response => {
      toastNotification('Purchased Package Added Successfully');
      navigate('/packages');
  })
  .catch(error => {
      console.log(error.message);
      notifyError('Error Adding Purchased Package');
  }); 
  };
  }
  const handleSubmit = (pkgId,pkgprice,term) => {
    setSelectedPackage(pkgId);
    setSelectedPackageprice(pkgprice);
    setSelectedPackageterm(term);
  };

  useEffect(() => {
    fetchPackages(packageType);
  }, [packageType]);

  async function fetchPackages(type) {
    try {
      const response = await fetch(`https://api.drivenappinc.com/packages/${type}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setPackages(data); // Assuming your data structure matches the expected package data
    } catch (error) {
      console.error('Error fetching packages:', error);
      setError('Error fetching packages');
    }
  }
  return (
    <>
      <Navbar />
      <div className="container newcont p-3">
        <h3 className="my-4" align="center" style={{ fontSize: 'xx-large', textDecoration: 'underline' }}>
          <b>{t("Choose A Package")}</b>
        </h3>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button className={`mt-3 mb-3 btn btn-new1 ${packageType !== 'Private' ? 'btn bg-warning btn-warning' : 'btn btn-outline-warning'}`}
            onClick={() => { setPackageType("Private") }}>
            {t("Private Package")}
          </button>
          <button className={`mt-3 mb-3 btn btn-new2 ${packageType === 'Private' ? 'btn bg-warning btn-warning' : 'btn btn-outline-warning'}`}
            onClick={() => { setPackageType("Dealer") }}>
            {t("Dealer Package")}
          </button>
        </div>

        {error && <div className="alert alert-danger">{error}</div>}

        <div className="row row-cols-1 row-cols-md-4 g-4">
          {packages.map((pkg, index) => (
            <div className="col" key={index}>
              <div className="card" style={{ width: "22rem", color: "black" }}>
                <div className="card-body">
                  <h5 className="card-subtitle" style={{ fontSize: 'x-large' }}>{pkg.title}</h5>
                  <h5 className="card-title" style={{ fontSize: 'xx-large' }}><b>AED {pkg.price}</b></h5>
                  <hr className='bg-warning' />
                  <h6 className="card-subtitle mb-2 mt-3 text-muted">Details</h6>
                  <p className="card-text"><span style={{ color: 'black' }}>Limit for {pkg.term == 'Month' ? '30 Days' : `${pkg.term == 'Month' ? '30 Days': '1 Year'}`}</span></p>


                  <p className="card-text"><span style={{ color: 'black' }}>Featured {pkg.number_of_car_featured} Days</span></p>
                  <p className="card-text"><span style={{ color: 'black' }}>Total Number Of Cars {pkg.number_of_car_add}</span></p>
                  <button className="btn btn-warning mt-3" style={{ width: '100%' }} onClick={() => handleSubmit(pkg.id,pkg.price,pkg.term)}>Select</button>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Modal */}
        {selectedPackage && (
          <div className="modal show" tabIndex="-1" style={{ display: 'block' }} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">{t("Confirm Purchase")}</h5>
                  <button type="button" className="btn-close" onClick={() => setSelectedPackage(null)} aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  {t("Are you sure you want to purchase this package?")}
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={() => setSelectedPackage(null)}>{t("Cancel")}</button>
                  <button type="button" className="btn btn-primary" onClick={() => handleConfirm(selectedPackage)}>{t("Confirm")}</button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}


export default Packages;