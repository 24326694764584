import React from 'react'
import { useEffect, useState, useRef } from "react";
import { bidsColumns, buyNowReqColumns } from "../../datatablesource";
import Sidebar from "../../components/sidebar/Sidebar"
import { Circles } from 'react-loader-spinner';
import Navbar from "../../components/navbar/Navbar"

import List from '../list/List'

const BuyNowReq = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true)
console.log(data);
    useEffect(() => {
    const fetchDataForPosts = async () => {
        try {
        const response = await fetch(
            `https://api.drivenappinc.com/buynowreq`
        );
        if (!response.ok) {
            throw new Error(`HTTP error: Status ${response.status}`);
        }
        let postsData = await response.json();
        setData(postsData);
        setIsLoading(false)
        } catch (err) {
        console.log(err.message)
        setIsLoading(false)
        }
    };
    
        fetchDataForPosts();
    }, [])



    return (
        
        <div className="list">
          <Sidebar/>
          <div className="listContainer">
            <Navbar/>
            {isLoading ? (
              <div style=
                {{ 
                  display: 'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center', 
                  top: 0, 
                  bottom: 0, 
                  right: 0, 
                  left: 0, 
                  position: 'fixed' 
                }}>
                <Circles
                height="80"
                width="80"
                color="#FFC107"
                ariaLabel="circles-loading"
                wrapperClass=""
                visible={true}
                />
              </div>
            ) : (data && 
                ( <>
                    <List 
                        data={data} 
                        link='buy_now_requests'
                        columns={buyNowReqColumns} 
                        setData={setData} 
                        title={"Buy Now Requests"}
                    />
                </>
                ))}
        </div>
    </div>
    )
}

export default BuyNowReq