import React, { useState } from "react";
import "./style.css";
import { useTranslation } from "react-i18next";
import PaymentForm from "./paymentform";
import { useNavigate } from "react-router-dom";

const Payment = () => {
  const { t } = useTranslation();
  const [deposit, setDeposit] = useState('1');
  const [steps, setStep] = useState('1');
  const user = useState(JSON.parse(sessionStorage.getItem('user')) || {});
    const users = user[0].data[0];
    const navigate = useNavigate();

  const Deposit = ({ deposit, step }) => {
    const [state2, setState2] = useState('1');

    return (
      <div className="step2">
        {deposit !== '1' && step === '2' && deposit === 'Bank' && (
          <>
            <div className="row m-3">
              <h4 style={{ color: "black" }} align="center" className="m-2">{t('Step_2')}</h4>
              <h3 style={{ color: "black" }} align="center">
                {t('psootf')}
              </h3>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="card" onClick={() => { setState2("new"); setStep('3'); setDeposit('new')}}>
                  <img className="card-img-top" src="/dontHaveSlip.svg" alt="Card image cap" style={{ width: '50%', marginLeft: "25%" }} />
                  <div className="card-body">
                   {t('iwtmand')}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card" onClick={() => { setState2("alreadyd"); setStep('3'); setDeposit('alreadyd')}}>
                  <img className="card-img-top" src="/haveSlip.svg" alt="Card image cap" style={{ width: '50%', marginLeft: "25%" }} />
                  <div className="card-body">
                    {t('iahads')}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {(deposit === 'Cash' || deposit === 'Cheque') && (
          <div className="col-md-6" style={{ color: "black" }}>
            <div className="card" onClick={() => { setState2("already"); setStep('3'); }}>
              <img className="card-img-top" src="/ChequeNotSelected21.svg" alt="Card image cap" style={{ width: '50%', marginLeft: "25%" }} />
              <div className="card-body">
                <h2 style={{ color: "black", fontSize: '30px' }}>{t(deposit)}</h2>
                <p style={{ color: "black", fontSize: '15px' }}>
                  {t('checash')}
                  <br />
                  {t('saccorbl')}
                  <br />
                  {t('Yblwbi')}
                </p>
                <br/><br/>
                <p style={{ color: "black" }}><a href="https://maps.app.goo.gl/5fudwkaSDGkwDs6P7" target="_blank" title="Open Google Maps">{t('Location')}</a></p>
              </div>
            </div>
          </div>
        )}
        <Step3 state={deposit} />
      </div>
    );
  };

  const Step3 = ({ state }) => {
    console.log(state,steps);
    return (
      <div className="step3">
        {state !== null && steps === '3' && state === 'new' && (
          <>
            <div className="row m-3">
              <h4 style={{ color: "black" }} align="center" className="m-2">{t('Step_3')}</h4>
              {/* <h3 style={{ color: "black" }} align="center">
                Please select one of the following
              </h3> */}
            </div>
              <div className="row">
                <div className="col-md-6" style={{height: '100%'}}>
                  <div className="card">
                    <img className="card-img-top" src="/dontHaveSlip.svg" alt="Card image cap" style={{ width: '50%', marginLeft: "25%" }} />
                    <div className="card-body">
                      {t('Account_Title')}: {t('a_title')} <br />
                      {t('Account_Number')}: 3002741410000001 <br />
                      {t('IBAN_Number')}: AE830973002741410000001 <br />
                      {t('Currency')} : {t('Currency')} <br />
                      {t('Swift_code')}: E097AEXXXXX <br />
                      {t('Bank_Name')}: {t('bank_name')} <br />
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="card"  onClick={() => { setStep('3'); setDeposit('alreadyd')}}>
                    <img className="card-img-top" src="/dontHaveSlip.svg" alt="Card image cap" style={{ width: '50%', marginLeft: "25%" }} />
                    <div className="card-body">
                      {t('cagdr')}
                    </div>
                  </div>
                </div>
              </div>
          </>
        )}


{state !== null && steps === '3' && state === 'alreadyd' && (
          
            navigate('/user/dashboard/paymentform')
          
        )}
      </div>
    );
  };


  return (
    <div>
      {steps === '1' && (
        <>
          <div className="row">
            <div className="col-md-11 bold-cont">
              <div className="row">
                <div className="col">
                  <h3 style={{ marginTop: '10px' }}>{t("Ab")}</h3>
                </div>
                <div className="col" align="right" style={{ marginTop: '10px' }}>
                  <h3><span style={{ color: "red" }}>{users.payment}</span>{t("currency")}</h3>
                </div>
              </div>
            </div>
          </div>
          <h1 align="center" style={{ color: "#C69944", fontSize: '40px', lineHeight: '50px', margin:'0 0 10px' }}>
            {t('Deposit_Amount')}
          </h1>
          <div className="row">
            <div className="col-md-4" align="center">
              <div className="card" onClick={() => { setDeposit("Bank"); setStep('2'); }} style={{ width: '19rem', marginTop: "10px" }}>
                <img className="card-img-top" src="/BankTransferNotSelected.svg" alt="Card image cap" style={{ width: '50%', marginLeft: "25%" }} />
                <div className="card-body">
                  <h4 align="center" style={{ color: "#C69944" }}>
                    {t('BDBT')}
                  </h4>
                  <p className="card-text" style={{ color: "black", fontSize: "small" }}>
                    {t('Doyrbl')}
                    <br />
                    {t('MSBR')}
                    <br />
                    {t('Ahtuya')}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4" align="center">
              <div className="card" onClick={() => { setDeposit("Cheque"); setStep('2'); }} style={{ width: '19rem', marginTop: "10px", height: "100%" }}>
                <img className="card-img-top" src="/ChequeNotSelected2.svg" alt="Card image cap" style={{ width: '50%', marginLeft: "25%" }} />
                <div className="card-body">
                  <h4 align="center" style={{ color: "#C69944" }}>
                    {t('Cheque')}
                  </h4>
                  <p className="card-text" style={{ color: "black", fontSize: "small" }}>
                  {t('Submit_a_cheque')}
                    <br />
                  {t('Limit_updates_immediately')}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4" align="center">
              <div className="card" onClick={() => { setDeposit("Cash"); setStep('2'); }} style={{ width: '19rem', marginTop: "10px", height: "100%" }}>
                <img className="card-img-top" src="/BankTransferNotSelected.svg" alt="Card image cap" style={{ width: '50%', marginLeft: "25%" }} />
                <div className="card-body">
                  <h4 align="center" style={{ color: "#C69944" }}>
                  {t('Cash_Deposit')}
                  </h4>
                  <p className="card-text" style={{ color: "black", fontSize: "small" }}>
                  {t('Deposit_20_of')}
                    <br />
                  {t('Deposit_in_Branch')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <Deposit deposit={deposit} step={steps} />
    </div>
  );
};

export default Payment;
