import React from 'react'
import CarAuction from './CarAuction';
import './App.css';
import Navbar from '../../Navbar';

const Auction = () => {
  return (
    <>
      <Navbar />
      <CarAuction />
    </>
  )
}

export default Auction