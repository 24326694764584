import axios from 'axios';
import { t } from 'i18next';
import React, { useState } from 'react';

const CheckoutForm = ({ numberId, onSuccess, mailingAddressLog, mailingAddressLat }) => {
  const [fullName, setFullName] = useState('');
  const [drivingLicenceFront, setDrivingLicenceFront] = useState('');
  const [drivingLicenceBack, setDrivingLicenceBack] = useState('');
  const [trafficNumber, setTrafficNumber] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior
  
    try {
      const formData = {
        number_id: numberId,
        full_Name: fullName,
        driving_licence_front: drivingLicenceFront,
        driving_licence_back: drivingLicenceBack,
        mailing_address_lat: mailingAddressLat,
        mailing_address_log: mailingAddressLog,
        Traffic_number: trafficNumber
      };
      
      console.log(formData);
      const response = await axios.post('https://api.drivenappinc.com/buynumber', formData);
  
      console.log('Buy Number added:', response.data);
      onSuccess('Buy Number added');
    } catch (error) {
      console.error('Error adding Buy Number:', error.message);
      alert("Failed to add Number");
    }
  };

  const handleFileChange = (setFile) => (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFile(reader.result); // Get the base64 string
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="fullName">Full Name</label>
        <input
          type="text"
          className="form-control"
          id="fullName"
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
          required
        />
      </div>
      <div className="form-group m-2">
        <label htmlFor="drivingLicenceFront">Driving Licence Front</label>
        <input
          type="file"
          className="form-control-file"
          id="drivingLicenceFront"
          onChange={handleFileChange(setDrivingLicenceFront)}
          accept=".jpg,.png,.jpeg"
          required
        />
      </div>
      <div className="form-group m-2">
        <label htmlFor="drivingLicenceBack">Driving Licence Back</label>
        <input
          type="file"
          className="form-control-file"
          id="drivingLicenceBack"
          onChange={handleFileChange(setDrivingLicenceBack)}
          accept=".jpg,.png,.jpeg"
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="trafficNumber">Traffic Number</label>
        <input
          type="text"
          className="form-control"
          id="trafficNumber"
          value={trafficNumber}
          onChange={(e) => setTrafficNumber(e.target.value)}
          required
        />
      </div>
      {error && <div className="alert alert-danger">{error}</div>}
      <button type="submit" className="btn btn-primary my-3">{t('Submit')}</button>
    </form>
  );
};

export default CheckoutForm;
