import React, { useEffect } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './mapcomponentshow.css';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css'; // Import the fullscreen CSS
import 'leaflet-fullscreen'; // Import the fullscreen plugin

// Fix paths for marker icons
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const MapComponent = ({ latitude, longitude }) => {
  if(latitude === null){
    latitude = 0;
  }
  if(longitude === null){
    longitude = 0;
  }
  useEffect(() => {
    const mapContainer = document.getElementById('map');
    if (!mapContainer) return;

    const map = L.map(mapContainer, {
      center: [latitude, longitude],
      zoom: 14,
    });

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '',
    }).addTo(map);

    L.marker([latitude, longitude]).addTo(map);

    // Add fullscreen control
    map.addControl(new L.Control.Fullscreen());

    return () => {
      map.remove(); // Clean up the map instance on unmount
    };
  }, [latitude, longitude]);

  return <div id="map" style={{ height: '500px', width: '100%' }}></div>;
};

export default MapComponent;
