import React from 'react'
import { useEffect, useState, useRef } from "react";
//import { carsColumns } from "../../datatablesource";
import { Circles } from 'react-loader-spinner';
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import { StatusDropdown, FeaturedDropdown, StatusDropdownpack } from '../../components/customDataButtons/customButtons';
import List from '../list/List'




const Packagesadmin = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true)
    const childRef = useRef(null);
    const [popupImageUrl, setPopupImageUrl] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);


  // Function to handle opening the popup with the image URL
  const handleImageClick = (imageUrl) => {
    setPopupImageUrl(imageUrl);
    setIsPopupOpen(true);
  };

  // Function to close the popup
  const closePopup = () => {
    setIsPopupOpen(false);
    setPopupImageUrl(''); // Reset the image URL when closing the popup
  };


    useEffect(() => {
        const fetchDataForPosts = async () => {
          try {
            const response = await fetch(
              `https://api.drivenappinc.com/packages`
            );
            if (!response.ok) {
              throw new Error(`HTTP error: Status ${response.status}`);
            }
            let postsData = await response.json();
            setData(postsData);
            setIsLoading(false)
          } catch (err) {
            console.log(err.message)
            setIsLoading(false)
          }
        };
    
        fetchDataForPosts();
      }, [])
      const [getStatus, setGetStatus] = useState(data)

      const handleStatusChange = (id, newValue) => {
        setGetStatus(getStatus.map(row => (row.id === id ? { ...row, status: newValue } : row)));
      };

      const paymentColumns = [
        { field: "id", headerName: "ID", width: 70 },
        {
          field: "title",
          headerName: "TiTle",
          width: 100,
          
        },
        {
          field: "price",
          headerName: "Price",
          width: 230,
        },
      
        {
          field: "term",
          headerName: "Term",
          width: 230,
        },
        {
          field: "number_of_car_featured",
          headerName: "Days Featured Cars",
          width: 160
        },
        {
            field: "number_of_car_add",
            headerName: "Total Cars",
            width: 160
          },
          {
            field: "status",
            headerName: "Status",
            width: 160,
            renderCell: (params) => (
                <StatusDropdownpack
                  id={params.row.id}
                  statusActive={params.row}
                  onChange={(newValue) => handleStatusChange(params.row.id, newValue)}
                />
              ),
          }
      ];
      
    return (
        <>
        <div className="list">
          <Sidebar/>
          <div className="listContainer">
            <Navbar/>
            {isLoading ? (
              <div style=
                {{ 
                  display: 'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center', 
                  top: 0, 
                  bottom: 0, 
                  right: 0, 
                  left: 0, 
                  position: 'fixed' 
                }}>
                <Circles
                height="80"
                width="80"
                color="#FFC107"
                ariaLabel="circles-loading"
                wrapperClass=""
                visible={true}
                />
              </div>
            ) : (data && (
                <List data={data} columns={paymentColumns} link={'Packages'} setData={setData} title={"Packages"}/> 
              )
            )}
          </div>
        </div>
      </>
    )
}

export default Packagesadmin