import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import axios from "axios";
import { FaTimes } from 'react-icons/fa';
import { Circles } from 'react-loader-spinner';


import English from "./English";
import Arabic from "./Arabic";

const Edit = () => {
    const { carId } = useParams();
    const [images, setImages] = useState(null);
    const [expDate, setExpDate] = useState(new Date().toISOString().split('T')[0])
    const [expanded, setExpanded] = useState(true);
    const [featureImage, setFeatureImage] = useState(null);
    const [arabicExpand, setArabicExpand] = useState(false);
    const [getDescription, setGetDescription] = useState('')
    const [getDescriptionAr, setGetDescriptionAr] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    
    const [cars, setCars] = useState({
        feature_image: '',
        price: '',
        previous_price: '',
        speed: '',
        year: '',
        mileage: '',
        is_featured: false,
        specification: '',
        status: '',
        latitude: '',
        longitude: '',
        expiry_date: '',
        vendor: '',
        Vin_Number: '',
        Incremental_Amount: ''
      });
    
      const [carContentEn, setCarContentEn] = useState({
        category: '',
        car_condition: '',
        brand: '',
        car_model: '',
        fuel_type: '',
        transmission_type: '',
        title: '',
        slug: '',
        description: '',
        address: '',
        meta_keyword: '',
        meta_description: '',
        body_type: '',
        Enterior_color: '',
        Interior_color: '',
        Emirate: '',
        Warranty: '',
        Specs: '',
        Power: '',
        trim: '',
        Engine_cylinders: ''
      });

      const [carContentAr, setCarContentAr] = useState({
        category: '',
        car_condition: '',
        brand: '',
        car_model: '',
        fuel_type: '',
        transmission_type: '',
        title: '',
        slug: '',
        description: '',
        address: '',
        meta_keyword: '',
        meta_description: '',
        body_type: '',
        Enterior_color: '',
        Interior_color: '',
        Emirate: '',
        Warranty: '',
        Specs: '',
        Power: '',
        trim: '',
        Engine_cylinders: ''
      });
    
      const [carImages, setCarImages] = useState([]);
    

    useEffect(() => {
        const fetchCarData = async (url, setter) => {
            try {
              const response = await fetch(url);
              if (!response.ok) {
                throw new Error(`HTTP error: Status ${response.status}`);
              }
              const carData = await response.json();
              setter(carData);
            } catch (err) {
              console.log(err.message);
            }
        };

        const fetchDataForPosts = async () => {
            setIsLoading(true);
      
            await fetchCarData(`https://api.drivenappinc.com/cars/${carId}`, setCars);
            await fetchCarData(`https://api.drivenappinc.com/carsen/${carId}`, setCarContentEn);
            await fetchCarData(`https://api.drivenappinc.com/carsar/${carId}`, setCarContentAr);
            await fetchCarData(`https://api.drivenappinc.com/carsimages/${carId}`, setCarImages);
      
            setIsLoading(false);
        };
      
          fetchDataForPosts();
          
      }, [carId])

      useEffect(() => {
        setFeatureImage(cars[0]?.feature_image)
      }, [cars])
      
      const handleFeatureImage = (event) => {
        const file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onloadend = () => {
            setFeatureImage(reader.result);
          };
          reader.readAsDataURL(file);
        }
      };
    
      const handleImageChange = (e, index) => {
        const file = e.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64String = reader.result;
            setCarImages(prevImages => [...prevImages, { Image: base64String }]);
          };
          reader.readAsDataURL(file);
        }
      };

      const handleChange = (e, setState) => {
        const { name, value } = e.target;
        setState(prevState => ({
          ...prevState,
          [name]: value
        }));
      };

      const handleRemoveImage = (index) => {
        const updatedFiles = [...carImages];
        updatedFiles.splice(index, 1);
        setCarImages(updatedFiles);
      };
    
      const handleRemoveFeatureImage = () => {
        setFeatureImage(null);
      };

      console.log("Arabic Lang: ", carContentAr)
      console.log("English Lang: ", carContentEn)
      console.log("Images: ", carImages)

      const handleSubmit = async (e) => {
        e.preventDefault();
        setCarContentAr.description = getDescriptionAr !== '' ? getDescriptionAr : carContentAr.description;
        setCarContentEn.description = getDescription !== '' ? getDescriptionAr : carContentAr.description;
        setCars.feature_image = featureImage[0] !== '' ? featureImage[0] : cars.feature_image;
        try {
            await Promise.all([
              axios.put(`https://api.drivenappinc.com/cars/${carId}`, cars),
              axios.put(`https://api.drivenappinc.com/carsen/${carId}`, carContentEn),
              axios.put(`https://api.drivenappinc.com/carsar/${carId}`, carContentAr),
              axios.put(`https://api.drivenappinc.com/carimages/${carId}`, carImages)
            ]);
            alert('Update successful');
          } catch (err) {
            alert('Update failed');
          }
        // Here you can access formData and perform actions like submitting to a server
        
      };
    
      const toggleExpand = () => {
        setExpanded(!expanded);
        setArabicExpand(!arabicExpand);
      };
console.log(carContentAr,cars,carContentEn);
    return (
       <>
        <div className="new">
            <Sidebar />
            <div className="newContainer">
                <Navbar />
                {isLoading ? (
                        <div style=
                            {{ 
                                display: 'flex', 
                                justifyContent: 'center', 
                                alignItems: 'center', 
                                top: 0, 
                                bottom: 0, 
                                right: 0, 
                                left: 0, 
                                position: 'fixed' 
                            }}>
                            <Circles
                            height="80"
                            width="80"
                            color="#FFC107"
                            ariaLabel="circles-loading"
                            wrapperClass=""
                            visible={true}
                            />
                        </div>
                ) : (carContentEn && (<>
                <div className="top">
                <h1>Edit Car</h1>
                </div>
                <div className="bottom">
                    <div className="left">
                    <label>
                        Feature Image
                    </label>
                    {featureImage ? 
                        <li style={{ display: 'flex', flexWrap: 'wrap', position: 'relative', marginBottom: '10px', listStyle: 'none' }}>
                        <img
                            src={
                                featureImage ? featureImage
                                : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                            }
                            alt=""
                        />
                        <button
                            onClick={handleRemoveFeatureImage}
                            style={{
                            position: 'absolute',
                            top: '0',
                            right: '160px',
                            background: 'none',
                            border: 'none',
                            cursor: 'pointer',
                            }}
                        >
                            <FaTimes style={{ color: 'red' }} className="icon" />
                        </button>
                        </li> : 
                        <img
                        src={
                            featureImage ? featureImage
                            : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                        }
                        alt=""
                        />
                    }
                    
                    <label style={{ marginTop: '7%' }}>
                        Other Images
                    </label>
                    <ul style={{ listStyle: 'none', padding: 0 }}>
                        <li style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {carImages.map((image, index) => (
                        <div key={index} style={{ position: 'relative', marginLeft: '7px', marginBottom: '10px', listStyle: 'none' }}>
                        <img
                            key={index}
                            src={
                            image?.Image ? image?.Image   
                                : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                            }
                            alt=""
                        />
                            <button
                            onClick={() => handleRemoveImage(index)}
                            style={{
                                position: 'absolute',
                                top: '0',
                                right: '125px',
                                background: 'none',
                                border: 'none',
                                cursor: 'pointer',
                            }}
                            >
                            <FaTimes style={{ color: 'red' }} className="icon" />
                            </button>
                        </div>
                        ))}
                        </li>
                    </ul>
                    </div>
                    <div className="right">
                    <form>
                        <div className="formInput">
                        <label htmlFor="featureImage">
                            Feature Image: <DriveFolderUploadOutlinedIcon className="icon" />
                        </label>
                        <input
                            type="file"
                            id="featureImage"
                            onChange={handleFeatureImage}
                            style={{ display: "none" }}
                        />
                        </div>
                        <div className="formInput">
                            <label htmlFor="file">
                                Other Images: <DriveFolderUploadOutlinedIcon className="icon" />
                            </label>
                            <input
                                type="file"
                                id="file"
                                multiple
                                onChange={handleImageChange}
                                style={{ display: "none" }}
                            />
                        </div>
                        <div className="formInput">
                            <label>Price</label>
                            <input 
                            required
                            type='number' 
                            name='price'
                            value={cars[0].price}
                            onChange={(e) => handleChange(e, setCars)}
                            />
                        </div>
                        <div className="formInput">
                            <label>Incremental Amount</label>
                            <input 
                            type='number' 
                            name='pervious_price'
                            value={cars[0].pervious_price}
                            onChange={(e) => handleChange(e, setCars)}
                            />
                        </div>
                        <div className="formInput">
                            <label>Speed</label>
                            <input 
                            required
                            type='text' 
                            name='speed'
                            value={cars[0].speed}
                            onChange={(e) => handleChange(e, setCars)}
                            />
                        </div>
                        <div className="formInput">
                            <label>Year</label>
                            <input 
                            required
                            type='text' 
                            name='year'
                            value={cars[0].year}
                            onChange={(e) => handleChange(e, setCars)}
                            />
                        </div>
                        <div className="formInput">
                            <label>Mileage</label>
                            <input 
                            required
                            type='text' 
                            name='mileage'
                            value={cars[0].mileage}
                            onChange={(e) => handleChange(e, setCars)}
                            />
                        </div>
                        <div className="formInput">
                            <label>Featured</label>
                            <input
                            required 
                            type='text' 
                            name='is_featured'
                            value={cars[0].is_featured}
                            onChange={(e) => handleChange(e, setCars)}
                            />
                        </div>
                        <div className="formInput">
                            <label>Specification</label>
                            <input 
                            required
                            type='text' 
                            name='specification'
                            value={cars[0].specification}
                            onChange={(e) => handleChange(e, setCars)}
                            />
                        </div>
                        <div className="formInput">
                            <label>Status</label>
                            <input
                            required 
                            type='text' 
                            name='status'
                            value={cars[0].status}
                            onChange={(e) => handleChange(e, setCars)}
                            />
                        </div>
                        <div className="formInput">
                            <label>Latitude</label>
                            <input
                            required 
                            type='text' 
                            name='latitude'
                            value={cars[0].latitude}
                            onChange={(e) => handleChange(e, setCars)}
                            />
                        </div>
                        <div className="formInput">
                            <label>Longitude</label>
                            <input
                            required 
                            type='text' 
                            name='longitude'
                            value={cars[0].longitude}
                            onChange={(e) => handleChange(e, setCars)}
                            />
                        </div>
                        <div className="formInput">
                            <label>Expiry Date</label>
                            <input
                            required 
                            type='datetime-local' 
                            name='expiry_date'
                            value={new Date(cars[0]?.expiry_date).toISOString().split('T')[0]}
                            onChange={(e) => handleChange(e, setCars)}
                            />
                        </div>
                        <div className="formInput">
                            <label>Vendor</label>
                            <input
                            required 
                            type='text' 
                            name='vendor'
                            value={cars[0].vendor}
                            onChange={(e) => handleChange(e, setCars)}
                            />
                        </div>
                        <div className="formInput">
                            <label>Vin Number</label>
                            <input
                            required 
                            type='text' 
                            name='Vin_Number'
                            value={cars[0].Vin_Number}
                            onChange={(e) => handleChange(e, setCars)}
                            />
                        </div>
                    </form>
                    </div>
                </div>
                <div className="expand-form-btn">
                <button 
                    style={{
                    backgroundColor: `${expanded ? "#FFC107" : "white"}`, 
                    color: `${expanded ? "white" : "#007bff"}`, fontWeight: 600 }} 
                    className="expand-button" 
                    onClick={toggleExpand 
                    }>
                    English Language (Default)
                </button>
                {
                    expanded && 
                    <English 
                        setCarContentEn={setCarContentEn}  
                        carContentEn={carContentEn}
                        setGetDescription={setGetDescription}
                        handleChange={handleChange}
                    />
                }
                </div>
                
                <div className="expand-form-btn">
                <button 
                    style={{
                    backgroundColor: `${arabicExpand ? "#FFC107" : "white"}`, 
                    color: `${arabicExpand ? "white" : "#007bff"}`, fontWeight: 600 }} 
                    className="expand-button" 
                    onClick={toggleExpand 
                    }>
                    عربي Language
                </button>
                {
                    arabicExpand && 
                    <Arabic 
                    carContentAr={carContentAr}
                    setCarContentAr={setCarContentAr} 
                    setGetDescriptionAr={setGetDescriptionAr}
                    handleChange={handleChange}
                    />
                }
                    
                </div>
                
                <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', justifyItems: 'center' }}>
                <button
                    className="submit-btn"
                    style={{ backgroundColor: '#FFC107', color: 'white' }} 
                    type="submit"
                    onClick={handleSubmit}
                >
                    Add
                </button>
                </div>
                </>))}
            </div>
        </div>
       
       </>
    )
}

export default Edit