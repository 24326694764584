import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './AuctionCard.css';
import './saledetails.css';
import axios from 'axios';
import GalleryPop from './popup';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { position } from '@chakra-ui/react';

const SaleAuctionCard = () => {
  const navigate = useNavigate();
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isInWishlist, setIsInWishlist] = useState(false);
  const userData = sessionStorage.getItem('user');
  const defaultUser = {};
  const [user, setUser] = useState(userData ? JSON.parse(userData) : defaultUser);
  const { t } = useTranslation();

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [items, setItems] = useState([]);
  const itemsPerPage = isMobile ? 10 : 20;
  const [id, setid] = useState();
  // Fetch items from the backend
  const fetchItems = async (page) => {
    try {
      const response = await axios.get('https://api.drivenappinc.com/salecar-itemsss', {
        params: {
          page,
          limit: itemsPerPage,
        },
      });
      setItems(response.data);
    } catch (error) {
      console.error('Error fetching auction items:', error);
    }
  };

  useEffect(() => {
    fetchItems(currentPage);
  }, [currentPage]);

  const addwishlist = (id) => {
    if (isInWishlist) {
      handleRemoveFromWishlist(id);
    } else {
      handleAddWishlist(id);
    }
  };

  const handleAddWishlist = async (auctionId) => {
    const userId = user.data && user.data[0]?.id;
    if (!userId) {
      alert('Please Login first!');
      return;
    }
    if (!auctionId) {
      console.error('Auction ID not found');
      return;
    }

    try {
      const response = await axios.post('https://api.drivenappinc.com/wishlist', { auctionId, userId });
      console.log('Added to wishlist successfully', response.data);
      setIsInWishlist(true);
      checkWishlistStatus();
    } catch (error) {
      console.error('Error adding to wishlist:', error);
    }
  };

  const handleRemoveFromWishlist = async (auctionId) => {
    const userId = user.data && user.data[0]?.id;

    try {
      const response = await axios.delete(`https://api.drivenappinc.com/wishlist/${userId}/${auctionId}`);
      setIsInWishlist(false);
      checkWishlistStatus();
    } catch (error) {
      console.error('Error removing from wishlist:', error);
    }
  };

  const checkWishlistStatus = async () => {
    const userId = user.data && user.data[0]?.id;
    const auctionId = items.id;

    try {
      const response = await axios.get(`https://api.drivenappinc.com/wishlist/${userId}/${auctionId}`);
      const isInWishlist = response.data.isInWishlist;
      setIsInWishlist(isInWishlist);
    } catch (error) {
      console.error('Error checking wishlist status:', error);
    }
  };

  useEffect(() => {
    checkWishlistStatus();
  }, [items]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const togglePopup = (id) => {
    console.log(id);
    setid(id);
    setIsPopupVisible(!isPopupVisible);
  };

  const handleClick = (carId) => {
    navigate(`/sale/motors/${carId}`, { state: carId });
  };

  return (
    <>
      {isPopupVisible && <GalleryPop auctionId={id} onClose={togglePopup} />}
      <div className='gallery-container'>
        {items.map((item) => (
          <div key={item.car_id} className='card-container'>
            <div className='card-image-container'>
              <img
                src={item.feature_image}
                alt={item.title}
                title={item.title}
                className='card-image'
                loading='lazy'
                style={{height: '250px', width: '100%'}}
              />
              <div className='add-to-favorite'>
                <div onClick={() => addwishlist(item.car_id)}>
                  {
                    isInWishlist ? <img src="./1r.png" alt="favorite"  width="26" height="26" loading="lazy" rel="preload" aria-label="Add to Watchlist" className=""/> :
                    <img src="./1.png" alt="favorite"  width="26" height="26" loading="lazy" rel="preload" aria-label="Add to Watchlist" className=""/>
                  }
                </div>
                <div onClick={()=>{togglePopup(`${item.car_id}`)}}>
                  <img src='./2.png' alt='option' width="26" height="26" loading="lazy" rel="preload" style={{ background: 'transparent' }} />
                </div>
              </div>
            </div>
            <div className='card-details'>
              <h3 className='card-title'>{item.title}</h3>
              <div className='detail-section'>
                <div className='detail-label'>
                  <img src="./icons/9.png" alt="favorite"  width="26" height="26" loading="lazy" rel="preload" aria-label="Add to Watchlist" className=""/>{t('Milage')}: <span>{item.mileage}</span></div>
              </div>
              <div className='detail-section'>
                <div className='detail-label'>
                <img src="./icons/5.png" alt="favorite"  width="26" height="26" loading="lazy" rel="preload" aria-label="Add to Watchlist" className=""/>{t('Year')}: <span>{item.year}</span></div>
                
              </div>
              <div className='detail-section'>
                <div className='detail-label'>
                <img src="./icons/carmake.png" alt="favorite"  width="26" height="26" loading="lazy" rel="preload" aria-label="Add to Watchlist" className=""/>{t('brands')}:<span>{item.brand}</span></div>
              </div>
              <div className='detail-section'>
                <div className='detail-label'><img src="./icons/6.png" alt="favorite"  width="26" height="26" loading="lazy" rel="preload" aria-label="Add to Watchlist" className=""/>{t('Speed')}:<span>{item.speed} Km</span></div>
              </div>
              <div className='detail-section'>
                <div className='detail-label'>
                <img src="./icons/modlemake.png" alt="favorite"  width="26" height="26" loading="lazy" rel="preload" aria-label="Add to Watchlist" className=""/>{t('models')}:<span>{item.car_model}</span></div>
              </div>
              <div className='detail-section'>
                <div className='detail-label price' style={{fontFamily: ' !important'}}>{t('Price')} {item.price}{t('Currency')}</div>
              </div>
              {/* <div className='button-container'> */}
                <button className='buy-button' style={{width: '100%'}} onClick={() => handleClick(item.car_id)}>{t('Details')}</button>
              {/* </div> */}
            </div>
          </div>
        ))}
        <div className="pagination">
          {currentPage > 1 && (
            <button onClick={() => setCurrentPage(currentPage - 1)}>{t('Previous')}</button>
          )}
          {items.length === itemsPerPage && (
            <button onClick={() => setCurrentPage(currentPage + 1)}>{t('Next')}</button>
          )}
        </div>
      </div>
    </>
  );
};

export default SaleAuctionCard;
