import "./new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useState } from "react";
import axios from "axios";
import { FaTimes } from 'react-icons/fa';
import English from "./English";
import Arabic from "./Arabic";
import MapComponent from "../../../AddressPicker";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { Circles } from "react-loader-spinner";

const New = ({ inputs, title }) => {
  const [images, setImages] = useState([]);
  const [imagesPreview, setImagesPreview] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [documentsPreview, setDocumentsPreview] = useState([]);
  const [expanded, setExpanded] = useState(true);
  const [arabicExpand, setArabicExpand] = useState(false);
  const [getDescription, setGetDescription] = useState('');
  const [getDescriptionar, setGetDescriptionar] = useState('');
  const [getterm, setGetterm] = useState('');
  const [gettermar, setGettermar] = useState('');
  const [featureImage, setFeatureImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    price: 0,
    pervious_price: 0,
    feature_image: '',
    body_type: '',
    body_typeAr: '',
    brand: '' ,
    title: '',
    titleAr: '',
    description: getDescription,
    descriptionAr: getDescriptionar,
    address: '',
    addressAr: '',
    exterior_color: '',
    interior_color: '',
    emirate: '',
    warranty: '',
    specs: '',
    power: '',
    trim: '',
    trimAr: '',
    engine_cylinders: '',
    model: '',
    category: '',
    categoryAr: '',
    fuel_type: '',
    fuel_typeAr: '',
    vendor: 'Admin',
    speed: '',
    year: '',
    mileage: '',
    is_featured: '',
    specification: '',
    status: '',
    latitude: '',
    longitude: '',
    car_condition: '',
    car_conditionAr: '',
    transmission: '',
    transmissionAr: '',
    meta_keywords: '',
    meta_keywordsAr: '',
    meta_description: '',
    meta_descriptionAr: '',
    expiry_date: Date.now(),
    otherImages: [],
    documents: []
  });

  const handleFeatureImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFeatureImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImages = (e) => {
    const files = Array.from(e.target.files);
    console.log('Selected files:', files);
    const newImages = [];

    files.forEach((file) => {
      const reader = new FileReader();
      
      reader.onload = () => {
        console.log('File loaded:', reader.result);
        if (reader.readyState === 2) {
          newImages.push(reader.result); // Collect new images
          setImagesPreview((prevImages) => [...prevImages, reader.result]);
        }
      };

      reader.readAsDataURL(file);
    });
  };

  const handleDocuments = (e) => {
    const files = Array.from(e.target.files);
    console.log('Selected documents:', files);
    const newDocuments = [];

    files.forEach((file) => {
      const reader = new FileReader();
      
      reader.onload = () => {
        console.log('Document loaded:', reader.result);
        if (reader.readyState === 2) {
          newDocuments.push(reader.result); // Collect new documents
          setDocumentsPreview((prevDocuments) => [...prevDocuments, reader.result]);
        }
      };

      reader.readAsDataURL(file);
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleLocationSelect = (location) => {
    formData.latitude = location.lat;
    formData.longitude = location.lng;
    console.log(location);
  };

  const handleRemoveImage = (index) => {
    const updatedFiles = [...imagesPreview];
    updatedFiles.splice(index, 1);
    setImagesPreview(updatedFiles);
  };

  const handleRemoveDocument = (index) => {
    const updatedFiles = [...documentsPreview];
    updatedFiles.splice(index, 1);
    setDocumentsPreview(updatedFiles);
  };

  const handleRemoveFeatureImage = () => {
    setFeatureImage(null);
  };

  console.log(formData);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    formData.description = getDescription;
    formData.descriptionAr = getDescriptionar;
    formData.term = getterm;
    formData.termar = gettermar;
    formData.feature_image = featureImage;
    formData.otherImages = imagesPreview;
    formData.documents = documentsPreview[0]? documentsPreview[0] : '';
    
    axios.post('https://api.drivenappinc.com/cars', formData)
      .then(response => {
        console.log('Response:', response.data);
        alert('Created Successfully!');
        navigate('/admin/cars');
      })
      .catch(error => {
        console.error('Error:', error);
        setIsLoading(false);
      });
  };

  const toggleExpand = () => {
    setExpanded(!expanded);
    setArabicExpand(!arabicExpand);
  };

  return (
    <>
    {isLoading ? (
      <div style=
        {{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          top: 0, 
          bottom: 0, 
          right: 0, 
          left: 0, 
          position: 'fixed' 
        }}>
        <Circles
        height="80"
        width="80"
        color="#FFC107"
        ariaLabel="circles-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        />
      </div>
    ) : (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="left">
            <label>Feature Image</label>
            {featureImage ? 
              <li style={{ display: 'flex', flexWrap: 'wrap', position: 'relative', marginBottom: '10px', listStyle: 'none' }}>
                <img
                  src={
                    featureImage ? featureImage
                      : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                  }
                  alt=""
                />
                <button
                  onClick={handleRemoveFeatureImage}
                  style={{
                    position: 'absolute',
                    top: '0',
                    right: '160px',
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                  }}
                >
                  <FaTimes style={{ color: 'red' }} className="icon" />
                </button>
              </li> : 
              <img
                src={
                  featureImage ? featureImage
                    : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                }
                alt=""
              />
            }
            
            <label style={{ marginTop: '7%' }}>Other Images</label>
            <ul style={{ listStyle: 'none', padding: 0 }}>
              <li style={{ display: 'flex', flexWrap: 'wrap' }}>
                {imagesPreview.map((image, index) => (
                  <div key={index} style={{ position: 'relative', marginLeft: '7px', marginBottom: '10px', listStyle: 'none' }}>
                    <img
                      key={index}
                      src={
                        image ? image
                          : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                      }
                      alt=""
                    />
                    <button
                      onClick={() => handleRemoveImage(index)}
                      style={{
                        position: 'absolute',
                        top: '0',
                        right: '125px',
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                      }}
                    >
                      <FaTimes style={{ color: 'red' }} className="icon" />
                    </button>
                  </div>
                ))}
              </li>
            </ul>

            <label style={{ marginTop: '7%' }}>Documents</label>
            <ul style={{ listStyle: 'none', padding: 0 }}>
              <li style={{ display: 'flex', flexWrap: 'wrap' }}>
                {documentsPreview.map((document, index) => (
                  <div key={index} style={{ position: 'relative', marginLeft: '7px', marginBottom: '10px', listStyle: 'none' }}>
                    <img
                      key={index}
                      src={
                        document ? document
                          : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                      }
                      alt=""
                    />
                    <button
                      onClick={() => handleRemoveDocument(index)}
                      style={{
                        position: 'absolute',
                        top: '0',
                        right: '125px',
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                      }}
                    >
                      <FaTimes style={{ color: 'red' }} className="icon" />
                    </button>
                  </div>
                ))}
              </li>
            </ul>
          </div>
          <div className="right">
            <form>
              <div className="formInput">
                <label htmlFor="featureImage">
                  Feature Image: <DriveFolderUploadOutlinedIcon className="icon" />
                </label>
                <input
                  type="file"
                  id="featureImage"
                  onChange={handleFeatureImage}
                  style={{ display: "none" }}
                />
              </div>
              <div className="formInput">
                <label htmlFor="file">
                  Other Images: <DriveFolderUploadOutlinedIcon className="icon" />
                </label>
                <input
                  type="file"
                  id="file"
                  multiple
                  onChange={handleImages}
                  style={{ display: "none" }}
                />
              </div>
              <div className="formInput">
                <label htmlFor="documents">
                  Documents: <DriveFolderUploadOutlinedIcon className="icon" />
                </label>
                <input
                  type="file"
                  id="documents"
                  multiple
                  onChange={handleDocuments}
                  style={{ display: "none" }}
                />
              </div>
              
              {inputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>{input.label}</label>
                  <input 
                    type={input.type} 
                    placeholder={input.placeholder} 
                    name={input.name}
                    onChange={handleChange}
                  />
                </div>
              ))}
            </form>
            {isMobile ? 
            <MapComponent onLocationSelect={handleLocationSelect} /> : ''}
          </div>
        </div>
        <div className="expand-form-btn">
          {!isMobile ? 
          <MapComponent onLocationSelect={handleLocationSelect} /> : ''}
          <button 
            style={{
              backgroundColor: `${expanded ? "#FFC107" : "white"}`, 
              color: `${expanded ? "white" : "#007bff"}`, fontWeight: 600 }} 
              className="expand-button" 
              onClick={toggleExpand 
            }>
            English Language (Default)
          </button>
          {
            expanded && 
            <English 
              setData={setFormData}  
              setGetDescription={setGetDescription}
              handleChange={handleChange}
              setGetterms={setGetterm}
            />
          }
        </div>
        
        <div className="expand-form-btn">
          <button 
            style={{
              backgroundColor: `${arabicExpand ? "#FFC107" : "white"}`, 
              color: `${arabicExpand ? "white" : "#007bff"}`, fontWeight: 600 }} 
              className="expand-button" 
              onClick={toggleExpand 
            }>
            عربي Language
          </button>
          {
            arabicExpand && 
            <Arabic 
              setData={setFormData} 
              setGetDescription={setGetDescriptionar}
              handleChange={handleChange}
              setGetterms={setGettermar}
            />
          }
        </div>
        
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', justifyItems: 'center' }}>
          <button
            className="submit-btn"
            style={{ backgroundColor: '#FFC107', color: 'white' }} 
            type="submit"
            onClick={handleSubmit}
          >
            Add
          </button>
        </div>
      </div>
    </div>)}</>
  );
};

export default New;
