import React from 'react';
import './Banner.css'; // Import CSS file for styling (create this file)
import { Link } from 'react-router-dom';
import { t } from 'i18next';

const Banner = () => {
  return (
    <div className="banner_container">
      <div className="banner1">
        <img
          src="/banner_image.jpg" // Replace with your image URL
          alt="Banner Image"
          className="banner_image"
        />
        <div className="banner-content" align={'center'}>
          <div className="button-container">
            <Link to="/user/Profile/request.js" className="button btn btn-warning">{t('Sale_Car')}</Link>
            <Link to="/packages" className="button btn btn-warning">{t('Select_Packages')}</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
