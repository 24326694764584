import React from 'react';
import { useEffect, useState, useRef } from "react";
import { Circles } from 'react-loader-spinner';
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import axios from 'axios';
import List from '../list/List'

const Users = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true)
  const childRef = useRef(null);


  const PaymentInputField = ({ id, initialPrice, onChange }) => {
    const [price, setPrice] = useState(initialPrice || ''); // Initialize with initialPrice or empty string if undefined

    const handlePriceChange = async (event) => {
        const newPrice = event.target.value;
        setPrice(newPrice);
        
        // Ensure onChange is a function before calling it
        if (typeof onChange === 'function') {
            onChange(newPrice); // Propagate the new price to parent component if onChange is defined
        }

        try {
            const response = await axios.post(`https://api.drivenappinc.com/Users/payment`, {
                id,
                price: newPrice,
            });

            if (response.status !== 200) {
                throw new Error('Failed to update price');
            }

            console.log(response.data); // Assuming response.data contains updated data or message
        } catch (error) {
            console.error('Error updating price:', error);
        }
    };

    return (
        <div>
            <input
                type="number"
                id={`price_${id}`}
                name="price"
                value={price}
                onChange={handlePriceChange}
                placeholder="Enter price..."
            />
        </div>
    );
};



    useEffect(() => {
        const fetchDataForPosts = async () => {
          try {
            const response = await fetch(
              `https://api.drivenappinc.com/users`
            );
            if (!response.ok) {
              throw new Error(`HTTP error: Status ${response.status}`);
            }
            let usersData = await response.json();
            setData(usersData);
            console.log(usersData);
            setIsLoading(false);
          } catch (err) {
            console.log(err.message)
            setIsLoading(false);
          }
        };
    
        fetchDataForPosts();
      }, [])

      const userColumns = [
        { field: "id", headerName: "ID", width: 70 },
        {
          field: "name",
          headerName: "Name",
          width: 230,
          renderCell: (params) => {
            return (
              <div className="cellWithImg">
                <img className="cellImg" src={params.row.image} alt="avatar" />
                {params.row.name}
              </div>
            );
          },
        },
        {
          field: "username",
          headerName: "Username",
          width: 100,
        },
        {
          field: "email",
          headerName: "Email",
          width: 100,
        },
        {
          field: "phone",
          headerName: "Phone",
          width: 100,
        },
        {
          field: "country",
          headerName: "Country",
          width: 100,
        },
        {
          field: "payment",
                  headerName: "Payment",
                  width: 150,
                  renderCell: (params) => (
                      <PaymentInputField
                          id={params.row.id}
                          initialPrice={params.row.payment}
                          onUpdate={(userId, newPrice) => {
                              // Handle update logic here
                              console.log(`Updated payment for user ${userId} to ${newPrice}`);
                          }}
                      />
                  ),
        }
      ]

  return (
    <>
    <div className="list">
          <Sidebar/>
          <div className="listContainer">
            <Navbar/>
            {isLoading ? (
              <div style=
                {{ 
                  display: 'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center', 
                  top: 0, 
                  bottom: 0, 
                  right: 0, 
                  left: 0, 
                  position: 'fixed' 
                }}>
                <Circles
                height="80"
                width="80"
                color="#FFC107"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                />
              </div>
            ) : (data && (
              <List 
              data={data} 
              link='users'
              columns={userColumns} 
              setData={setData} 
              title={"Users"}
              onChange={(userId, newPrice) => {
                  // Handle update logic here
                  console.log(`Updated payment for user ${userId} to ${newPrice}`);
              }}
          />          
              )
            )}
          </div>
        </div>
    </>
  )
}

export default Users