import React, { useState, useEffect } from 'react'
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import Chart from "../../components/chart/Chart";
import List from "../../components/table/Table";
import { Circles } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';

const UserDetails = () => {
  const { userId } = useParams()
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchDataForPosts = async () => {
      try {
        const response = await fetch(
          `https://api.drivenappinc.com/users/${userId}`
        );
        if (!response.ok) {
          throw new Error(`HTTP error: Status ${response.status}`);
        }
        let userData = await response.json();
        setData(userData);
        console.log(data);
        setIsLoading(false);
      } catch (err) {
        console.log(err.message)
        setIsLoading(false)
      }
    };

    fetchDataForPosts();
  }, [])


  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        {isLoading ? (
          <div style=
            {{ 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
              top: 0, 
              bottom: 0, 
              right: 0, 
              left: 0, 
              position: 'fixed' 
            }}>
              <Circles
              height="80"
              width="80"
              color="#FFC107"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              />
          </div>
        ) : (data 
          && 
          (
            <>
              <div className="top">
                <div className="left">
                  <h1 className="title">Information</h1>
                  <div className="item">
                    <img
                      src={data[0].image}
                      alt={data[0].name}
                      className="itemImg"
                    />
                    <div className="details">
                      <h1 className="itemTitle">{data[0].name}</h1>
                      <div className="detailItem">
                        <span className="itemKey">Email:</span>
                        <span className="itemValue">{data[0].email}</span>
                      </div>
                      <div className="detailItem">
                        <span className="itemKey">Phone:</span>
                        <span className="itemValue">{data[0].phone}</span>
                      </div>
                      <div className="detailItem">
                        <span className="itemKey">Address:</span>
                        <span className="itemValue">
                          {data[0].address}. {data[0].city}. {data[0].state}
                        </span>
                      </div>
                      <div className="detailItem">
                        <span className="itemKey">Country:</span>
                        <span className="itemValue">{data[0].country}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="right">
                  <Chart aspect={3 / 1} title="User Spending ( Last 6 Months)" />
                </div>
              </div>
              <div className="bottom">
              <h1 className="title">Last Transactions</h1>
                <List/>
              </div>
        </>
      ))}
    </div>
  </div>
  )
}

export default UserDetails