import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './numberdetails.css';
import './AuctionCard.css';
import axios from 'axios';
import GalleryPop from './popup';
import { useTranslation } from 'react-i18next';
import BidPopup from './BidPopup';
import i18next from 'i18next';
import { isMobile } from 'react-device-detect';

const NumberCarddetails = ({ item }) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [bidsCount, setBidsCount] = useState(0);
  const [isInWishlist, setIsInWishlist] = useState(false);
  const userData = sessionStorage.getItem('user');
  const defaultUser = {};
  const [user, setUser] = useState(userData ? JSON.parse(userData) : defaultUser);
  const { t } = useTranslation();

  const addWishlist = (id) => {
    if (isInWishlist) {
      handleRemoveFromWishlist(id);
    } else {
      handleAddWishlist(id);
    }
  };

  const handleOpenPopup = () => {
    setIsPopupVisible(true);
  };

  const handleClosePopup = () => {
    setIsPopupVisible(false);
  };

  const handleAddWishlist = async (auctionId) => {
    const userId = user.data && user.data[0]?.id;
    if (!userId) {
      alert('Please Login first!');
      return;
    }
    if (!auctionId) {
      console.error('Auction ID not found');
      return;
    }

    try {
      const response = await axios.post('https://api.drivenappinc.com/wishlist', { auctionId, userId });
      console.log('Added to wishlist successfully', response.data);
      setIsInWishlist(true);
      checkWishlistStatus();
    } catch (error) {
      console.error('Error adding to wishlist:', error);
    }
  };

  const handleRemoveFromWishlist = async (auctionId) => {
    const userId = user.data && user.data[0]?.id;

    try {
      const response = await axios.delete(`https://api.drivenappinc.com/wishlist/${userId}/${auctionId}`);
      setIsInWishlist(false);
      checkWishlistStatus();
    } catch (error) {
      console.error('Error removing from wishlist:', error);
    }
  };

  const checkWishlistStatus = async () => {
    const userId = user.data && user.data[0]?.id;
    const auctionId = item.id;

    try {
      const response = await axios.get(`https://api.drivenappinc.com/wishlist/${userId}/${auctionId}`);
      const isInWishlist = response.data.isInWishlist;
      setIsInWishlist(isInWishlist);
    } catch (error) {
      console.error('Error checking wishlist status:', error);
    }
  };

  useEffect(() => {
    checkWishlistStatus();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const breakpoint = 768;

  useEffect(() => {
    const fetchDataForPosts = async () => {
      try {
        const response = await fetch(`https://api.drivenappinc.com/bidsnew/${item.id}`);
        if (!response.ok) {
          throw new Error(`HTTP error: Status ${response.status}`);
        }
        let data = await response.json();
        console.log(data); // Log the response to verify its structure
        setBidsCount(data.count);
      } catch (err) {
        console.log(err.message);
      }
    };

    fetchDataForPosts(); // Initial fetch

    // Set interval to fetch data every 10 seconds
    const interval = setInterval(fetchDataForPosts, 10000); // 10000 milliseconds = 10 seconds

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, [item.id]);

  const calculateTimeLeft = () => {
    const difference = +new Date(item.expiry_date) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        D: Math.floor(difference / (1000 * 60 * 60 * 24)),
        H: Math.floor((difference / (1000 * 60 * 60)) % 24),
        M: Math.floor((difference / 1000 / 60) % 60),
        S: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [item.expiry_date]);

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span key={interval}>
        {timeLeft[interval]} {interval} {":"}
      </span>
    );
  });

  const handleClick = () => {
    setIsPopupVisible(true);
  };

  return (
    <>
      {isPopupVisible && (
        <BidPopup
          onClose={handleClosePopup}
          endDate={item.expiry_date}
          auctionId={item.id}
          item={item}
        />
      )}
      
      <div style={{ cursor: 'pointer' }} className='auctions-wrap'>
        <div className='page-content'>
          <div className='vehicle-wrap'>
            <div className='styles_auctionsList__i4K01'>
              <div id="CARD_GRID_477467" className="styles_listItemContainer__34R8_" style={{ outline: '0px', boxShadow: 'rgba(78, 78, 78, 0.14) 0px 5px 15px' }}>
                <div className="styles_listImg__4ia1l">
                  <div className="styles_imageWrapper__WhasL container2">
                    <a onClick={handleClick}>
                      <img src={item.image} alt={item.number} title={item.number} className="styles_cardImg__peZtj centered-image" style={{ maxHeight: '50px', maxWidth: '212px', alignSelf: 'center' }} loading="lazy" />
                    </a>
                  </div>
                </div>
                <div className="styles_listDetails__Du48R">
                  <div className="styles_listStatus__BH4qo"></div>
                  <a target="_self" onClick={handleClick}>
                    <h3 id="title" className="styles_cardTitle__yML2U styles_cardTitleLarge__GN874" aria-label={item.number}>Number: {item.number}</h3>
                  </a>
                  <div className="styles_wrapper__4w_5e">
                    {windowWidth >= breakpoint && (
                      <div className="styles_listWrapper__mlWdq styles_wrapper__6swlq">
                        <img src="./calender_icon.svg" alt="End Date" width="16" height="16" loading="lazy" />
                        <span className="styles_text__GSIbG">{t('End_Date')} :</span>
                        <span className="styles_data__HPULm styles_listData__5uy5S">{item.expiry_date}</span>
                      </div>
                    )}
                    <div className={`styles_divider__mLML4${isMobile && i18n.language === 'en' ? '' : 'en'}`}></div>
                    <div className="styles_listWrapper__mlWdq styles_wrapper__6swlq">
                      <img src="./remaining.svg" alt="Time Left" width="16" height="16" loading="lazy" />
                      <span className="styles_text__GSIbG">{t('time_left')} :</span>
                      <span className="styles_data__HPULm styles_listData__5uy5S">
                        <span className="styles_large__zr9ao" style={{ display: 'flex' }}>{timerComponents.length ? timerComponents : <span>Time's up!</span>}</span>
                      </span>
                    </div>
                    <div className={`styles_divider__mLML4${isMobile && i18n.language === 'en' ? '' : 'en'}`}></div>
                    <div className={`styles_biddingCount__xU8XR number_bid_count_${i18next.language}`}>
                      <div className="styles_listWrapper__mlWdq styles_wrapper__6swlq itemBorderAnimation">
                        <img className="styles_white__rdPYA iconBidWhite" src="./bids.svg" alt="Bids" width="16" height="16" loading="lazy" />
                        <img className="styles_black__BBy8T iconBidBlack" src="./bids-white.svg" alt="Bids" width="16" height="16" loading="lazy" />
                        <span className="styles_text__GSIbG priceFlash">{t('Bids')} :</span>
                        <span className="styles_data__HPULm styles_listData__5uy5S priceFlash">{bidsCount}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`styles_divider__mLML4${isMobile && i18n.language === 'en' ? '' : 'en'}`}></div>
                <div className="styles_wrapperList__KKOXU itemBorderAnimation">
                  <div className={`styles_smallPrice__mlvXY priceFlash number_bid_price_${i18next.language}`}>
                    <span className="styles_currency__qIwfP priceFlash">AED</span>
                    <span>{item.amount}</span>
                  </div>
                  <button className={`styles_btn__KTmso BidBtnFlash bg-warning number_bid_button_${i18next.language}`} onClick={handleClick}>
                    <div className="biddingIconsContainer">
                      <img className="styles_white__RZ_zn iconWhite" src="./Bidding_icon-white.svg" alt="bid icon" width="18" height="18" loading="lazy" />
                      <img className="styles_black__tl8HL iconBlack" src="./Bidding_icon_black.svg" alt="bid icon" width="18" height="18" loading="lazy" />
                    </div>
                    <span>{t('Bid_now')}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NumberCarddetails;
