
import { useState, useEffect } from "react";

import Select from "./Select";

import './Arabic.scss'
import TextAreaEditor from "./TextAreaEditor";
import {
  conditionARTypes,
  cylinderTypes, 
  emirateTypes, 
  exteriorColorTypes,
  powerTypes, 
  specsTypes, 
  transmissionARTypes, 
  warrantyTypes 
} from "../../formSource";


const Arabic = ({  handleChange, setCarContentAr, carContentAr, setGetDescriptionAr }) => {
    

  const [categories, setCategories] = useState([])
  const [brands, setBrands] = useState([])
  const [models, setModels] = useState([])
  const [bodyType, setBodyType] = useState([])
  const [fuelType, setFuelType] = useState([])

  
  
  useEffect(() => {
    const fetchDataForPosts = async () => {
      //Geting categories
      try {
        const response = await fetch(
          `https://api.drivenappinc.com/categoriesar`
        );
        if (!response.ok) {
          throw new Error(`HTTP error: Status ${response.status}`);
        }
        let categoriesData = await response.json();
        setCategories(categoriesData);
      } catch (err) {
        console.log(err.message)
      }

      //Geting bodytypes
      try {
        const response = await fetch(
          `https://api.drivenappinc.com/body_typesar`
        );
        if (!response.ok) {
          throw new Error(`HTTP error: Status ${response.status}`);
        }
        let bodyTypeData = await response.json();
        setBodyType(bodyTypeData);
      } catch (err) {
        console.log(err.message)
      }

      //Geting brands
      try {
        const response = await fetch(
          `https://api.drivenappinc.com/brandsen`
        );
        if (!response.ok) {
          throw new Error(`HTTP error: Status ${response.status}`);
        }
        let brandsData = await response.json();
        setBrands(brandsData);
      } catch (err) {
        console.log(err.message)
      }

      //get models
      try {
        const response = await fetch(
          `https://api.drivenappinc.com/models`
        );
        if (!response.ok) {
          throw new Error(`HTTP error: Status ${response.status}`);
        }
        let modelsData = await response.json();
        setModels(modelsData);
      } catch (err) {
        console.log(err.message)
      }

      //get fuel type
      try {
        const response = await fetch(
          `https://api.drivenappinc.com/fueltypear`
        );
        if (!response.ok) {
          throw new Error(`HTTP error: Status ${response.status}`);
        }
        let fuelTypesData = await response.json();
        setFuelType(fuelTypesData);
      } catch (err) {
        console.log(err.message)
      }
    };

    fetchDataForPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
    

  return (
    <>
    <div dir="rtl">
    
      {/* <TextField dir="rtl" style={{ marginTop: '2%' }} fullWidth label="Title" id="fullWidth" name="title" /> */}

      <div style={{ width: '95%', marginTop: '2%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }} className="form-group rtl text-right">
        <label for="title">Title *</label>
        <input
          required
          defaultValue={carContentAr[0].title}
          id='title'
          style={{
            width: '100%', 
            outline: 0, 
            borderWidth: '0 0 2px', 
            borderColor: 'light-black',
            padding: '8px 0px',
            marginTop: '2%',
            height: '2em',
            fontSize: '16px'
          }} 
          type="text" 
          className="input-title" 
          name="title" 
          onChange={(e) => handleChange(e, setCarContentAr)}
          placeholder="Enter Title"
        />
      </div>
    </div>
    <div dir="rtl" style={{  marginTop: '3.5%', marginBottom: '3%', display: 'flex', flexWrap: 'wrap', gap: '5%' }}>
    <Select language='Arabic' handleChange={handleChange} setCarState={setCarContentAr} currentValue={carContentAr.brand} value={brands} name='brand' title='Brands'/>
      <Select language='Arabic' handleChange={handleChange} setCarState={setCarContentAr} currentValue={carContentAr.car_model} value={models}  name='car_model' title='Model'/>
      <Select language='Arabic' handleChange={handleChange} setCarState={setCarContentAr} currentValue={carContentAr[0].brand} value={conditionARTypes} name='car_condition' title='Condition'/>
      <Select language='Arabic' handleChange={handleChange} setCarState={setCarContentAr} currentValue={carContentAr[0].brand} value={categories} name='categoryAr' title='Category'/>
      <Select language='Arabic' handleChange={handleChange} setCarState={setCarContentAr} currentValue={carContentAr[0].brand} value={exteriorColorTypes} name='Enterior_color' title='Exterior Color'/>
      <Select language='Arabic' handleChange={handleChange} setCarState={setCarContentAr} currentValue={carContentAr[0].brand} value={bodyType} name='body_type' title='Body Type'/>
      <Select language='Arabic' handleChange={handleChange} setCarState={setCarContentAr} currentValue={carContentAr[0].brand} value={transmissionARTypes} name='transmission_type' title='Transmission Type'/>
      <Select language='Arabic' handleChange={handleChange} setCarState={setCarContentAr} currentValue={carContentAr[0].brand} value={fuelType} name='fuel_type' title='Fuel Type'/>
      <Select language='Arabic' handleChange={handleChange} setCarState={setCarContentAr} currentValue={carContentAr[0].brand} value={specsTypes} name='Specs' title='Specs'/>
      <Select language='Arabic' handleChange={handleChange} setCarState={setCarContentAr} currentValue={carContentAr[0].brand} value={warrantyTypes} name='Warranty' title='Warranty'/>
      <Select language='Arabic' handleChange={handleChange} setCarState={setCarContentAr} currentValue={carContentAr[0].brand} value={emirateTypes} name='Emirate' title='Emirate'/>
      <Select language='Arabic' handleChange={handleChange} setCarState={setCarContentAr} currentValue={carContentAr[0].brand} value={exteriorColorTypes} name='Interior_color' title='Interior Color'/>
      <Select language='Arabic' handleChange={handleChange} setCarState={setCarContentAr} currentValue={carContentAr[0].brand} value={cylinderTypes} name='Engine_cylinders' title='Engine Cylinders'/>
      <Select language='Arabic' handleChange={handleChange} setCarState={setCarContentAr} currentValue={carContentAr[0].brand} value={powerTypes} name='Power' title='Power'/>
      <input
          required
          id='title'
          style={{
            width: '20%', 
            outline: 0, 
            borderWidth: '0 0 2px', 
            borderColor: 'light-black',
            padding: '12px 0px',
            height: '1.5em',
            fontSize: '14px'
          }} 
          onChange={(e) => handleChange(e, setCarContentAr)}
          type="text" 
          className="input-title" 
          name="address" 
          defaultValue={carContentAr[0].address}
          placeholder="Enter Address"
        />
        <input
          required
          id='title'
          style={{
            width: '20%', 
            outline: 0, 
            borderWidth: '0 0 2px', 
            borderColor: 'light-black',
            padding: '12px 0px',
            height: '1.5em',
            fontSize: '14px'
          }}
          onChange={(e) => handleChange(e, setCarContentAr)}
          type="text" 
          className="input-title" 
          name="trim"
          defaultValue={carContentAr[0].trim} 
          placeholder="Enter Trim"
        />
        <TextAreaEditor setData={setGetDescriptionAr} value={carContentAr[0].description}/>
        <div style={{ width: '95%', marginTop: '2%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }} className="form-group rtl text-right">
        <label for="meta_keywords">Meta Keywords *</label>
        <input
          required
          id='meta_keywords'
          style={{
            width: '100%', 
            outline: 0, 
            borderWidth: '0 0 2px', 
            borderColor: 'light-black',
            padding: '5px 0px',
            marginTop: '1%',
            height: '2em',
            fontSize: '16px'
          }}
          onChange={(e) => handleChange(e, setCarContentAr)}
          type="text" 
          className="input-title" 
          name="meta_keyword"
          defaultValue={carContentAr[0].meta_keyword}
          placeholder="Enter Meta Keywords"
        />
      </div>
      <label style={{ marginTop: '2%' }} for="meta_description">Meta Description *</label>
      <textarea id="meta_description" value={carContentAr[0].meta_description} name="meta_description" onChange={(e) => handleChange(e, setCarContentAr)} className="meta_description">{carContentAr.meta_description}</textarea>
    </div>
  </>
);
};

export default Arabic;
