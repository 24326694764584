import React, { useState } from 'react';
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

const Newpackage = ({ title }) => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        title: '',
        price: '',
        term: '',
        number_of_car_featured: '',
        number_of_car_add: '',
        status: '',
        option1: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const toastNotification = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const notifyError = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        const finalFormData = {
            title: formData.title,
            price: formData.price,
            term: formData.term,
            number_of_car_featured: formData.number_of_car_featured,
            number_of_car_add: formData.number_of_car_add,
            status: formData.status,
            option1: formData.option1
        };
        console.log(finalFormData);
        axios.put('https://api.drivenappinc.com/package', finalFormData)
            .then(response => {
                toastNotification('Package Added Successfully');
                navigate('/admin/packages');
            })
            .catch(error => {
                console.log(error.message);
                notifyError('Error Adding Package');
            });
    };

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div className="new">
                <Sidebar />
                <div className="newContainer">
                    <Navbar />
                    <div className="top">
                        <h1>Add New Packages</h1>
                    </div>
                    <div className="bottom">
                        <div className="right">
                            <form onSubmit={handleSubmit}>
                                <div className="formInput">
                                    <label>Title</label>
                                    <input
                                        type="text"
                                        name="title"
                                        value={formData.title}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="formInput">
                                    <label>Price</label>
                                    <input
                                        type="number"
                                        name="price"
                                        value={formData.price}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="formInput">
                                    <label>Term</label>
                                    <select
                                        name="term"
                                        value={formData.term}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select Terms</option>
                                        <option value="Week">Week</option>
                                        <option value="Month">Month</option>
                                        <option value="Year">Year</option>
                                    </select>
                                </div>
                                <div className="formInput">
                                    <label>Days of Car Featured</label>
                                    <input
                                        type="number"
                                        name="number_of_car_featured"
                                        value={formData.number_of_car_featured}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="formInput">
                                    <label>Number of Car Add</label>
                                    <input
                                        type="number"
                                        name="number_of_car_add"
                                        value={formData.number_of_car_add}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="formInput">
                                    <label>Status</label>
                                    <select
                                        name="status"
                                        value={formData.status}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select Status</option>
                                        <option value="active">Active</option>
                                        <option value="inactive">Inactive</option>
                                    </select>
                                </div>
                                <div className="formInput">
                                    <label>Type</label>
                                    <select
                                        name="option1"
                                        value={formData.option1}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select</option>
                                        <option value="Private">Private</option>
                                        <option value="Dealer">Dealer</option>
                                    </select>
                                </div>
                                <button
                                    className="submit-btn"
                                    style={{ backgroundColor: '#FFC107', color: 'white' }}
                                    type="submit"
                                >
                                    Add
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Newpackage;
