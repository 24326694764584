import { ShoppingBasket } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function NumberPopup(props){
    return (<Container> </Container>
    )
}
export default NumberPopup;

const Container = () => {
    const {t, i18n} = useTranslation();
    const [counts, setCounts] = useState(null);

  useEffect(() => {
    fetch('https://api.drivenappinc.com/countnumber')
      .then(response => response.json())
      .then(data => setCounts(data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);


  if (!counts) {
    return <div>Loading...</div>;
  }
   return(
    // <li className="menu-item-has-children">
    //     <a href="#">{t('Numbers')}<i className="fas fa-angle-down"></i></a>
    //     <div className="sub-menu single-column-menu">
    //         <ul>
    //             <li><Link>{t("Comming")}</Link></li>
    //         </ul>
    //     </div>
    // </li>
    <>
    <li className="menu-item-has-children">
                              <a href='#'>{t('Numberplate')}<i className="fa fa-angle-down"></i></a>
                              <div className="sub-menu mega-menu mega-menu-column-4">
                              <div className="list-item text-center">
                                  <Link to={"/Numbers?city=abudabi&auction=buynow"}>
                                          <img src="/aubdabi.png" alt="new Product" />
                                          <h4 className="title">{t('abudabi')} <span class="badge rounded-pill bg-danger">
                                         {counts.abudabibuynow}
  </span></h4>
                                          <button className="btn btn-warning"><ShoppingBasket /> {t('Buy_now')}</button>
                                      </Link>
                                  </div>
                                  <div className="list-item text-center">
                                  <Link to={"/Numbers?city=sharjah&auction=buynow"}>
                                          <img src="/sharjahplates.png" alt="new Product" />
                                          <h4 className="title">{t('sharjah')} <span class="badge rounded-pill bg-danger">
                                         {counts.sharjahbuynow}
  </span></h4>
                                          <button className="btn btn-warning"><ShoppingBasket /> {t('Buy_now')}</button>
                                      </Link>
                                  </div>
                                  <div className="list-item text-center">
                                  <Link to={"/Numbers?city=Ajman&auction=buynow"}>
                                          <img src="/ajman.png" alt="new Product" />
                                          <h4 className="title">{t('Ajman')} <span class="badge rounded-pill bg-danger">
                                         {counts.Ajmanbuynow}
  </span></h4>
                                          <button className="btn btn-warning"><ShoppingBasket /> {t('Buy_now')}</button>
                                      </Link>
                                  </div>
                                  <div className="list-item text-center">
                                  <Link to={"/Numbers?city=Rak&auction=buynow"}>
                                          <img src="/rak.png" alt="new Product" />
                                          <h4 className="title">{t('Rak')} <span class="badge rounded-pill bg-danger">
                                         {counts.Rakbuynow}
  </span></h4>
                                          <button className="btn btn-warning"><ShoppingBasket /> {t('Buy_now')}</button>
                                      </Link>
                                  </div>
                                  <div className="list-item text-center">
                                  <Link to={"/Numbers?city=UAQ&auction=buynow"}>
                                          <img src="/uaq.png" alt="new Product" />
                                          <h4 className="title">{t('UAQ')} <span class="badge rounded-pill bg-danger">
                                         {counts.UAQbuynow}
  </span></h4>
                                          <button className="btn btn-warning"><ShoppingBasket /> {t('Buy_now')}</button>
                                      </Link>
                                  </div>
                                  <div className="list-item text-center">
                                  <Link to={"/Numbers?city=Fujarah&auction=buynow"}>
                                          <img src="/fujairah.png" alt="new Product" />
                                          <h4 className="title">{t('Fujarah')} <span class="badge rounded-pill bg-danger">
                                         {counts.Fujarahbuynow}
  </span></h4>
                                          <button className="btn btn-warning"><ShoppingBasket /> {t('Buy_now')}</button>
                                      </Link>
                                  </div>
                                  <div className="list-item text-center">
                                  <Link to={"/Numbers?city=Dubai&auction=buynow"}>
                                          <img src="/dubai.png" alt="new Product" />
                                          <h4 className="title">{t('Dubai')} <span class="badge rounded-pill bg-danger">
                                         {counts.Dubaibuynow}
  </span></h4>
                                          <button className="btn btn-warning"><ShoppingBasket /> {t('Buy_now')}</button>
                                      </Link>
                                  </div>
                                  <div className="row p-3 py-0 w-100">
                                  <hr />
                                  </div>
                                  <div className="list-item text-center">
                                  <Link to={"/Numbers?city=abudabi&auction=auction"}>
                                          <img src="/aubdabi.png" alt="new Product" />
                                          <h4 className="title">{t('abudabi')} {t('name')} <span class="badge rounded-pill bg-danger">
                                         {counts.abudabiauction}
  </span></h4>
                                      </Link>
                                  </div>
                                  <div className="list-item text-center">
                                  <Link to={"/Numbers?city=sharjah&auction=auction"}>
                                          <img src="/sharjahplates.png" alt="new Product" />
                                          <h4 className="title">{t('sharjah')} {t('name')} <span class="badge rounded-pill bg-danger">
                                         {counts.sharjahauction}
  </span></h4>
                                      </Link>
                                  </div>
                                  <div className="list-item text-center">
                                  <Link to={"/Numbers?city=Ajman&auction=auction"}>
                                          <img src="/ajman.png" alt="new Product" />
                                          <h4 className="title">{t('Ajman')} {t('name')} <span class="badge rounded-pill bg-danger">
                                         {counts.Ajmanauction}
  </span></h4>
                                      </Link>
                                  </div>
                                  <div className="list-item text-center">
                                  <Link to={"/Numbers?city=Rak&auction=auction"}>
                                          <img src="/rak.png" alt="new Product" />
                                          <h4 className="title">{t('Rak')} {t('name')} <span class="badge rounded-pill bg-danger">
                                         {counts.Rakauction}
  </span></h4>
                                      </Link>
                                  </div>
                                  <div className="list-item text-center">
                                  <Link to={"/Numbers?city=UAQ&auction=auction"}>
                                          <img src="/uaq.png" alt="new Product" />
                                          <h4 className="title">{t('UAQ')} {t('name')} <span class="badge rounded-pill bg-danger">
                                         {counts.UAQauction}
  </span></h4>
                                      </Link>
                                  </div>
                                  <div className="list-item text-center">
                                  <Link to={"/Numbers?city=Fujarah&auction=auction"}>
                                          <img src="/fujairah.png" alt="new Product" />
                                          <h4 className="title">{t('Fujarah')} {t('name')} <span class="badge rounded-pill bg-danger">
                                         {counts.Fujarahauction}
  </span></h4>
                                      </Link>
                                  </div>
                                  <div className="list-item text-center">
                                  <Link to={"/Numbers?city=Dubai&auction=auction"}>
                                          <img src="/dubai.png" alt="new Product" />
                                          <h4 className="title">{t('Dubai')} {t('name')} <span class="badge rounded-pill bg-danger">
                                         {counts.Dubaiauction}
  </span></h4>
                                      </Link>
                                  </div>
                                  
    <div className="row p-3 py-0 w-100">
            <hr />
            <div >
                <Link type="button" className="btn btn-warning last-button">{t('Requestyourfavoritenumber')} </Link>
             </div>
    </div>
                              </div>
                          </li>
    </>
   )
}